import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import ProfileCard from "../../components/profile-card/ProfileCard";
import useUserStore from "../../hooks/useUserStore";
import colors from "../../theme/colors";
import containers from "../../theme/containers";
import useProfileScreenViewModel from "./useProfileScreenViewModel";
import { getQuestionByName } from "../../helpers/quiz";
import { QuestionI } from "../../types/Quiz";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import NavigationParamList from "../../navigators/NavigationParamList";
import { UserParamsI } from "../../types/common/UserParamsI";

const ProfileScreen = () => {
  const {
    photo,

    name,
    age,
    gender,
    weight,
    height,
    faceshape,
    bodytype,
    hairlength,
    specials,
    colorseason,
    skinundertone,
    haircolor,
    eyecolor,
    skincolor,
  } = useProfileScreenViewModel();
  const { changeUserParam } = useUserStore();
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const handleChangeProfile = (questionName: QuestionI["result"][]) => {
    return () => {
      navigation.navigate("QuizScreen", {
        quiz: [...questionName.map((q) => getQuestionByName(q))],
        isWelcomeScreen: false,
        onFinish: (userParams: Partial<UserParamsI>) => {
          changeUserParam(userParams);
          navigation.navigate("Profile");
        },
        exitText: "",
        onExit: () => {},
      });
    };
  };
  // const route = useRoute();
  const { resetUserParams } = useUserStore();

  // useEffect(() => {
  //   logScreenView(route.name, "");
  // }, []);
  const renderProfileCard = (
    name: string,
    value: string | undefined | number,
    onPress: () => void
  ) => <ProfileCard name={name} value={value} onPress={onPress} />;

  return (
    <ScrollView style={styles.scrollView}>
      <View style={styles.container}>
        <PrimaryButton
          text={"Retake quiz"}
          onPress={handleChangeProfile([
            "gender",
            "age",
            "weight",
            "height",
            "eyecolor",
            "haircolor",
            "skincolor",
            "bodytype",
            "faceshape",
            "hairlength",
            "tanTest",
            "veins",
            "boneStructure",
            "textureAndBodyShape",
            "hairTextureAndVolume",
          ])}
        />
        {renderProfileCard("Name", name, handleChangeProfile(["name"]))}
        {renderProfileCard("Age", age, handleChangeProfile(["age"]))}
        {renderProfileCard("Gender", gender, handleChangeProfile(["gender"]))}
        {renderProfileCard("Weight", weight, handleChangeProfile(["weight"]))}
        {renderProfileCard("Height", height, handleChangeProfile(["height"]))}
        {renderProfileCard(
          "Eye color",
          eyecolor,
          handleChangeProfile(["eyecolor"])
        )}
        {renderProfileCard(
          "Hair color",
          haircolor,
          handleChangeProfile(["haircolor"])
        )}
        {renderProfileCard(
          "Skin color",
          skincolor,
          handleChangeProfile(["skincolor"])
        )}
        {renderProfileCard(
          "Bodytype",
          bodytype,
          handleChangeProfile(["bodytype"])
        )}
        {renderProfileCard(
          "Faceshape",
          faceshape,
          handleChangeProfile(["faceshape"])
        )}
        {renderProfileCard(
          "Hair length",
          hairlength,
          handleChangeProfile(["hairlength"])
        )}
        {/* {renderProfileCard(
          "Special styles",
          specials,
          handleChangeProfile(["specials"])
        )} */}
        {/* {renderProfileCard(
          "Color Season",
          colorseason,
          () => {}
          // handleChangeProfile(["colorseason"])
        )} */}
        {renderProfileCard(
          "Skin Undertone",
          skinundertone,
          handleChangeProfile([
            "tanTest",
            "veins",
            "jewelry",
            "paperTest",
            "eyesFlecks",
          ])
        )}
      </View>
    </ScrollView>
  );
};

export default ProfileScreen;

const styles = StyleSheet.create({
  scrollView: { flex: 1, paddingTop: 20, backgroundColor: colors.bg },
  photo: {
    height: 200,
    width: 200,
    alignSelf: "center",
    borderRadius: 200,
    marginBottom: 30,
  },
  container: { ...containers.screenContainer },
});
