import { NavigationProp, useNavigation } from "@react-navigation/native";
import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import AuthButton from "../../components/buttons/AuthButton";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import InputEmail from "../../components/input-email/InputEmail";
import InputPassword from "../../components/input-password/InputPassword";
import ModalDialogue from "../../components/modal-dialogue/ModalDialogue";
import { getImage } from "../../helpers/resourceProvider";
import { useAuth } from "../../hooks";
import colors from "../../theme/colors";
import containers from "../../theme/containers";
import { theme } from "../../theme/defaultTheme";
import { typography } from "../../theme";
import NavigationParamList from "../../navigators/NavigationParamList";

const SignInScreen = () => {
  const {
    resetPassword,
    modalVisible,
    setModalVisible,
    email,
    setEmail,
    password,
    setPassword,
    handleSigninWithEmailAndPassword,
    handleFacebookLogin,
    handleGoogleLogin,
  } = useAuth();
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();

  return (
    <View style={styles.container}>
      <ModalDialogue
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        onPress={resetPassword}
        text="Send me an email with a link to reset the password"
        title="Reset Password"
      />
      <Image source={getImage("loginScreen")} style={styles.image} />
      <View style={styles.welcomeTextContainer}>
        <Text style={styles.h1}>Let’s sign you in,</Text>
      </View>
      <InputEmail email={email} setEmail={setEmail} />
      <InputPassword password={password} setPassword={setPassword} />
      <Text
        style={styles.forgotPasswordText}
        onPress={() => {
          setModalVisible(true);
        }}
      >
        Forgot Password?
      </Text>
      <PrimaryButton
        text={"Sign In"}
        onPress={handleSigninWithEmailAndPassword}
        style={{ marginBottom: 15 }}
      />
      <Text style={styles.orDeviderText}>Or</Text>
      <View style={styles.socialSignInContainer}>
        <AuthButton
          onPress={handleGoogleLogin}
          text={"Google"}
          icon={getImage("googleLogo")}
          style={{ width: "45%" }}
        />
        <AuthButton
          onPress={handleFacebookLogin}
          text={"Facebook"}
          icon={getImage("facebookLogo")}
          style={{ width: "45%" }}
        />
      </View>
      <Text style={styles.dontHaveAccountText}>
        Don’t have an account?{" "}
        <Text
          style={styles.signupText}
          onPress={() => navigation.navigate("SignupScreen")}
        >
          Sign Up
        </Text>
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  p1: { ...typography.p1, textAlign: "center" },
  h1: {
    ...typography.h1,
    fontFamily: theme.fontPrimaryBold,
    textAlign: "center",
    marginBottom: 10,
  },
  dontHaveAccountText: {
    ...typography.p1,
    alignSelf: "center",
  },
  signupText: {
    fontFamily: theme.fontSecondarySemibold,
  },
  socialSignInContainer: {
    alignSelf: "stretch",
    height: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  orDeviderText: {
    ...typography.p3,
    color: colors.gray,
    marginBottom: 15,
    alignSelf: "center",
  },
  forgotPasswordText: {
    ...typography.p3,
    color: colors.gray,
    alignSelf: "flex-end",
    marginBottom: 35,
    marginTop: 10,
  },
  welcomeTextContainer: {
    alignSelf: "stretch",
    marginBottom: 15,
  },
  container: {
    ...containers.screenContainer,
    minHeight: 550,
    justifyContent: "center",
  },

  image: {
    alignSelf: "center",
    marginTop: 0,
    resizeMode: "contain",
    width: 323,
    height: 250,
  },
});
export default SignInScreen;
