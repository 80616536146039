import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import React, { useState } from "react";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import Swiper from "react-native-deck-swiper";
import { LookI } from "../../types/common/LookI";
import { logEvent } from "../../helpers/analytics";
import getInternalImgUrl from "../../helpers/getImgUrl";
import { getImage } from "../../helpers/resourceProvider";
import { useLookStore } from "../../hooks";
import useUserStore from "../../hooks/useUserStore";
import colors from "../../theme/colors";
import containers from "../../theme/containers";
import { theme } from "../../theme/defaultTheme";
import SplashScreen from "../splash-screen/SplashScreen";
import { typography } from "../../theme";
import NavigationParamList from "../../navigators/NavigationParamList";
import { useGenerateLookMutation, useGetLooksAllQuery } from "../../store/api";

const swipeCard = (look: LookI, isFavorite: boolean) => {
  return (
    <View style={styles.card}>
      <Image
        source={{ uri: getInternalImgUrl(look.imageUrl as string) }}
        style={{
          height: "100%",
          position: "absolute",
          resizeMode: "contain",
          aspectRatio: look.aspectRatio,
        }}
      />
    </View>
  );
};

const noLooks = () => {
  return (
    <View
      style={[
        styles.container,
        { justifyContent: "center", alignItems: "center" },
      ]}
    >
      <Text style={typography.p1}>
        We don't have any looks in this category yet. Why don't you add some
        with "Steal the look" feature?
      </Text>
    </View>
  );
};

const DeckSwiperScreen = () => {
  const renderLoading = () => {
    return (
      <View>
        <Text>Loading...</Text>
      </View>
    );
  };
  const { getByFilter } = useLookStore();
  const { addFavoriteLook } = useUserStore();
  const { user } = useUserStore();
  const deckRef = React.useRef<Swiper<LookI>>(null);
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const route = useRoute<RouteProp<NavigationParamList, "DeckSwiper">>();

  const [isSwipedAll, setIsSwipedAll] = React.useState(false);
  const [cardIndex, setCardIndex] = useState(0);
  let { data: looksData, isFetching, refetch } = useGetLooksAllQuery();
  // const [generate, result] = useGenerateLookMutation();
  console.log("index", cardIndex);
  if (isFetching) return renderLoading();
  if (looksData == undefined || looksData.length == 0) return noLooks();
  return (
    <View style={styles.container}>
      {isSwipedAll ? (
        noLooks()
      ) : (
        <View
          style={{
            justifyContent: "flex-end",
            height: "100%",
          }}
        >
          <Swiper
            infinite={false}
            ref={deckRef}
            containerStyle={styles.container}
            cards={looksData}
            cardStyle={{
              width: "100%",
              height: "70%",
              marginTop: "10%",
            }}
            renderCard={(look: LookI) => {
              return swipeCard(
                look,

                false
              );
            }}
            onSwiped={(cardIndex) => {
              setCardIndex(cardIndex + 1);
              logEvent("swipe_look", {
                id: (looksData as LookI[])[cardIndex].id,
              });
            }}
            onSwipedAll={() => {
              refetch();
              // setIsSwipedAll(true);
            }}
            cardIndex={0}
            backgroundColor={"#4FD0E9"}
            stackSize={3}
            cardHorizontalMargin={0}
            cardVerticalMargin={0}
            verticalSwipe={false}
            stackSeparation={0}
            stackScale={0}
          />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              width: "100%",
              paddingHorizontal: "5%",
              marginTop: 20,
              position: "absolute",
              alignSelf: "center",
              bottom: "10%",
            }}
          >
            <Pressable onPress={() => deckRef.current?.swipeLeft()}>
              <Image
                source={getImage("dislike")}
                style={{ width: 50, height: 50 }}
              />
            </Pressable>
            <Pressable
              onPress={() => {
                console.log("index", cardIndex);
                navigation.navigate("ShopLook", {
                  look: (looksData as LookI[])[cardIndex],
                });
              }}
            >
              <Image
                source={getImage("cart")}
                style={{ width: 50, height: 50 }}
              />
            </Pressable>
            <Pressable onPress={() => deckRef.current?.swipeRight()}>
              <Image
                source={getImage("heart")}
                style={{ width: 50, height: 50 }}
              />
            </Pressable>
          </View>
        </View>
      )}
    </View>
  );
};

export default DeckSwiperScreen;

const styles = StyleSheet.create({
  scrollView: { flex: 1, paddingTop: 20, backgroundColor: colors.bg },

  container: {
    ...containers.screenContainer,
    justifyContent: "center",
  },
  card: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    overflow: "hidden",
    shadowColor: colors.dark,
    // marginHorizontal: 20,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 8,
    height: "100%",
    backgroundColor: colors.bg,
  },
});
