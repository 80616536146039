import { Image, StyleSheet, Text, View } from "react-native";
import React from "react";
import { useUserStore } from "../../hooks";
import { getImage } from "../../helpers/resourceProvider";
import TabLink from "./TabLink";
import { styles } from "./styles";
import { typography } from "../../theme";

const OuterwearGuide = () => {
  const { user } = useUserStore();
  if (!user) return [];
  const recommendations = user.recommendations;

  const { bodytype } = recommendations;

  const slides = [
    {
      slide: (
        <View>
          <Image
            source={{
              uri: "https://i.gyazo.com/40a2b426618ea31e99b016735b200f62.png",
            }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>Outerwear Guide</Text>
          <Text style={styles.description}>
            Find the perfect outerwear for your unique body type. Let's guide
            you to the best choices!
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink
          img={{
            uri: "https://i.gyazo.com/40a2b426618ea31e99b016735b200f62.png",
          }}
          text="Welcome"
        />
      ),
    },
    {
      slide: (
        <View>
          {/* <Image
            source={{ uri: "" }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          /> */}
          <Text style={styles.title}>Best Outerwear for Your Bodytype</Text>
          <Text style={styles.description}>
            {bodytype.explanationBestOuterwear}
          </Text>
          <View style={{ height: 20 }} />
          <Text style={typography.h3}>Examples of Best Outerwear</Text>
          <View style={{ height: 10 }} />
          <Text style={styles.description}>
            {bodytype.bestOuterwear.map((item) => (
              <Text key={item}>
                - {item} {"\n"}
              </Text>
            ))}
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink img={getImage("bestOuterwear")} text="Best Outerwear" />
      ),
    },
    // {
    //   slide: (
    //     <View>
    //       <Image
    //         source={{ uri: "" }}
    //         style={[styles.mainIllustration]}
    //         resizeMode="cover"
    //       />
    //       <Text style={styles.title}>Examples of Best Outerwear</Text>
    //       <Text style={styles.description}>
    //         {bodytype.bestOuterwear.map((item) => (
    //           <Text key={item}>
    //             {item} {"\n"}
    //           </Text>
    //         ))}
    //       </Text>
    //     </View>
    //   ),
    //   tabLink: (
    //     <TabLink
    //       img={getImage("examplesBestOuterwear")}
    //       text="Best Outerwear Examples"
    //     />
    //   ),
    // },
    {
      slide: (
        <View>
          {/* <Image
            source={{ uri: "" }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          /> */}
          <Text style={styles.title}>Outerwear to Avoid for Your Bodytype</Text>
          <Text style={styles.description}>
            {bodytype.explanationWorstOuterwear}
          </Text>
          <View style={{ height: 20 }} />
          <Text style={typography.h3}>Examples of Outerwear to Avoid</Text>
          <View style={{ height: 10 }} />
          <Text style={styles.description}>
            {bodytype.worstOuterwear.map((item) => (
              <Text key={item}>
                - {item} {"\n"}
              </Text>
            ))}
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink img={getImage("worstOuterwear")} text="Outerwear to Avoid" />
      ),
    },
    // {
    //   slide: (
    //     <View>
    //       <Image
    //         source={{ uri: "" }}
    //         style={[styles.mainIllustration]}
    //         resizeMode="cover"
    //       />
    //       <Text style={styles.title}>Examples of Outerwear to Avoid</Text>
    //       <Text style={styles.description}>
    //         {bodytype.worstOuterwear.map((item) => (
    //           <Text key={item}>
    //             {item} {"\n"}
    //           </Text>
    //         ))}
    //       </Text>
    //     </View>
    //   ),
    //   tabLink: (
    //     <TabLink
    //       img={getImage("examplesWorstOuterwear")}
    //       text="Worst Outerwear Examples"
    //     />
    //   ),
    // },
    {
      slide: (
        <View>
          <Image
            source={getImage("thanks")}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>Thank You!</Text>
          <Text style={styles.description}>
            Thank you for exploring the best and worst outerwear choices for
            your body type. Dress with flair and confidence!
          </Text>
        </View>
      ),
      tabLink: <TabLink img={getImage("thanks")} text="End" />,
    },
  ];

  return slides;
};

export default OuterwearGuide;
