import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { Image, Pressable, ScrollView, View } from "native-base";
import NavigationParamList from "../../navigators/NavigationParamList";
import { useEffect, useState } from "react";
import { env } from "../../../env";
import { useUserStore } from "../../hooks";
import Loader from "../../components/loader";
import { colors, typography } from "../../theme";
import { ProductCardV2 } from "../../components/product-card/ProductCardV2";
import ProductI from "../../types/common/ProductI";
import { getImage } from "../../helpers/resourceProvider";

export const TryOnScreen = () => {
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const route = useRoute<RouteProp<NavigationParamList, "TryOn">>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [image, setImage] = useState<string>("");
  const { user } = useUserStore();
  const [lookLocal, setLook] = useState(route.params.look);
  useEffect(() => {
    const generateLook = async (description: string) => {
      const response = await fetch(`${env.BASE_URL}imgs/try-on`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          description: description,
        }),
      });
      const data = await response.json();
      setImage(data.data);
      setIsLoading(false);
    };
    if (user?.params) {
      let description = route.params.look.lookItems.reduce(
        (acc, item) => `${acc} ${item.product?.description}, `,
        ""
      );

      description = `a ${user.params.gender} model with skin color: ${
        user.params.skincolor && user.params.skincolor[0][0]
      }, 
      haircolor: ${user.params.haircolor && user.params.haircolor[0][0]}, 
      boddytype: ${user.params.bodytype && user.params.bodytype},
      
      wearing ${description}
      standing on floor
      only one person, full body shot, full height, from head to feet, front view, fashnion photo, photorealistic, HD
      `;
      generateLook(description);
    }
  }, [user]);
  if (isLoading)
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          backgroundColor: colors.bg,
        }}
      >
        <Loader
          time={15000}
          messages={[
            "Generating looks...",
            "Generating looks...",
            "Personalizing for your type...",
            "Fetching the results...",
          ]}
          sections={10}
          textStyle={[typography.p1, { marginTop: 20 }]}
          containerStyle={{
            width: "70%",
          }}
          progressBarStyle={{
            height: 20,
          }}
          type="flow"
        />
      </View>
    );
  return (
    <View backgroundColor={colors.bg} height={"100%"}>
      <Image
        source={{ uri: image }}
        style={{ width: "100%", aspectRatio: 1 }}
      />
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={true}
        style={{ width: "100%" }}
      >
        {lookLocal.lookItems.map((item, index) => (
          <View
            style={{
              width: 150,
              marginBottom: 8,
              marginRight: 8,
              // backgroundColor: "red",
            }}
          >
            <ProductCardV2
              product={item.product as ProductI}
              key={index}
              style={{
                width: "100%",
                //  backgroundColor: "green"
              }}
            />
            <Pressable
              position="absolute"
              top={2}
              left={2}
              onPress={() =>
                navigation.navigate("ChangeProduct", {
                  onChanged: (product: ProductI) => {
                    console.log("product", product);
                    setLook((prev) => ({
                      ...prev,
                      lookItems: prev.lookItems.map((item, i) =>
                        i === index ? { ...item, product: product } : item
                      ),
                    }));
                  },
                  product: item.product as ProductI,
                })
              }
            >
              <Image
                source={getImage("filtersIcon")}
                style={{ width: 28, height: 28 }}
              />
            </Pressable>
          </View>
        ))}
      </ScrollView>
    </View>
  );
};
