import { RouteProp, useRoute } from "@react-navigation/native";
import React from "react";
import { ScrollView, StyleSheet } from "react-native";
import SubscriptionsComponent from "../../components/subscriptions-component/SubscriptionsComponent";
import colors from "../../theme/colors";
import containers from "../../theme/containers";
import { typography } from "../../theme";
import NavigationParamList from "../../navigators/NavigationParamList";

const SubscriptionsScreen = () => {
  const route = useRoute<RouteProp<NavigationParamList, "Subscription">>();
  return (
    <ScrollView style={styles.scrollView}>
      <SubscriptionsComponent context={route.params.context} />
    </ScrollView>
  );
};

export default SubscriptionsScreen;

const styles = StyleSheet.create({
  scrollView: { flex: 1, paddingTop: 20, backgroundColor: colors.bg },
  buyButton: { marginBottom: 10 },
  h1: { ...typography.h1, marginBottom: 30 },
  container: { ...containers.screenContainer },

  featuresContainer: {
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    marginBottom: 30,
    flex: 1,
  },
});
