import { useState } from "react";
import {
  FlatList,
  Image,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import GalleryPicker from "../../components/gallery-picker/GalleryPicker";
import { ProductCard } from "../../components/product-card";
import ProductList from "../../components/product-list";
import { ProductDescriptor } from "../../entities/common/ProductDescriptor";
import { getImage } from "../../helpers/resourceProvider";
import { useUserStore } from "../../hooks";
import {
  useDeleteWardrobeProductMutation,
  usePostWardrobeProductMutation,
} from "../../store/api";
import { colors, typography } from "../../theme";
import containers from "../../theme/containers";
import { Gender } from "../../types/common/Gender";
import {
  ClusteredCategory,
  getSubcategories,
} from "../../types/common/ProductI";
import { ProductDescriptorI } from "../../types/common/productDescriptorI";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import NavigationParamList from "../../navigators/NavigationParamList";
import { ProductCardV2 } from "../../components/product-card/ProductCardV2";

const WardrobeScreen = () => {
  const { user } = useUserStore();
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  // console.log(user?.favorites.wardrobe);
  if (!user) return null;

  const topProducts = user.favorites.wardrobe.filter((product) =>
    getSubcategories(ClusteredCategory.TOPS).includes(product.category)
  );
  const bottomProducts = user.favorites.wardrobe.filter((product) =>
    getSubcategories(ClusteredCategory.BOTTOMS).includes(product.category)
  );
  const shoesProducts = user.favorites.wardrobe.filter((product) =>
    getSubcategories(ClusteredCategory.SHOES).includes(product.category)
  );
  const accessoriesProducts = user.favorites.wardrobe.filter((product) =>
    getSubcategories(ClusteredCategory.ACCESSORIES).includes(product.category)
  );
  // const fullBody = user.favorites.wardrobe.filter((product) =>
  //   getSubcategories(ClusteredCategory.FULL_BODY).includes(product.category)
  // );

  const other = user.favorites.wardrobe.filter((product) =>
    getSubcategories(ClusteredCategory.OTHER).includes(product.category)
  );

  const productsCategories = [
    { name: "Tops", products: topProducts },
    { name: "Bottoms", products: bottomProducts },
    { name: "Shoes", products: shoesProducts },
    { name: "Accessories", products: accessoriesProducts },
    { name: "Other", products: other },
  ];

  return (
    <ScrollView style={{ backgroundColor: colors.bg }}>
      <View style={styles.container}>
        <View style={{ height: 20 }} />
        {/* <Text style={typography.h2}>
          Add new items to receieve outfit suggestions
        </Text> */}
        <SecondaryButton
          text={"➕ Add new items for daily suggestions"}
          onPress={() => navigation.navigate("AddNewItem")}
        />
        <View style={{ height: 20 }} />

        {productsCategories.map((category) => (
          <View style={{ width: "100%", marginBottom: 50 }}>
            <Text style={[typography.h2, { marginBottom: 20 }]}>
              {category.name}
            </Text>
            <ScrollView horizontal>
              {/* <Pressable
                style={{
                  height: 200,
                  backgroundColor: colors.plask,
                  padding: 10,
                  borderRadius: 10,
                  aspectRatio: 3 / 4,
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 10,
                }}
                onPress={() => navigation.navigate("AddNewItem")}
              >
                <Text style={typography.p2}>New item</Text>
                <View style={{ height: 10 }} />
                <Image
                  style={{ width: 30, height: 30 }}
                  source={getImage("plusIcon")}
                  resizeMode="contain"
                />
              </Pressable> */}

              {category.products.map((product) => (
                <ProductCardV2
                  product={product}
                  style={{ marginRight: 20, height: 200 }}
                />
              ))}
            </ScrollView>
          </View>
        ))}
      </View>
    </ScrollView>
  );
};

export const styles = StyleSheet.create({
  container: {
    ...containers.screenContainer,
    flex: 1,
    backgroundColor: colors.bg,
    alignItems: "center",
  },
});
export default WardrobeScreen;
