import React from "react";
import { StyleSheet, TextInput, View } from "react-native";
import { QuestionI, TextQuestionI } from "../../../types/Quiz";

export type Props = {
  question: TextQuestionI;
  onChange: (chosenAnswers: QuestionI["chosenAnswer"]) => void;
};
const TextQuestion = ({ question, onChange }: Props) => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "space-between",
      }}
    >
      <View>
        <TextInput
          style={styles.textInput}
          placeholder={question.question}
          keyboardType="default"
          value={question.chosenAnswer}
          onChangeText={(text) => onChange(text)}
        />
      </View>
    </View>
  );
};

export default TextQuestion;

const styles = StyleSheet.create({
  textInput: {
    fontSize: 18,
    backgroundColor: "white",
    borderRadius: 5,
    borderColor: "gray",
    borderWidth: 1,
    padding: 10,
    height: 50,
  },
  h2: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 12,
  },
  h3: {
    textAlign: "center",
  },
});
