import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import { api } from "./api";
import appStatusReducer from "./reducers/appStatusReducer";
import { authSlice } from "./reducers/authReducer";

import { localFiltersChosenValuesSlice } from "./reducers/localFilterReducer";
import { lookItemSlice } from "./reducers/lookItemsReducer";
import { lookSlice } from "./reducers/lookReducer";
import modalReducer from "./reducers/modalReducer";
import { quizSlice } from "./reducers/quizReducer";
import { userInfoSlice } from "./reducers/userInfoReducer";

const reducers = combineReducers({
  [api.reducerPath]: api.reducer,
  appStatus: appStatusReducer,
  modal: modalReducer,
  auth: authSlice.reducer,
  filters: localFiltersChosenValuesSlice.reducer,
  quiz: quizSlice.reducer,
  userInfo: userInfoSlice.reducer,
  looks: lookSlice.reducer,
  lookItems: lookItemSlice.reducer,
});

const store = configureStore({
  reducer: reducers,
  middleware: [thunk, api.middleware],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { api, store };
