import { RouteProp, useRoute } from "@react-navigation/native";
import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";
import LookCard from "../../components/look-card/LookCard";
import { LookI } from "../../types/common/LookI";
import { logScreenView } from "../../helpers/analytics";
import { useLookStore } from "../../hooks";
import containers from "../../theme/containers";
import NavigationParamList from "../../navigators/NavigationParamList";

const LookbookScreen = () => {
  const route = useRoute<RouteProp<NavigationParamList, "LookBook">>();
  const { getByFilter } = useLookStore();
  const looks = getByFilter(route.params.lookFilter);

  useEffect(() => {
    logScreenView(route.name, "");
  }, []);
  if (looks.looks == undefined) return <LoadingState />;
  return (
    <View style={[styles.container, { flexDirection: "row" }]}>
      {/* <MasonryList
        data={looks.looks}
        keyExtractor={(look) => look.id.toString()}
        numColumns={2}
        showsVerticalScrollIndicator={false}
        renderItem={({ item }) => (
          <View style={{ padding: 10 }}>
            <LookCard
              look={item as LookI}
              disabled={false}
              height={undefined}
              width={"100%"}
            />
          </View>
        )}
        onEndReachedThreshold={0.1}
      /> */}
    </View>
  );
};

export default LookbookScreen;

const styles = StyleSheet.create({
  container: { ...containers.screenContainer },

  list: {
    marginTop: 10,
  },
});

const LoadingState = () => {
  return (
    <View style={stylesLoading.placeholder}>
      <Placeholder Animation={Fade}>
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderLine width={80} />
        <PlaceholderLine width={60} />

        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderLine width={80} />
        <PlaceholderLine width={60} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderLine width={80} />
        <PlaceholderLine width={60} />
      </Placeholder>
    </View>
  );
};

const stylesLoading = StyleSheet.create({
  placeholder: { flex: 1 },
  placeholderBasicAndGuide: {
    width: "100%",
    height: 300,
    borderRadius: 5,
    marginBottom: 10,
  },
  placeholderOccasion: {
    width: "30%",
    height: 140,
    borderRadius: 5,
    marginBottom: 10,
  },
});
