import React from "react";
import {
  Image,
  ImageSourcePropType,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { typography } from "../../theme";

export type TabLinkProps = {
  img: ImageSourcePropType;
  text: string;
};

const TabLink = ({ img, text }: TabLinkProps) => {
  return (
    <View style={styles.tabLinkContainer}>
      <Image source={img} style={styles.tabLinkImg} />
      <Text style={styles.tabLinkText}>{text}</Text>
    </View>
  );
};

export default TabLink;

const styles = StyleSheet.create({
  tabLinkContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tabLinkImg: {
    width: 40,
    height: 40,
    borderRadius: 7,
    marginRight: 10,
  },
  tabLinkText: {
    ...typography.p1,
    color: "#151515",
    textTransform: "capitalize",
  },
});
