import {
  Image,
  ImageSourcePropType,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React from "react";
import { theme } from "../../theme/defaultTheme";
import colors from "../../theme/colors";
import { getImage } from "../../helpers/resourceProvider";
import { typography } from "../../theme";

export type Props = {
  onPress: () => void;
  image: ImageSourcePropType;
  header: string;
  description: string;
  style?: any;
  children?: any;
  ar?: number;
};

const RectangleCard = ({
  onPress,
  image,
  header,
  description,
  style,
  children,
  ar = 2.43,
}: Props) => {
  return (
    <Pressable style={[styles.container, style]} onPress={onPress}>
      <View style={{}}>
        <Image source={image} style={[styles.image]} />
        <View style={{ position: "absolute", top: 8, right: 8 }}>
          {children}
        </View>
      </View>
      <View style={styles.headerContainer}>
        <Text style={styles.header}>{header}</Text>
        <Image
          source={getImage("arrowForwardRounded")}
          style={styles.arrow}
          // resizeMethod="scale"
        />
      </View>
      <Text style={[styles.description, ar > 1 && { maxWidth: "66%" }]}>
        {description}
      </Text>
    </Pressable>
  );
};

export default RectangleCard;

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  arrow: {
    width: 18,
    height: 18,
    marginLeft: 10,
  },
  header: {
    fontFamily: theme.fontSecondarySemibold,
    fontSize: typography.h4.fontSize,
    letterSpacing: 0.5,
    alignSelf: "center",
    color: colors.dark,
    numberOfLines: 1,
    maxHeight: typography.h4.fontSize + 5,
    overflow: "hidden",
  },
  description: {
    fontFamily: theme.fontSecondaryRegular,
    fontSize: typography.p3.fontSize,
    color: colors.gray,
    letterSpacing: "1%",
    numberOfLines: 2,
    maxHeight: typography.p3.fontSize * 2.5 * 1.4,
    overflow: "hidden",
  },
  container: {
    overflow: "hidden",
    // backgroundColor: "yellow",
    width: "100%",
  },
  image: {
    // aspectRatio: 2.43,
    borderRadius: 10,
    marginBottom: 10,
    height: 150,
    width: "100%",
    // backgroundColor: "red",
    resizeMode: "cover",
    // width: "100%",
    // height: "100%",
  },
});
