import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { MultipleQuestionI, QuestionI } from "../../../types/Quiz";
import MultipleQuestionOption from "./MultipleQuestionOption";
import MultipleQuestionLine from "./MultipleQuestionLine";

export type Props = {
  question: MultipleQuestionI;
  onChange: (chosenAnswers: QuestionI["chosenAnswer"]) => void;
};
const MultipleOptionsQuestion = ({ question, onChange }: Props) => {
  const handlePress = (index: number) => {
    if (question.numberOfOptions == 1) {
      onChange(question.option[index].value);
    } else {
      const chosenAnswer = question.chosenAnswer as Array<any>;
      if (chosenAnswer.includes(question.option[index].value)) {
        onChange([
          ...chosenAnswer.filter(
            (answer) => answer != question.option[index].value
          ),
        ]);
      } else {
        onChange([...chosenAnswer, question.option[index].value]);
      }
    }
  };

  return (
    <ScrollView>
      <View
        style={{
          ...styles.linearLayout,
        }}
      >
        {question.option.map((option, index) => {
          const isSelected =
            question.numberOfOptions == 1
              ? question.chosenAnswer == option.value
              : (question.chosenAnswer as Array<any>).includes(
                  question.option[index].value
                );
          return (
            // <MultipleQuestionOption
            //   key={index}
            //   option={option}
            //   onPress={() => handlePress(index)}
            //   isSelected={isSelected}
            //   style={{ width: "40%" }}
            // />
            <MultipleQuestionLine
              key={index}
              option={option}
              onPress={() => handlePress(index)}
              isSelected={isSelected}
              style={{}}
            />
          );
        })}
      </View>
    </ScrollView>
  );
};

export default MultipleOptionsQuestion;

const styles = StyleSheet.create({
  h2: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 12,
  },
  h3: {
    textAlign: "center",
  },

  squareLayout: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  linearLayout: {
    flexDirection: "column",
    flexWrap: "nowrap",
  },
});
