import { UserParamsI } from "../types/common/UserParamsI";
import {
  TextQuestionI,
  QuestionType,
  ValidationResponseI,
} from "../types/Quiz";

export class TextQuestion implements TextQuestionI {
  type = QuestionType.TEXT;
  constructor(
    public result: keyof UserParamsI,
    public question: string,
    public chosenAnswer: string,
    public validation: (
      answer: UserParamsI[keyof UserParamsI][] | UserParamsI[keyof UserParamsI]
    ) => ValidationResponseI
  ) {}
}
