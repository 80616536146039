import { StyleSheet } from "react-native";
import { colors, typography } from "../../theme";
import { theme } from "../../theme/defaultTheme";

export const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: colors.bg,
  },
  title: {
    ...typography.h1,

    // fontFamily: theme.fontPrimaryBold,
    // fontSize: 24,
    // fontWeight: "bold",
    marginBottom: 10,
    textTransform: "capitalize",
  },
  subtitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 10,
  },
  description: {
    ...typography.p1,
    marginBottom: 10,
  },
  colorContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
    flexWrap: "wrap",
  },
  colorBox: {
    width: 60,
    height: 60,
    borderRadius: 10,
    margin: 4,
  },
  celebritiesContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  celebrityBox: {
    width: "48%",
    marginBottom: 20,
  },
  celebrityImage: {
    width: "100%",
    height: 150,
    resizeMode: "cover",
    borderRadius: 10,
    marginBottom: 10,
  },
  celebrityName: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
  },
  mainIllustration: {
    width: "100%",
    height: 320,
    borderRadius: 10,
    marginBottom: 20,
  },
});
