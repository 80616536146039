import { randomInteger } from "../../helpers/RandomInteger";
import { Gender } from "../../types/common/Gender";
import { LookI, LookItemI, Style } from "../../types/common/LookI";

export class Look implements LookI {
  constructor(
    public style: Style,
    public gender: Gender,
    public id: number,
    public name: string,
    public description: string,
    public imageUrl: string | undefined,
    public imageBase64: string | undefined,
    public lookItems: LookItemI[],
    public keywords: string[],
    public year: number,
    public isPublic: boolean,
    public authorId: string,
    public aspectRatio: number
  ) {}

  static createEmpty() {
    return new Look(
      "BASIC",
      Gender.FEMALE,
      randomInteger(0, Number.MAX_SAFE_INTEGER),
      "",
      "",
      undefined,
      undefined,
      [],
      [],
      0,
      false,
      "",
      1
    );
  }
}
