import React from "react";
import { StyleSheet, Text } from "react-native";
import { typography } from "../../theme";
import colors from "../../theme/colors";
import { LinearGradient } from "expo-linear-gradient";

export type PremiumLabelProps = {
  text: string;
  style?: any;
  textStyle?: any;
};
const PremiumLabel = ({ text, style, textStyle }: PremiumLabelProps) => {
  return (
    <LinearGradient
      colors={["#CE00F0", "#3500F0"]}
      start={{ x: 0.1, y: 0.1 }}
      end={{ x: 0.9, y: 0.9 }}
      style={[styles.proButton, style]}
    >
      <Text style={[{ ...typography.p3, color: colors.white }, textStyle]}>
        {text}
      </Text>
    </LinearGradient>
  );
};

export default PremiumLabel;

const styles = StyleSheet.create({
  proButton: {
    paddingHorizontal: 24,
    paddingVertical: 8,
    borderRadius: 40,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
});
