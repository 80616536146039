import React, { useEffect, useRef, useState } from "react";
import { Text, View, StyleSheet, Animated } from "react-native";
import { colors } from "../../theme";
import { LinearGradient } from "expo-linear-gradient";

interface LoaderProps {
  messages: string[];
  time: number;
  sections: number;
  textStyle?: any;
  containerStyle?: any;
  progressBarStyle?: any;
  type: "sections" | "flow";
}

const Loader = ({
  messages,
  time,
  sections,
  textStyle,
  containerStyle,
  progressBarStyle,
  type,
}: LoaderProps) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [completedSections, setCompletedSections] = useState(0);
  const durationPerMessage = time / messages.length;
  const durationPerSection = time / sections;
  const animation = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCompletedSections((prevState) => prevState + 1);
    }, durationPerSection);

    return () => clearInterval(intervalId);
  }, [time, sections, durationPerSection]);

  useEffect(() => {
    Animated.timing(animation, {
      toValue: 0,
      duration: time,
      useNativeDriver: false,
    }).start();

    const intervalId = setInterval(() => {
      setCurrentMessageIndex((prevState) => {
        const nextIndex = prevState + 1;
        if (nextIndex < messages.length) {
          return nextIndex;
        } else {
          clearInterval(intervalId);
          return prevState;
        }
      });
      // setCompletedSections((prevState) => prevState + 1);
    }, durationPerMessage);

    return () => clearInterval(intervalId);
  }, [time, messages.length, durationPerMessage]);

  const width = animation.interpolate({
    inputRange: [0, 1],
    outputRange: ["0%", "100%"],
  });
  return (
    <View style={[styles.container, containerStyle]}>
      <LinearGradient
        colors={["#CE00F0", "#3500F0"]}
        start={{ x: 0.1, y: 0.1 }}
        end={{ x: 0.9, y: 0.9 }}
        style={[styles.progressBar, progressBarStyle]}
      >
        {/* <View style={[styles.progressBar, progressBarStyle]}> */}
        {type === "flow" ? (
          <Animated.View style={{ ...styles.progressBarFlow, width }} />
        ) : (
          <View>
            {new Array(sections).fill(undefined).map((_, index) => (
              <View
                key={index}
                style={[
                  styles.progressSection,
                  {
                    flex: 1,
                    backgroundColor:
                      index < completedSections ? "transparent" : "#00f",
                  },
                ]}
              />
            ))}
          </View>
        )}
        {/* </View> */}
      </LinearGradient>
      <Text style={[styles.text, textStyle]}>
        {messages[currentMessageIndex]}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "red",
    width: 300,
    // height: 20,
  },
  text: {
    fontSize: 20,
    marginBottom: 20,
  },
  progressBarFlow: {
    height: 20,
    backgroundColor: colors.plask,
  },
  progressBar: {
    flexDirection: "row-reverse",
    height: 20,
    width: "100%",
    backgroundColor: colors.blue,
    overflow: "hidden",
    borderRadius: 10,
  },
  progressSection: {
    // borderRightWidth: 1,
    // borderRightColor: "#fff",

    marginHorizontal: 2,
    borderRadius: 2,
  },
});

export default Loader;
