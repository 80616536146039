import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { setStatus } from "../store/reducers/appStatusReducer";

const useAppStatus = () => {
  const message = useSelector((state: RootState) => state.appStatus.message);
  const isShowing = useSelector(
    (state: RootState) => state.appStatus.isShowing
  );
  const dispatch = useDispatch();

  const showMessage = (message: string, duration: number = 5000) => {
    console.log("showMessage", message);
    dispatch(
      setStatus({
        message: message,
        isShowing: true,
      })
    );
    setTimeout(() => {
      dispatch(
        setStatus({
          message: "",
          isShowing: false,
        })
      );
    }, duration);
  };
  return {
    message,
    isShowing,
    showMessage,
  };
};

export default useAppStatus;
