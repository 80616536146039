import { RouteProp, useRoute } from "@react-navigation/native";
import React, { useRef, useState } from "react";
import { Dimensions, ScrollView, StyleSheet, Text, View } from "react-native";
import DrawerLayout from "react-native-drawer-layout";
import { Fade, Placeholder, PlaceholderMedia } from "rn-placeholder";
import { PrimaryButton } from "../../components/buttons";
import FiltersPage from "../../components/filters-page";
import ProductList from "../../components/product-list";
import { ProductDescriptor } from "../../entities/common/ProductDescriptor";
import { LookI } from "../../types/common/LookI";

import { PriceFilter } from "../../entities/common/PriceFilter";
import { objectToIntHash } from "../../helpers/objectToIntHash";
import { useGlobalFiltersStore } from "../../hooks";
import NavigationParamList from "../../navigators/NavigationParamList";
import {
  getProductsResponse,
  useGetProductDescriptorByIdQuery,
} from "../../store/api";
import { typography } from "../../theme";
import colors from "../../theme/colors";
import containers from "../../theme/containers";
import { theme } from "../../theme/defaultTheme";
import { ProductDescriptorI } from "../../types/common/productDescriptorI";

const renderProductListAndDetails = (
  index: number,
  getProductsResponse?: getProductsResponse,
  productDescriptor?: ProductDescriptorI,
  id?: ProductDescriptor["id"]
) => {
  const { globalFilters } = useGlobalFiltersStore();

  const [priceFilter, setPriceFilter] = useState<PriceFilter>({
    minPrice: globalFilters.chosenValues?.priceFilter?.minPrice || 0,
    maxPrice: globalFilters.chosenValues?.priceFilter?.maxPrice || 100,
  });
  // console.log("productDescriptor", productDescriptor);
  // console.log("id", id);
  // console.log("getProductsResponse", getProductsResponse);
  // const [keyState, setKeyState] = useState(Math.random());
  let keyState = objectToIntHash(productDescriptor);
  console.log("keyState", keyState);
  if (!productDescriptor && !id && !getProductsResponse)
    throw new Error("No productDescriptor or id provided");

  if (
    getProductsResponse == undefined &&
    productDescriptor == undefined &&
    id
  ) {
    console.log("productDescriptor is undefined");
    productDescriptor = useGetProductDescriptorByIdQuery(id).data;
  }
  if (!productDescriptor)
    return <LoadingStateProductDescriptor key={keyState} />;

  return (
    <View style={styles.itemsContainer} key={keyState + 1}>
      <Text style={styles.h}>
        {index + 1} {productDescriptor.name}
      </Text>
      <Text style={styles.p}>{productDescriptor.description}</Text>
      {/* <ColorFilterPicker
        productDescriptor={productDescriptor}
        colorScheme={colorScheme}
        key={keyState + 2}
      /> */}
      {/* <PriceSlider
        productDescriptor={productDescriptor}
        currency={"$"}
        key={keyState + 3}
      /> */}
      {/* <Text style={styles.heading2}>
        Price {priceFilter.minPrice} {priceFilter.maxPrice}
      </Text> */}
      {/* <RangeSlider
        key={keyState + 3}
        range={[priceFilter.minPrice, priceFilter.maxPrice]}
        style={{ width: "100%", height: 40 }}
        minimumValue={globalFilters.chosenValues?.priceFilter?.minPrice || 0}
        maximumValue={globalFilters.chosenValues?.priceFilter?.maxPrice || 100}
        // minimumTrackTintColor={colors.blue}
        // maximumTrackTintColor={colors.plask}
        thumbTintColor={colors.blue}
        // step={1}
        onSlidingComplete={(value: [number, number]) =>
          setPriceFilter({ minPrice: value[0], maxPrice: value[1] })
        }
      /> */}
      <ProductList
        key={keyState + 4}
        productDescriptor={productDescriptor}
        products={getProductsResponse?.products}
        filters={{
          priceFilter,
        }}
      />
    </View>
  );
};

const ShopLookScreen = () => {
  const route = useRoute<RouteProp<NavigationParamList, "ShopLook">>();
  const { globalFilters } = useGlobalFiltersStore();

  const look: LookI = route.params.look;
  // const { data, isLoading } = useGetProductsByLookQuery({
  //   look: look,
  //   filters: globalFilters.chosenValues as FiltersI,
  //   offset: 0,
  //   limit: 20,
  // });
  // console.log("data", data);
  const drawer = useRef<DrawerLayout>(null);
  const [isBlurred, setBlurred] = useState(false);
  // const colorFilters = useColorScheme(look, acceptableColorDelta);
  const screenWidth = Math.min(
    Dimensions.get("window").width,
    containers.rootContainer.maxWidth
  );
  const drawerWidth = screenWidth * 0.6;
  if (!look) return <LoadingState />;
  return (
    // <DrawerLayout
    //   ref={drawer}
    //   drawerWidth={drawerWidth}
    //   drawerPosition={"right"}
    //   renderNavigationView={() => {}}
    //   onDrawerOpen={() => setBlurred(true)}
    //   onDrawerClose={() => setBlurred(false)}
    // >
    <ScrollView style={styles.scrollView}>
      <View style={styles.container}>
        {/* <View style={styles.lookCardContainer}>
            <LookCard look={look} disabled={true} height={300} />
          </View> */}
        <View style={{ marginBottom: 30 }}>
          <PrimaryButton
            text="Filters"
            onPress={() => drawer.current?.openDrawer()}
          />
        </View>
        {look.lookItems.map((lookitem, index) => {
          // const response = data[lookitem.productDescriptor?.id as number];
          // console.log("response", response);
          return renderProductListAndDetails(
            index,

            undefined,
            lookitem.productDescriptor,
            lookitem.productDescriptorId
            // colorFilters
          );
        })}
      </View>
    </ScrollView>
    // </DrawerLayout>
  );
};

const styles = StyleSheet.create({
  colorScheme: {
    height: 150,
    paddingVertical: 10,
  },
  scrollView: { flex: 1, paddingTop: 20, backgroundColor: colors.bg },
  itemsContainer: { marginBottom: 30 },
  lookCardContainer: { alignSelf: "center" },
  container: { ...containers.screenContainer },

  h: { ...typography.h2, marginBottom: 20 },
  navigationContainer: {
    backgroundColor: "#ecf0f1",
  },
  p: {
    ...typography.p2,
    marginBottom: 10,
    lineHeight: 20,
  },
  heading1: {
    fontFamily: theme.fontSecondaryBold,
    fontWeight: "bold",
    color: colors.dark,
    marginTop: 10,
    fontSize: 30,
    textTransform: "capitalize",
  },
  heading2: {
    fontFamily: theme.fontSecondaryRegular,
    fontWeight: "bold",
    color: colors.dark,
    marginTop: 10,
    fontSize: 20,
    textTransform: "capitalize",
  },
  paragraph: {
    fontFamily: theme.fontSecondaryRegular,
    fontStyle: "italic",
    color: colors.gray,
    marginTop: 10,
    fontSize: 15,
    textTransform: "capitalize",
  },
  list: {
    marginTop: 10,
  },
  title: {
    fontWeight: "bold",
  },
  basicWardrobeImage: {
    width: "100%",
    height: 200,
  },
});

export default ShopLookScreen;

const LoadingState = () => {
  return (
    <View style={stylesLoading.placeholder}>
      <Placeholder Animation={Fade}>
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
      </Placeholder>
    </View>
  );
};

const LoadingStateProductDescriptor = () => {
  return (
    <View style={stylesLoading.placeholder}>
      <Placeholder Animation={Fade}>
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
      </Placeholder>
    </View>
  );
};

const stylesLoading = StyleSheet.create({
  placeholder: { flex: 1 },
  placeholderBasicAndGuide: {
    width: "100%",
    height: 140,
    borderRadius: 5,
    marginBottom: 10,
  },
  placeholderOccasion: {
    width: "30%",
    height: 140,
    borderRadius: 5,
    marginBottom: 10,
  },
});
