import { Filters } from "./Filters";
import { FiltersUnitI, FiltersI } from "../../types/common/FiltersI";

export class FiltersUnit implements FiltersUnitI {
  constructor(
    public isLoading: boolean,
    public possibleValues: FiltersI | undefined,
    public chosenValues: FiltersI | undefined
  ) {}

  static createEmpty() {
    return new FiltersUnit(true, Filters.createEmpty(), Filters.createEmpty());
  }
}
