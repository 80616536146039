import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ModalI {
  content: React.ReactNode;
  isShowing: boolean;
}

const initialState: ModalI = {
  content: null,
  isShowing: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState: initialState,
  reducers: {
    setStatus: (
      state,
      action: PayloadAction<{ isShowing: boolean; content: ModalI["content"] }>
    ) => {
      state.content = action.payload.content;
      state.isShowing = action.payload.isShowing;
    },
  },
});

export const { setStatus } = modalSlice.actions;
export default modalSlice.reducer;
