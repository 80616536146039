import React, {
  ReactNode,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { Animated, Dimensions, PanResponder, View } from "react-native";

interface Props {
  children: ReactNode;
  drawerContent: ReactNode;
  drawerHeight: number; // The maximum height of the open drawer
  closedDrawerHeight: number; // The height of the closed drawer
}

export interface DrawerLayoutCustomHandles {
  openDrawer: () => void;
  closeDrawer: () => void;
}

const DrawerLayoutCustom = forwardRef<DrawerLayoutCustomHandles, Props>(
  ({ children, drawerContent, drawerHeight, closedDrawerHeight }, ref) => {
    const screenHeight = Dimensions.get("window").height;
    const pan = useRef(
      new Animated.ValueXY({ x: 0, y: screenHeight - closedDrawerHeight })
    ).current;
    const [startY, setStartY] = useState(0);

    useImperativeHandle(ref, () => ({
      openDrawer: () => {
        Animated.spring(pan, {
          toValue: { x: 0, y: screenHeight - drawerHeight },
          useNativeDriver: false,
        }).start();
      },
      closeDrawer: () => {
        Animated.spring(pan, {
          toValue: { x: 0, y: screenHeight - closedDrawerHeight },
          useNativeDriver: false,
        }).start();
      },
    }));

    // const panResponder = PanResponder.create({
    //   //   onStartShouldSetPanResponder: (evt, gestureState) => {
    //   //     setStartY(gestureState.y0);
    //   //     return true;
    //   //   },
    //   onPanResponderMove: (_, gestureState) => {
    //     const y = startY - gestureState.moveY;
    //     pan.setValue({
    //       x: 0,
    //       y: Math.min(
    //         Math.max(y, screenHeight - drawerHeight),
    //         screenHeight - closedDrawerHeight
    //       ),
    //     });
    //   },
    //   onPanResponderRelease: (_, gestureState) => {
    //     if (gestureState.vy > 0) {
    //       Animated.spring(pan, {
    //         toValue: { x: 0, y: screenHeight - closedDrawerHeight },
    //         useNativeDriver: false,
    //       }).start();
    //     } else {
    //       Animated.spring(pan, {
    //         toValue: { x: 0, y: screenHeight - drawerHeight },
    //         useNativeDriver: false,
    //       }).start();
    //     }
    //   },
    // });
    const panResponder = PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderMove: (e, gestureState) => {
        // console.log("gestureState", gestureState);
        // console.log("pan", pan);
        // console.log("e", e);
        // Animated.event([null, { dy: pan.y  - pan.}], {
        //   useNativeDriver: false,
        // });
        pan.setValue({
          x: 0,
          y: Math.min(
            Math.max(gestureState.moveY, screenHeight - drawerHeight),
            screenHeight - closedDrawerHeight
          ),
        });
      },
      onPanResponderRelease: (_, gestureState) => {
        if (gestureState.dy > 0) {
          Animated.spring(pan, {
            toValue: {
              x: 0,
              y: Dimensions.get("window").height - closedDrawerHeight,
            },
            useNativeDriver: false,
          }).start();
        } else {
          Animated.spring(pan, {
            toValue: {
              x: 0,
              y: Dimensions.get("window").height - drawerHeight,
            },
            useNativeDriver: false,
          }).start();
        }
      },
    });
    return (
      <View style={{ flex: 1 }}>
        {children}
        <Animated.View
          {...panResponder.panHandlers}
          style={[
            {
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: screenHeight,
            },
            { transform: pan.getTranslateTransform() },
          ]}
        >
          {drawerContent}
        </Animated.View>
      </View>
    );
  }
);

export default DrawerLayoutCustom;
