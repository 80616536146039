import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { typography } from "../../../theme";
import PrimaryButton from "../../buttons/PrimaryButton";

export type Props = {
  startTest: () => void;
  onExit: () => void;
  exitText: string;
};

const IntroQuiz = ({ startTest, onExit, exitText }: Props) => {
  return (
    <View style={styles.container}>
      <Text style={[typography.h1, { textAlign: "center" }]}>
        Lets personalize your experience
      </Text>
      {/* <View>
        <PrimaryButton
          text="Lets go"
          onPress={startTest}
          style={{ marginBottom: 10 }}
        />
        <Text onPress={onExit} style={styles.dontHaveAccountText}>
          {exitText}
        </Text>
      </View> */}
    </View>
  );
};

export default IntroQuiz;

const styles = StyleSheet.create({
  h1: {
    ...typography.h1,
  },
  container: {
    flex: 1,
    justifyContent: "space-between",
  },
  dontHaveAccountText: {
    ...typography.p1,
    alignSelf: "center",
    marginTop: 40,
  },
});
