import React from "react";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import colors from "../../theme/colors";
import { theme } from "../../theme/defaultTheme";
import { typography } from "../../theme";

export type Props = {
  image: number;
  name: string;
  details: string;
  onPress: () => void;
};

const SettingsItem = ({ image, name, details, onPress }: Props) => {
  return (
    <Pressable style={styles.container} onPress={onPress}>
      <View style={styles.imageContainer}>
        <Image source={image} style={styles.image} resizeMode="contain" />
      </View>
      <View>
        <Text style={styles.name}>{name}</Text>
        <Text style={styles.details}>{details}</Text>
      </View>
    </Pressable>
  );
};

export default SettingsItem;

const styles = StyleSheet.create({
  container: {
    height: 50,
    flexDirection: "row",
    alignItems: "flex-start",
    borderBottomColor: colors.plask,
    borderBottomWidth: 1,
    marginBottom: 10,
  },
  imageContainer: {
    width: 40,
    height: 40,
    backgroundColor: colors.bg,
    borderRadius: 50,
    marginRight: 20,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "60%",
    height: "60%",
  },
  name: {
    ...typography.p1,
    fontFamily: theme.fontSecondarySemibold,
    color: colors.dark,
  },
  details: {
    ...typography.p2,

    fontFamily: theme.fontSecondaryRegular,

    color: colors.gray,
  },
});
