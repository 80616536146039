import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuestionI } from "../../types/Quiz";

export interface AuthStateI {
  isLoggedIn: boolean;
  token: undefined | string;
}

export type quizStateI = {
  currentQuestion: number;
  questions: QuestionI[];
  isQuizTaken: boolean;
};

const initialState: quizStateI = {
  currentQuestion: -1,
  questions: [],
  isQuizTaken: false,
};

export const quizSlice = createSlice({
  name: "quiz",
  initialState: initialState,
  reducers: {
    init_test: (state, action: PayloadAction<{ initState: quizStateI }>) => {
      state.currentQuestion = action.payload.initState.currentQuestion;
      state.questions = action.payload.initState.questions;
    },
    next_question: (state) => {
      state.currentQuestion = state.currentQuestion + 1;
    },
    choose_answer: (
      state,
      action: PayloadAction<{ chosenAnswer: QuestionI["chosenAnswer"] }>
    ) => {
      state.questions[state.currentQuestion] = {
        ...state.questions[state.currentQuestion],
        chosenAnswer: action.payload.chosenAnswer,
      };
    },
    previous_question: (state) => {
      state.currentQuestion = state.currentQuestion - 1;
    },
    finish_quiz: (state) => {
      state.isQuizTaken = true;
    },
  },
});

export const {
  init_test,
  next_question,
  choose_answer,
  previous_question,
  finish_quiz,
} = quizSlice.actions;
export default quizSlice.reducer;
