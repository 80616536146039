import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { LookI } from "../../types/common/LookI";
import { useLookStore, useUserStore } from "../../hooks";
import LookCard from "./LookCard";
import { useGetLookByIdQuery } from "../../store/api";
import LookCardByProducts from "../look-card-by-products/LookCardByProducts";
import LookActionsBar from "../../screens/generate-look-screen/LookActionsBar";
import { useNavigation, NavigationProp } from "@react-navigation/native";
import NavigationParamList from "../../navigators/NavigationParamList";
import { LookCardV2 } from "../look-card-v2/LookCardV2";
import { setLookItem } from "../../store/reducers/lookItemsReducer";
import { useDispatch } from "react-redux";
import { LookCardV2InLine } from "../look-card-v2/LookCarV2InLine";

export type Props = {
  lookId: LookI["id"];
  element: (look: LookI) => JSX.Element;
};

const LookByIdCard = ({ lookId, element }: Props) => {
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const { removeFavoriteLook } = useUserStore();
  const look = useGetLookByIdQuery(lookId).data;
  const dispatch = useDispatch();
  useEffect(() => {
    if (look) {
      look.lookItems.forEach((lookItem) => {
        dispatch(
          setLookItem({
            lookItem: lookItem,
            id: lookItem.id,
          })
        );
      });
    }
  }, [look]);

  if (!look) return null;
  return element(look);
};

export default LookByIdCard;

const styles = StyleSheet.create({});
