import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import React from "react";
import { MultipleQuestionOptionI } from "../../../types/Quiz";
import { colors, typography } from "../../../theme";
import { theme } from "../../../theme/defaultTheme";
import { getImage } from "../../../helpers/resourceProvider";

export type MultipleQuestionLineProps = {
  option: MultipleQuestionOptionI;
  onPress: () => void;
  isSelected: boolean;
  style: any;
};

const MultipleQuestionLine = ({
  option,
  onPress,
  isSelected,
  style,
}: MultipleQuestionLineProps) => {
  const isImageExisting = option.image != "" && option.image != undefined;
  const isElementExisting = option.element != undefined;
  var numberOfAttributes = 0;
  if (isImageExisting) numberOfAttributes++;
  if (option.caption != undefined) numberOfAttributes++;
  if (option.paragraph != undefined) numberOfAttributes++;

  return (
    <Pressable
      style={[styles.container, isSelected ? styles.pressed : {}, style]}
      onPress={onPress}
    >
      {isImageExisting && (
        <View style={styles.imageContainer}>
          <Image
            style={styles.image}
            // @ts-ignore
            source={getImage(option.image)}
            resizeMode="contain"
          />
        </View>
      )}
      {isElementExisting && option.element}
      <Text style={styles.h}>{option.caption}</Text>
      <Text style={styles.p} numberOfLines={10}>
        {option.paragraph}
      </Text>
    </Pressable>
  );
};

export default MultipleQuestionLine;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    padding: 10,
    backgroundColor: colors.plask,
    marginBottom: 5,
    borderRadius: 10,
    height: 70,
    // aspectRatio: 1,
    borderColor: colors.plask,
    borderWidth: 2,
  },
  imageContainer: {
    overflow: "hidden",
    width: "20%",
    height: "90%",
  },
  image: {
    resizeMode: "contain",
    height: "100%",
    width: "100%",
    // backgroundColor: "red",
  },
  h: {
    color: "black",
    alignSelf: "center",
    fontSize: typography.p2.fontSize,
    fontFamily: theme.fontSecondaryBold,
  },
  p: {
    color: colors.gray,
    alignSelf: "center",
    fontSize: typography.p3.fontSize,
    fontFamily: theme.fontSecondaryRegular,
  },
  pressed: {
    borderColor: colors.blue,
  },
});
