import React from "react";
import { Pressable, StyleSheet, Text } from "react-native";
import colors from "../../theme/colors";
import { theme } from "../../theme/defaultTheme";
import { typography } from "../../theme";

export type Props = {
  text: string;
  onPress?: () => void;
  style?: any;
};
const SecondaryButton = ({ text, onPress, style }: Props) => {
  return (
    <Pressable
      onPress={onPress}
      style={[styles.container, style]}
      disabled={onPress && false}
    >
      <Text style={styles.text}>{text}</Text>
    </Pressable>
  );
};

export default SecondaryButton;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: colors.plask,
    borderWidth: 2,
    borderColor: colors.plask,
    borderRadius: 8,
    padding: 12,
  },
  text: {
    ...typography.button,
    color: colors.dark,
    textAlign: "center",
  },
});
