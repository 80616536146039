import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Filters } from "../entities/common/Filters";
import { FiltersUnit } from "../entities/common/FiltersUnit";
import { RootState } from "../store";
import { useGetProductsQuery } from "../store/api";
import { setGlobalFiltersChosenValue } from "../store/reducers/localFilterReducer";
import { GlobalFiltersStoreI } from "../types/FiltersStore";
import { FiltersI, FiltersUnitI } from "../types/common/FiltersI";
import { Gender } from "../types/common/Gender";

const emptyFilters = Filters.createEmpty();

const useGlobalFiltersStore = (): GlobalFiltersStoreI => {
  const dispatch = useDispatch();

  const globalFiltersChosenValues: FiltersI | undefined = useSelector(
    (state: RootState) => state.filters.globalFiltersChosenValues
  );

  const {
    data: getPruductsData,
    isLoading: isGlobalFilterLoading,
    isFetching: isGlobalFilterFetching,
  } = useGetProductsQuery({
    lookItem: {
      description: "",
      color: "",
      category: undefined,
      id: 0,
      filters: emptyFilters,
      imageUrl: "",
      gender: Gender.FEMALE,
    },
    limit: 1,
    offset: 0,
  });

  const globalFilters: FiltersUnitI = new FiltersUnit(
    isGlobalFilterLoading || isGlobalFilterFetching,
    getPruductsData?.filters,
    globalFiltersChosenValues
  );

  useEffect(() => {
    if (getPruductsData && !globalFiltersChosenValues) {
      setGlobalFilters(getPruductsData.filters);
    }
  }, [getPruductsData?.filters]);

  const setGlobalFilters = (value: FiltersI): void => {
    dispatch(
      setGlobalFiltersChosenValue({
        chosenValues: value,
      })
    );
  };

  return {
    globalFilters,
    setGlobalFilters,
  };
};

export default useGlobalFiltersStore;
