export default {
  xxxs: 2,
  xxs: 4,
  xs: 6,
  s: 8,
  ssm: 10,
  sm: 12,
  smm: 14,
  m: 16,
  mml: 18,
  ml: 20,
  mll: 22,
  l: 24,
  xl: 32,
  xxl: 40,
  xxxl: 48,
} as const;
