import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppStatusI {
  message: string;
  isShowing: boolean;
}

const initialState: AppStatusI = {
  message: "",
  isShowing: false,
};

export const appStatusSlice = createSlice({
  name: "status",
  initialState: initialState,
  reducers: {
    setStatus: (
      state,
      action: PayloadAction<{ isShowing: boolean; message: string }>
    ) => {
      state.message = action.payload.message;
      state.isShowing = action.payload.isShowing;
    },
  },
});

export const { setStatus } = appStatusSlice.actions;
export default appStatusSlice.reducer;
