import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import objectHash from "object-hash";
import React, { useEffect } from "react";
import { BackHandler, StyleSheet, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import {
  CameraQuestion,
  ColorPickerQuestion,
  MultipleOptionsQuestion,
  SliderQuestion,
  TextQuestion,
} from "../../components/quiz";
import IntroQuiz from "../../components/quiz/intro-quiz/IntroQuiz";
import {
  ColorPickerQuestionI,
  MultipleQuestionI,
  NumberQuestionI,
  PhotoQuestionI,
  QuestionI,
  QuestionType,
  TextQuestionI,
} from "../../types/Quiz";
import { UserParamsI } from "../../types/common/UserParamsI";

import { logQuiz } from "../../helpers/analytics";
import useAppStatus from "../../hooks/useAppStatus";
import { RootState } from "../../store";
import {
  choose_answer,
  finish_quiz,
  init_test,
  next_question,
  previous_question,
  quizStateI,
} from "../../store/reducers/quizReducer";
import { typography } from "../../theme";
import colors from "../../theme/colors";
import containers from "../../theme/containers";
import { AuthenticatedStackNavigatorParamList } from "../../navigators/AuthenticatedTabNavigator";

export const renderQuestion = (
  question: QuestionI,
  onChange: (chosenAnswers: QuestionI["chosenAnswer"]) => void
) => {
  switch (question.type) {
    case QuestionType.MULTIPLE:
      return (
        <MultipleOptionsQuestion
          question={question as MultipleQuestionI}
          onChange={onChange}
        />
      );
    case QuestionType.NUMBER:
      return (
        <SliderQuestion
          question={question as NumberQuestionI}
          onChange={onChange}
          key={objectHash(question.question)}
        />
      );
    case QuestionType.PHOTO:
      return (
        <CameraQuestion
          onChange={onChange}
          question={question as PhotoQuestionI}
        />
      );
    case QuestionType.TEXT:
      return (
        <TextQuestion
          question={question as TextQuestionI}
          onChange={onChange}
        />
      );
    case QuestionType.COLOR_PICKER:
      return (
        <ColorPickerQuestion
          question={question as ColorPickerQuestionI}
          onChange={onChange}
          key={objectHash(question.question)}
        />
      );
    default:
      return <Text>Unknown question</Text>;
  }
};
export const mapQuizStateTouserParams = (
  quizstate: quizStateI
): UserParamsI => {
  const res: any = { isSetup: true };
  quizstate.questions.forEach((questions: QuestionI) => {
    res[questions.result] = questions.chosenAnswer;
  });
  delete res["photo"];
  return res;
};

export type QuizProps = {
  quiz: QuestionI[];
  isWelcomeScreen: boolean;
  onFinish: (userParams: Partial<UserParamsI>) => void;
  exitText: string;
  onExit: () => void;
};

const QuizScreen = () => {
  const quizstate = useSelector((state: RootState) => state.quiz);
  const dispatch = useDispatch();
  const route =
    useRoute<RouteProp<AuthenticatedStackNavigatorParamList, "QuizScreen">>();
  const { onExit, exitText, quiz, onFinish, isWelcomeScreen } = route.params;
  const navigation =
    useNavigation<NavigationProp<AuthenticatedStackNavigatorParamList>>();
  const { showMessage } = useAppStatus();
  const quizstateInitial: quizStateI = {
    currentQuestion: isWelcomeScreen ? -1 : 0,
    questions: quiz,
    isQuizTaken: false,
  };

  useEffect(() => {
    dispatch(init_test({ initState: quizstateInitial }));

    const backAction = () => {
      dispatch(previous_question());
      return true;
    };

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => backHandler.remove();
  }, []);

  let answer = quizstate.questions[quizstate.currentQuestion];

  const isLastQuestion = quizstate.currentQuestion >= quiz.length - 1;
  const isFirstQuestion = quizstate.currentQuestion == 0;
  const isIntro = quizstate.currentQuestion == -1;
  const onChange = (chosenAnswers: QuestionI["chosenAnswer"]) =>
    dispatch(choose_answer({ chosenAnswer: chosenAnswers }));
  const blackBtnText: string = isLastQuestion ? "Finish" : "Next";
  return (
    <View style={styles.container}>
      <View style={{ height: 20 }} />
      {isIntro ? (
        <IntroQuiz
          startTest={() => dispatch(next_question())}
          onExit={onExit}
          exitText={exitText}
        />
      ) : (
        <View
          style={{
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <View style={{ flex: 1 }}>
            <View style={{ marginBottom: 10, alignItems: "center" }}>
              <Text style={typography.p1}>
                {quizstate.currentQuestion + 1}/{quiz.length}
              </Text>
            </View>
            <View style={{ marginBottom: 20 }}>
              <Text style={[typography.h1, { textAlign: "center" }]}>
                {answer?.question}
              </Text>
            </View>
            {renderQuestion(answer, onChange)}
          </View>
        </View>
      )}

      <View>
        <View
          style={{
            marginBottom: 0,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {quizstate.currentQuestion > 0 && (
            <SecondaryButton
              style={{ flex: 1, marginRight: 10 }}
              text="Back"
              onPress={() => {
                dispatch(previous_question());
              }}
            />
          )}
          <PrimaryButton
            key={quizstate.currentQuestion}
            text={blackBtnText}
            style={{ flex: 1 }}
            onPress={() => {
              if (quizstate.currentQuestion == -1) {
                dispatch(next_question());
                return;
              }
              const isValid = answer.validation(answer.chosenAnswer);
              if (isValid.message) showMessage(isValid.message);
              else {
                if (answer.result == "photo") {
                  logQuiz(answer.result, "");
                } else {
                  logQuiz(answer.result, answer.chosenAnswer);
                }
                if (isLastQuestion) {
                  dispatch(finish_quiz());
                  onFinish(mapQuizStateTouserParams(quizstate));
                } else {
                  dispatch(next_question());
                }
              }
            }}
          />
        </View>

        <Text style={styles.dontHaveAccountText} onPress={() => onExit()}>
          {exitText}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    ...containers.screenContainer,
    paddingTop: 20,
    justifyContent: "space-between",
  },
  dontHaveAccountText: {
    ...typography.p1,
    alignSelf: "center",
    marginTop: 50,
    marginBottom: 20,
  },
});

export default QuizScreen;
