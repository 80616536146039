import { View, Text, Modal, Image, Pressable, Row } from "native-base";
import { SearchBar } from "../search-bar";
import { useState } from "react";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import NavigationParamList from "../../navigators/NavigationParamList";
import ProductList from "../../components/product-list";
import { colors } from "../../theme";
import { LookItemI } from "../../types/common/LookI";
import FiltersPage from "../../components/filters-page/FiltersPage";
import { useGlobalFiltersStore, useUserStore } from "../../hooks";
import FiltersComponent from "../../components/filters-page/FiltersComponent";
import { PrimaryButton } from "../../components/buttons";
import { useModal } from "../../hooks/useModal";
import { getImage } from "../../helpers/resourceProvider";

export const ChangeProductScreen = () => {
  const { showModal } = useModal();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const route = useRoute<RouteProp<NavigationParamList, "ChangeProduct">>();
  const [descriptionLocal, setDescriptionLocal] = useState<string>(
    route.params.lookItem?.description || ""
  );
  const { globalFilters, setGlobalFilters } = useGlobalFiltersStore();
  const { user } = useUserStore();
  const [lookItemLocal, setLookItemLocal] = useState<LookItemI>(
    route.params.lookItem
  );
  const [lookItem, setLookItem] = useState<LookItemI>(lookItemLocal);
  return (
    <View backgroundColor={colors.bg} flex={1}>
      <Modal isOpen={isModalOpen} closeOnOverlayClick={close}>
        <View padding={10} borderRadius={20} backgroundColor={colors.bg}>
          <FiltersComponent
            filtersUnit={{
              ...globalFilters,
              chosenValues: {
                ...globalFilters?.chosenValues,
                ...lookItemLocal?.filters,
              },
            }}
            setFiltersUnit={(filters) =>
              setLookItemLocal({ ...lookItem, filters })
            }
          />
          <PrimaryButton
            text={"Apply"}
            onPress={() => {
              setIsModalOpen(false);
            }}
          />
        </View>
      </Modal>

      <SearchBar
        value={descriptionLocal}
        onInputChange={setDescriptionLocal}
        onPress={() =>
          setLookItem({ ...lookItemLocal, description: descriptionLocal })
        }
      />
      <Row
        justifyContent={"flex-end"}
        py={2}
        alignItems={"center"}
        backgroundColor={"red"}
      >
        <Pressable onPress={() => setIsModalOpen(true)}>
          <Image
            source={getImage("filtersIcon")}
            style={{ width: 30, height: 30 }}
          />
        </Pressable>
      </Row>
      <ProductList
        lookItem={lookItem}
        direction={"vertical"}
        onProductPress={route.params.onChanged}
      />
    </View>
  );
};
