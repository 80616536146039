import { useEffect, useState } from "react";
import { FlatList, StyleSheet, Text, View } from "react-native";
import { useDispatch } from "react-redux";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";
import { useGlobalFiltersStore } from "../../hooks";
import useUserStore from "../../hooks/useUserStore";
import { api, useGetProductsQuery } from "../../store/api";
import { typography } from "../../theme";
import { FiltersI } from "../../types/common/FiltersI";
import ProductI from "../../types/common/ProductI";
import { ProductDescriptorI } from "../../types/common/productDescriptorI";
import ProductCard from "../product-card/ProductCard";
import { ProductCardV2 } from "../product-card/ProductCardV2";
import { LookItemI } from "../../types/common/LookI";

const numberOfItemsToLoad = 10;

export type Props = {
  lookItem: LookItemI;
  limit?: number;
  products?: ProductI[];
  direction?: "horizontal" | "vertical";
  filters?: Partial<FiltersI>;
  onProductPress?: (product: ProductI) => void;
};

const ProductList = ({
  lookItem,
  limit = numberOfItemsToLoad,
  products,
  filters,
  direction = "horizontal",
  onProductPress,
}: Props) => {
  const dispatch = useDispatch();
  const [isProductNeeded, setIsProductNeeded] = useState(products == undefined);
  const { user } = useUserStore();
  const [limitLocal, setLimitLocal] = useState(limit);
  const { globalFilters } = useGlobalFiltersStore();
  const [productsData, setProductsData] = useState<ProductI[] | undefined>(
    products
  );
  const {
    data: getPruductsData,
    isFetching,
    error,
    isLoading,
    refetch,
  } = useGetProductsQuery(
    {
      lookItem: {
        ...lookItem,
        filters: { ...globalFilters?.chosenValues, ...lookItem?.filters },
      },
      offset: 0,
      limit: limitLocal,
    },
    {
      skip:
        user == undefined ||
        globalFilters.isLoading ||
        globalFilters.chosenValues == undefined ||
        !isProductNeeded,
    }
  );
  const renderLoading = () => {
    const data = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
    const renderPlaceholder = () => {
      return (
        <View
          style={{
            width: "45%",
            height: 200,
            flexDirection: "column",
            marginHorizontal: 5,
            alignItems: "center",
          }}
        >
          <View style={styles.placeholderImage}>
            <Placeholder Animation={Fade}>
              <PlaceholderMedia style={styles.placeholderImage} />
            </Placeholder>
          </View>
          <Placeholder Animation={Fade}>
            <PlaceholderLine noMargin={true} style={styles.textPlaceholder} />
            <PlaceholderLine noMargin={true} style={styles.textPlaceholder} />
          </Placeholder>
        </View>
      );
    };
    return (
      <View>
        <FlatList
          style={styles.list}
          data={data}
          renderItem={renderPlaceholder}
          horizontal={direction == "horizontal"}
          numColumns={direction == "vertical" ? 2 : 1}
          contentContainerStyle={{
            justifyContent: "space-between",
            alignContent: "space-between",
            width: "100%",
          }}
        />
      </View>
    );
  };
  useEffect(() => {
    console.log(filters, globalFilters.chosenValues);
    // dispatch(
    //   api.util.invalidateTags([{ type: "Product", id: productDescriptor.id }])
    // );
    if (!getPruductsData) return;
    setProductsData(undefined);
    if (!isProductNeeded) setIsProductNeeded(true);
    // refetch();
    setLimitLocal(limit);
  }, [globalFilters.chosenValues, filters]);

  useEffect(() => {
    if (!getPruductsData) return;
    refetch();
  }, [lookItem]);

  useEffect(() => {
    setProductsData(products);
  }, [products]);

  useEffect(() => {
    if (getPruductsData) {
      setProductsData(getPruductsData.products);
    }
  }, [getPruductsData]);

  const handleLoadMoreResults = () => {
    console.log("handleLoadMoreResults");
    if (!isFetching) {
      setLimitLocal(limitLocal + limit);
      if (!isProductNeeded) setIsProductNeeded(true);
      // setProductsData(undefined);
    }
  };

  if (
    !productsData ||
    globalFilters.isLoading ||
    (isFetching && limitLocal == numberOfItemsToLoad)
  )
    return renderLoading();
  return (
    <View>
      {productsData.length > 0 ? (
        <View style={{ height: 800 }}>
          <FlatList
            style={styles.list}
            data={productsData}
            renderItem={({ item }) => (
              <ProductCardV2
                product={item}
                style={{ width: "45%", marginHorizontal: "3%" }}
                onPress={onProductPress}
              />
            )}
            keyExtractor={(item) => item.id.toString()}
            initialNumToRender={numberOfItemsToLoad}
            onEndReachedThreshold={1}
            onEndReached={handleLoadMoreResults}
            horizontal={direction == "horizontal"}
            numColumns={direction == "vertical" ? 2 : 1}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            contentContainerStyle={{
              justifyContent: "space-between",
              alignContent: "space-between",
              width: "100%",
              paddingBottom: 300,
            }}
          />
        </View>
      ) : (
        renderEmptyList()
      )}
    </View>
  );
};

export default ProductList;

const renderEmptyList = () => {
  return (
    <View
      style={{
        height: 200,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text style={typography.p1}>
        Oops! We could not find the items for you. Try adding more stores
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  list: {
    marginTop: 0,
  },
  priceSlider: {
    height: 50,
    backgroundColor: "red",
  },
  placeholder: {
    width: 170,
    height: 200,
    flexDirection: "column",
    marginHorizontal: 5,
    alignItems: "center",
  },
  placeholderImage: {
    width: "100%",
    height: 140,
    borderRadius: 5,
  },
  textPlaceholder: {
    marginTop: 5,
    height: 20,
  },
});
