import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import InputPassword from "../../components/input-password/InputPassword";
import ModalDialogue from "../../components/modal-dialogue/ModalDialogue";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import { useAuth } from "../../hooks";
import containers from "../../theme/containers";
import { typography } from "../../theme";
import { SettingsNavigatorParamList } from "../../navigators/SettingsStackNavigator";

const ChangePasswordScreen = () => {
  const navigation =
    useNavigation<NavigationProp<SettingsNavigatorParamList>>();
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const { resetPassword, changePassword } = useAuth();

  return (
    <View style={styles.container}>
      <ModalDialogue
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        onPress={resetPassword}
        title={"Reset password"}
        text="Are you sure you want to reset your password?"
      />
      <View style={{ marginBottom: 30 }}>
        <Text style={typography.h1}>Create a new password</Text>
      </View>
      <View style={{ marginBottom: 10 }}>
        <Text>Type your current password</Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <InputPassword password={oldPassword} setPassword={setOldPassword} />
      </View>
      <View style={{ marginBottom: 10 }}>
        <Text>Type your new password</Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <InputPassword password={password1} setPassword={setPassword1} />
      </View>
      <View style={{ marginBottom: 10 }}>
        <Text>Retype your new password</Text>
      </View>
      <View style={{ marginBottom: 40 }}>
        <InputPassword password={password2} setPassword={setPassword2} />
      </View>

      <View style={{ marginBottom: 100 }}>
        <Text onPress={() => setModalVisible(true)}>Forgot password?</Text>
      </View>

      <View style={{ marginBottom: 10 }}>
        <PrimaryButton
          text={"Save"}
          onPress={() => changePassword(password1, password2, oldPassword)}
        />
      </View>
      <View>
        <SecondaryButton text={"Go Back"} onPress={navigation.goBack} />
      </View>
    </View>
  );
};

export default ChangePasswordScreen;

const styles = StyleSheet.create({
  container: { ...containers.screenContainer },
});
