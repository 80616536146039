import React from "react";
import { StyleSheet, View } from "react-native";
import { env } from "../../../env";
import containers from "../../theme/containers";

const PrivacyPolicyScreen = () => {
  return (
    <View style={styles.container}>
      {/* <iframe
        src={env.PRIVACY_POLICY_URL}
        width="100%"
        height="100%"
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          backgroundColor: "white",
        }}
        // seamless={true}
      /> */}
    </View>
  );
};
const styles = StyleSheet.create({
  container: { ...containers.screenContainer },
});

export default PrivacyPolicyScreen;
