import { UserParamsI } from "../types/common/UserParamsI";
import {
  MultipleQuestionI,
  MultipleQuestionOptionI,
  QuestionType,
  ValidationResponseI,
} from "../types/Quiz";

export class MultipleQuestion implements MultipleQuestionI {
  type = QuestionType.MULTIPLE;
  constructor(
    public numberOfOptions: number,
    public option: MultipleQuestionOptionI[],
    public result: keyof UserParamsI,
    public question: string,
    public chosenAnswer: UserParamsI[keyof UserParamsI] | undefined,
    public validation: (
      answer: UserParamsI[keyof UserParamsI][] | UserParamsI[keyof UserParamsI]
    ) => ValidationResponseI
  ) {}
}
