import { View } from "react-native";

import { MultipleQuestion } from "../entities/MultipleQuestion";
import { MultipleQuestionOption } from "../entities/MultipleQuestionOption";
import { NumberQuestion } from "../entities/NumberQuestion";
import { PhotoQuestion } from "../entities/PhotoQuestion";
import { ValidationResponseI, QuestionI } from "../types/Quiz";
import { ColorSeason } from "../types/common/ColorSeason";
import { Gender } from "../types/common/Gender";
import { Hairlength } from "../types/common/Hairlength";
import { Hairtype } from "../types/common/Hairtype";
import { Skintype } from "../types/common/Skintype";
import { Specials } from "../types/common/Specials";
import { TextQuestion } from "../entities/TextQuestion";
import { ColorPickerQuestion } from "../entities/ColorPickerQuestion";

const validateNonEmpty = (answer: any): ValidationResponseI => {
  const isValid = answer != undefined && answer.length != "";
  return {
    isValid,
    message: isValid ? "" : "Please enter a value",
  };
};

const validateAny = (answer: any): ValidationResponseI => {
  return {
    isValid: true,
  };
};

const getQuestionByName = (questionName: QuestionI["result"]) => {
  if (question[questionName] == undefined) {
    throw `question doesnt exist ${questionName}`;
  }
  return question[questionName];
};

const renderColorView = (color: string) => {
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: color,
        borderRadius: 10,
      }}
    />
  );
};

const question: { [key: string]: QuestionI } = {
  gender: new MultipleQuestion(
    1,
    [
      { caption: "Male", value: Gender.MALE, image: "quiz_male" },
      {
        caption: "Female",
        value: Gender.FEMALE,
        image: "quiz_female",
      },
      {
        caption: "Non-binary",
        value: Gender.NON_BINARY,
        image: "quiz_nonBinary",
      },
    ],
    "gender",
    "Choose your gender",
    undefined,
    validateNonEmpty
  ),

  age: new NumberQuestion(
    ["years"],
    18,
    100,
    "age",
    "Choose your age",
    25,
    validateAny
  ),

  height: new NumberQuestion(
    ["cm", "ft"],
    90,
    250,
    "height",
    "Enter your height",
    165,
    validateAny
  ),
  weight: new NumberQuestion(
    ["kg", "lbs"],
    30,
    200,
    "weight",
    "Enter your weight",
    50,
    validateAny
  ),
  colorseason: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption(
        "Bright Winter",
        ColorSeason.WINTER_BRIGHT,
        ""
      ),
      new MultipleQuestionOption("True Winter", ColorSeason.WINTER_TRUE, ""),
      new MultipleQuestionOption("Dark Winter", ColorSeason.WINTER_DARK, ""),
      new MultipleQuestionOption(
        "Bright Spring",
        ColorSeason.SPRING_BRIGHT,
        ""
      ),
      new MultipleQuestionOption("True Spring", ColorSeason.SPRING_TRUE, ""),
      new MultipleQuestionOption("Light Spring", ColorSeason.SPRING_LIGHT, ""),
      new MultipleQuestionOption("Light Summer", ColorSeason.SUMMER_LIGHT, ""),
      new MultipleQuestionOption("True Summer", ColorSeason.SUMMER_TRUE, ""),
      new MultipleQuestionOption("Soft Summer", ColorSeason.SUMMER_SOFT, ""),
      new MultipleQuestionOption("Soft Autumn", ColorSeason.AUTUMN_SOFT, ""),
      new MultipleQuestionOption("True Autumn", ColorSeason.AUTUMN_TRUE, ""),
      new MultipleQuestionOption(
        "Dark Autumn",
        ColorSeason.AUTUMN_DARK,
        "quiz/dark-autumn.svg"
      ),
    ],
    "colorseason",
    "Choose your color season",
    "SPRING_BRIGHT",
    validateAny
  ),
  photo: new PhotoQuestion("Take a photo", validateNonEmpty),
  bodytype: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption("Apple", "APPLE", `quiz_apple`),
      new MultipleQuestionOption("Pear", "PEAR", `quiz_pear`),
      new MultipleQuestionOption(
        "Inverted Triangle",
        "INVERTED_TRIANGLE",
        `quiz_invertedTriangle`
      ),
      new MultipleQuestionOption("Rectangle", "RECTANGLE", `quiz_rectangle`),
      new MultipleQuestionOption("Hourglass", "HOURGLASS", `quiz_hourglass`),
    ],
    "bodytype",
    "Choose your bodytype",
    "APPLE",
    validateAny
  ),

  faceshape: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption("Round", "ROUND", "quiz_faceshapeRound"),
      new MultipleQuestionOption(
        "Triangle",
        "TRIANGLE",
        "quiz_faceshapeTriangle"
      ),
      new MultipleQuestionOption("Oval", "OVAL", "quiz_faceshapeOval"),
      new MultipleQuestionOption("Oblong", "OBLONG", "quiz_faceshapeOblong"),
      new MultipleQuestionOption("Square", "SQUARE", "quiz_faceshapeSquare"),
      // new MultipleQuestionOption(
      //   "Heart",
      //   Faceshape.HEART,
      //   "quiz/faceshape-heart"
      // ),
      new MultipleQuestionOption("Diamond", "DIAMOND", "quiz_faceshapeDiamond"),
    ],
    "faceshape",
    "Choose your faceshape",
    "ROUND",
    validateAny
  ),

  hairtype: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption("Wavy", Hairtype.WAVY, "quiz_hairtypeWavy"),
      new MultipleQuestionOption("Curly", Hairtype.CURLY, "quiz_hairtypeCurly"),
      new MultipleQuestionOption(
        "Straight",
        Hairtype.STRAIGHT,
        "quiz_hairtypeStraight"
      ),
    ],
    "hairtype",
    "Choose your hairtype",
    Hairtype.STRAIGHT,
    validateAny
  ),

  hairlength: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption("Long", Hairlength.LONG, "quiz_longhair"),
      new MultipleQuestionOption(
        "Middle",
        Hairlength.MIDDLE,
        "quiz_middlehair"
      ),
      new MultipleQuestionOption("Short", Hairlength.SHORT, "quiz_shorthair"),
    ],
    "hairlength",
    "Choose your hairlength",
    Hairlength.MIDDLE,
    validateAny
  ),

  skintype: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption(
        "Long",
        Skintype.TYPE_1,
        "",
        "always freckles and burns, never tans"
      ),
      new MultipleQuestionOption(
        "Middle",
        Skintype.TYPE_2,
        "quiz_bodytype_pear.jpg",
        "burns and peels often, tans minimally"
      ),
      new MultipleQuestionOption(
        "Short",
        Skintype.TYPE_3,
        "quiz_bodytype_pear.jpg",
        "occasionally burns, sometimes tans"
      ),
      new MultipleQuestionOption(
        "LONG",
        Skintype.TYPE_4,
        "quiz_bodytype_apple.jpg",
        "rarely burns, tans easily"
      ),
      new MultipleQuestionOption(
        "MIDDLE",
        Skintype.TYPE_5,
        "quiz_bodytype_pear.jpg",
        "rarely burns, tans easily and darkly"
      ),
      new MultipleQuestionOption(
        "dark brown or black",
        Skintype.TYPE_6,
        "quiz_bodytype_pear.jpg",
        "rarely burns, always tans"
      ),
    ],
    "skintype",
    "Choose your skintype",
    Skintype.TYPE_3,
    validateAny
  ),

  specials: new MultipleQuestion(
    100,
    [
      new MultipleQuestionOption(
        "Maternity",
        Specials.MATERNITY,
        "quiz_maternity",
        ""
      ),
      new MultipleQuestionOption(
        "Curve ot plus-size",
        Specials.PLUS,
        "quiz_plussize",
        ""
      ),
      new MultipleQuestionOption("Petite", Specials.PETIT, "quiz_petite", ""),
      new MultipleQuestionOption("Tall", Specials.TALL, "quiz_tall", ""),
    ],
    "specials",
    "Are any of these applicable to you?",
    [],
    validateAny
  ),
  name: new TextQuestion("name", "What's your name", "", validateNonEmpty),
  tanTest: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption("I burn quickly.", "cool", ""),
      new MultipleQuestionOption(
        "Starting with a tan, but then burn",
        "neutral",
        ""
      ),
      new MultipleQuestionOption("I tan easily.", "warm", ""),
    ],
    "tanTest",
    "How does exposure to the sun without sunscreen affect your skin?",
    undefined,
    validateNonEmpty
  ),
  veins: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption("Rather blue", "cool", ""),
      new MultipleQuestionOption(
        "Hard to tell, mix of blue and green",
        "neutral",
        ""
      ),
      new MultipleQuestionOption("Rather warm.", "warm", ""),
    ],
    "veins",
    "Take a look inside your wrist. What color do your veins mostly look like?",
    undefined,
    validateNonEmpty
  ),
  jewelry: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption("Rather silver", "cool", ""),
      new MultipleQuestionOption("Equally appealing", "neutral", ""),
      new MultipleQuestionOption("Rather gold", "warm", ""),
    ],
    "jewelry",
    "What kind of metal jewelry do you like wearing?",
    undefined,
    validateNonEmpty
  ),
  eyesFlecks: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption("Blue or gray", "cool", ""),
      new MultipleQuestionOption("A bit of both", "neutral", ""),
      new MultipleQuestionOption("Golden or hazel", "warm", ""),
    ],
    "eyesFlecks",
    "What shade are the flecks in your eyes?",
    undefined,
    validateNonEmpty
  ),
  paperTest: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption("Terrible!", "cool", ""),
      new MultipleQuestionOption("No difference", "neutral", ""),
      new MultipleQuestionOption("Pretty good", "warm", ""),
    ],
    "paperTest",
    "White paper should be placed near to your exposed face. What does your skin tone look like?",
    undefined,
    validateNonEmpty
  ),
  eyecolor: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption(
        "",
        [["#8B4513", 1]],
        "",
        undefined,
        renderColorView("#8B4513")
      ),
      new MultipleQuestionOption(
        "",
        [["#0000FF", 1]],
        "",
        undefined,
        renderColorView("#0000FF")
      ),
      new MultipleQuestionOption(
        "",
        [["#008000", 1]],
        "",

        undefined,
        renderColorView("#008000")
      ),
      new MultipleQuestionOption(
        "",
        [["#8E735B", 1]],
        "",
        undefined,
        renderColorView("#8E735B")
      ),
      new MultipleQuestionOption(
        "",
        [["#808080", 1]],
        "",
        undefined,
        renderColorView("#808080")
      ),
      new MultipleQuestionOption(
        "",
        [["#FFBF00", 1]],
        "",
        undefined,
        renderColorView("#FFBF00")
      ),
      new MultipleQuestionOption(
        "",
        [["#9400D3", 1]],
        "",
        undefined,
        renderColorView("#8B0000")
      ),
      new MultipleQuestionOption(
        "",
        [["#000000", 1]],
        "",
        undefined,
        renderColorView("#000000")
      ),
      new MultipleQuestionOption(
        "",
        [["#DABDAB", 1]],
        "",
        undefined,
        renderColorView("#DABDAB")
      ),
      new MultipleQuestionOption(
        "",
        [["#40E0D0", 1]],
        "",
        undefined,
        renderColorView("#40E0D0")
      ),
      new MultipleQuestionOption(
        "",
        [["#00008B", 1]],
        "",
        undefined,
        renderColorView("#00008B")
      ),
      new MultipleQuestionOption(
        "",
        [["#006400", 1]],
        "",
        undefined,
        renderColorView("#006400")
      ),
    ],
    "eyecolor",
    "What is your eye color?",
    undefined,
    validateNonEmpty
  ),

  haircolor: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption(
        "",
        [["#000000", 1]],
        "",
        undefined,
        renderColorView("#000000")
      ),
      new MultipleQuestionOption(
        "",
        [["#2C1B18", 1]],
        "",
        undefined,
        renderColorView("#2C1B18")
      ),
      new MultipleQuestionOption(
        "",
        [["#6A4E42", 1]],
        "",
        undefined,
        renderColorView("#6A4E42")
      ),
      new MultipleQuestionOption(
        "",
        [["#A67C52", 1]],
        "",
        undefined,
        renderColorView("#A67C52")
      ),
      new MultipleQuestionOption(
        "",
        [["#FAF0BE", 1]],
        "",
        undefined,
        renderColorView("#FAF0BE")
      ),
      new MultipleQuestionOption(
        "",
        [["#D2B48C", 1]],
        "",
        undefined,
        renderColorView("#D2B48C")
      ),
      new MultipleQuestionOption(
        "",
        [["#F4A460", 1]],
        "",
        undefined,

        renderColorView("#F4A460")
      ),
      new MultipleQuestionOption(
        "",
        [["#A52A2A", 1]],
        "",
        undefined,
        renderColorView("#A52A2A")
      ),
      new MultipleQuestionOption(
        "",
        [["#712F26", 1]],
        "",
        undefined,
        renderColorView("#712F26")
      ),
      new MultipleQuestionOption(
        "",
        [["#C0C0C0", 1]],
        "",
        undefined,
        renderColorView("#C0C0C0")
      ),
      new MultipleQuestionOption(
        "",
        [["#FFFFFF", 1]],
        "",
        undefined,
        renderColorView("#FFFFFF")
      ),
      new MultipleQuestionOption(
        "",
        [["#FFF0DB", 1]],
        "",
        undefined,
        renderColorView("#FFF0DB")
      ),
    ],
    "haircolor",
    "What is your hair color?",
    undefined,
    validateNonEmpty
  ),

  skincolor: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption(
        "",
        [["#FFEBDC", 1]],
        "",
        undefined,
        renderColorView("#FFEBDC")
      ),
      new MultipleQuestionOption(
        "",
        [["#FCD9C6", 1]],
        "",
        undefined,
        renderColorView("#FCD9C6")
      ),
      new MultipleQuestionOption(
        "",
        [["#E4B08A", 1]],
        "",
        undefined,
        renderColorView("#E4B08A")
      ),
      new MultipleQuestionOption(
        "",
        [["#CE967D", 1]],
        "",
        undefined,
        renderColorView("#CE967D")
      ),
      new MultipleQuestionOption(
        "",
        [["#BA6C49", 1]],
        "",
        undefined,
        renderColorView("#BA6C49")
      ),
      new MultipleQuestionOption(
        "",
        [["#8E4D38", 1]],
        "",
        undefined,
        renderColorView("#8E4D38")
      ),
      new MultipleQuestionOption(
        "",
        [["#70482C", 1]],
        "",
        undefined,
        renderColorView("#70482C")
      ),
      new MultipleQuestionOption(
        "",
        [["#5A341E", 1]],
        "",
        undefined,
        renderColorView("#5A341E")
      ),
      new MultipleQuestionOption(
        "",
        [["#482112", 1]],
        "",
        undefined,
        renderColorView("#482112")
      ),
      new MultipleQuestionOption(
        "",
        [["#30150C", 1]],
        "",
        undefined,
        renderColorView("#30150C")
      ),
    ],
    "skincolor",
    "What's your skin color?",
    undefined,
    validateNonEmpty
  ),
  skincolorPicker: new ColorPickerQuestion(
    "skincolor",
    "Enter your skincolor",
    [["#222222", 1]],
    validateNonEmpty,
    [
      "#8D5524", // Dark Brown
      "#C68642", // Medium Brown
      "#E0AC69", // Light Brown
      "#F1C27D", // Light Medium Brown
      "#FFDBAC", // Very Light Brown
      "#FFE0BD", // Pale
      "#FFCD94", // Light Pale
      "#E9CAB3", // Light Beige
      "#D1BAA3", // Beige
      "#C2996C", // Olive
      "#AD896A", // Tan
      "#A77543", // Light Tan
      "#6A4630", // Deep Brown
      "#5C3836", // Very Dark Brown
    ]
  ),
  eyecolorPicker: new ColorPickerQuestion(
    "eyecolor",
    "Choose your eyecolor",
    [["#222222", 1]],
    validateAny,
    [
      "#000000", // Black
      "#3B3024", // Very Dark Brown / Almost Black
      "#7D4627", // Dark Brown
      "#5B4534", // Medium Brown
      "#A7856A", // Light Brown
      "#A7856A", // Light Brown
      "#9DC209", // Light Green
      "#9DC209", // Light Green
      "#689D71", // Medium Green
      "#2B6142", // Dark
      "#2B6142", // Dark Green
      "#ACB6E5", // Ice Blue
      "#ACB6E5", // Ice Blue
      "#304FFE", // Blue
      "#4B4F89", // Gray-Blue
      "#788085", // Gray
      "#FFE9D4", // Amber
      "#EDE285", // Hazel
    ]
  ),
  haircolorPicker: new ColorPickerQuestion(
    "haircolor",
    "Choose your natural haircolor",
    [["#222222", 1]],
    validateAny,
    [
      "#090806", // Black
      "#2C222B", // Off Black
      "#71635A", // Darkest Browna
      "#7E6D55", // Medium Dark Brown
      "#B89778", // Light Brown
      "#DABAAF", // Very Light Brown
      "#E6CEA8", // Dark Blonde
      "#F1E6B2", // Golden Blonde
      "#FFF5E1", // Lightest Blonde
      "#8D4A00", // Dark Auburn
      "#B55239", // Medium Auburn
      "#D56C46", // Light Auburn
      "#E18B6B", // Strawberry Blonde
      "#C0450C", // Deep Red
      "#E15501", // Orange Red
      "#F7A71B", // Copper
      "#161218", // Dark Gray
      "#5E5E5E", // Medium Gray
      "#B0B0B0", // Light Gray
      "#E6E6E6", // Silver
      "#A30301", // Burgundy
      "#2A1A1C", // Dark Violet
      "#6B2D5C", // Medium Violet
      "#8C3A62", // Light Violet
      "#317589", // Dark Blue
      "#4B9CD3", // Medium Blue
      "#73C5E1", // Light Blue
      "#699D4C", // Dark Green
      "#9ACD32", // Medium Green
      "#B7E04A", // Light Green
    ]
  ),
  boneStructure: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption(
        "Soft, rounded, full cheeks, rounded jaw.",
        "Soft, rounded, full cheeks, rounded jaw.",
        ""
      ),
      new MultipleQuestionOption(
        "Angular, sharp, prominent cheekbones, chiseled jaw.",
        "Angular, sharp, prominent cheekbones, chiseled jaw.",
        ""
      ),
      new MultipleQuestionOption(
        "Mixed features, balanced cheekbones, tapered jaw.",
        "Mixed features, balanced cheekbones, tapered jaw.",
        ""
      ),
      new MultipleQuestionOption(
        "Delicate, small, gently curved, narrow jaw.",
        "Delicate, small, gently curved, narrow jaw.",
        ""
      ),
      new MultipleQuestionOption(
        "Long, narrow, elongated features, straight jaw.",
        "Long, narrow, elongated features, straight jaw.",
        ""
      ),
    ],
    "boneStructure",
    "How would you describe the overall shape of your facial features and bone structure?",
    undefined,
    validateNonEmpty
  ),
  textureAndBodyShape: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption(
        "Soft, voluptuous, curvy, hourglass figure.",
        "Soft, voluptuous, curvy, hourglass figure.",
        ""
      ),
      new MultipleQuestionOption(
        "Muscular, athletic, defined, rectangular silhouette.",
        "Muscular, athletic, defined, rectangular silhouette.",
        ""
      ),
      new MultipleQuestionOption(
        "Balanced softness, angularity, tapered waist.",
        "Balanced softness, angularity, tapered waist.",
        ""
      ),
      new MultipleQuestionOption(
        "Petite, delicate, small frame, subtle curves.",
        "Petite, delicate, small frame, subtle curves.",
        ""
      ),
      new MultipleQuestionOption(
        "Long, lean, slender, elongated, minimal curves.",
        "Long, lean, slender, elongated, minimal curves.",
        ""
      ),
    ],
    "textureAndBodyShape",
    "Which best describes the texture and shape of your body?",
    undefined,
    validateNonEmpty
  ),
  hairTextureAndVolume: new MultipleQuestion(
    1,
    [
      new MultipleQuestionOption(
        "Thick, wavy/curly, voluminous, full.",
        "Thick, wavy/curly, voluminous, full.",
        ""
      ),
      new MultipleQuestionOption(
        "Straight, coarse, dense, heavy.",
        "Straight, coarse, dense, heavy.",
        ""
      ),
      new MultipleQuestionOption(
        "Mixed, wavy/straight, medium thickness.",
        "Mixed, wavy/straight, medium thickness.",
        ""
      ),
      new MultipleQuestionOption(
        "Fine, silky, soft, smooth, gentle.",
        "Fine, silky, soft, smooth, gentle.",
        ""
      ),
      new MultipleQuestionOption(
        "Straight, fine, sleek, close-to-head.",
        "Straight, fine, sleek, close-to-head.",
        ""
      ),
    ],
    "hairTextureAndVolume",
    "Which best describes the texture and volume of your hair?",
    undefined,
    validateNonEmpty
  ),
};

const quiz: QuestionI[] = [
  question["gender"],
  question["age"],
  //question["haircolor"],
  // question["height"],
  // question["weight"],
  // question["bodytype"],
  // question["faceshape"],
  // question["hairtype"],
  // question["hairlength"],
  // question["boneStructure"],
  // question["textureAndBodyShape"],
  // question["hairTextureAndVolume"],
  question["tanTest"],
  // question["veins"],
  // question["jewelry"],
  // question["eyesFlecks"],
  // question["paperTest"],
  question["skincolor"],
  question["haircolor"],
  question["eyecolor"],

  // question["eyecolorPicker"],
  // question["photo"],

  // question["eyecolor"],
  // question["haircolor"],
  // question["skincolor"],
  // question["specials"],

  question["name"],
];

export { getQuestionByName, quiz };
