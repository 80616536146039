import ProductI from "../types/common/ProductI";

export const isProductFromWardrobe = (
  item: ProductI,
  wardrobeItems: ProductI[]
): boolean => {
  return wardrobeItems.some(
    (wardrobeItem: ProductI) => wardrobeItem.id === item.id
  );
};
