//@ts-ignore
import chroma from "chroma-js";
import nearestColor from "nearest-color";
import tinycolor from "tinycolor2";

// nearestColor need objects {name => hex} as input

const colors = {
  black: "#000000",
  blue: "#0000ff",
  brown: "#a52a2a",
  gray: "#808080",
  green: "#008000",
  metallic: "#404040",
  beige: "#f5f5dc",
  orange: "#ffa500",
  pink: "#ffc0cb",
  purple: "#800080",
  red: "#ff0000",
  white: "#ffffff",
  yellow: "#ffff00",
};

// colorNameList.reduce((o, { name, hex }) => {
//   return { ...o, [name]: hex };
//   // return Object.assign(o, { [name]: hex });
// }, {});
const getNameOfNearestColor = nearestColor.from(colors);

type ColorCombos = {
  monochromaticColors: string[];
  complementaryColor: string[];
  analogousColors: string[];
  triadColors: string[];
  tetradColors: string[];
};

const getColorCombinations = (color: string): ColorCombos => {
  const numOfMonochromaticColors = 6;
  const result: ColorCombos = {
    complementaryColor: [tinycolor(color).complement()].reduce(
      (acc, c) => [
        ...tinycolor(c.toHexString())
          .monochromatic(numOfMonochromaticColors)
          .map((c) => c.toHexString()),
        ...acc,
      ],
      [] as string[]
    ),
    monochromaticColors: tinycolor(color)
      .monochromatic(numOfMonochromaticColors)
      .map((c) => c.toHexString()),
    analogousColors: tinycolor(color)
      .analogous()
      .reduce(
        (acc, c) => [
          ...tinycolor(c.toHexString())
            .monochromatic(numOfMonochromaticColors)
            .map((c) => c.toHexString()),
          ...acc,
        ],
        [] as string[]
      ),
    triadColors: tinycolor(color)
      .triad()
      .reduce(
        (acc, c) => [
          ...tinycolor(c.toHexString())
            .monochromatic(numOfMonochromaticColors)
            .map((c) => c.toHexString()),
          ...acc,
        ],
        [] as string[]
      ),
    tetradColors: tinycolor(color)
      .tetrad()
      .reduce(
        (acc, c) => [
          ...tinycolor(c.toHexString())
            .monochromatic()
            .map((c) => c.toHexString()),
          ...acc,
        ],
        [] as string[]
      ),
  };
  return result;
};

const getRecommendedColors = (
  colors: string[],
  acceptableColorDelta: number
): string[] => {
  let result: string[] = [];
  if (colors.length == 1) {
    const colorCombos = getColorCombinations(colors[0]);
    result = [
      ...result,
      ...colorCombos.complementaryColor,
      ...colorCombos.monochromaticColors,
      ...colorCombos.analogousColors,
      ...colorCombos.triadColors,
    ];
  } else if (colors.length == 2) {
    const colorCombos = getColorCombinations(colors[0]);
    //result = [];
    if (
      colorCombos.complementaryColor.find(
        (clr) => chroma.deltaE(clr, colors[0]) < acceptableColorDelta
      )
    )
      result = [...result, ...colorCombos.complementaryColor];
    if (
      colorCombos.analogousColors.find(
        (clr) => chroma.deltaE(clr, colors[0]) < acceptableColorDelta
      )
    )
      result = [...result, ...colorCombos.analogousColors];
    if (
      colorCombos.monochromaticColors.find(
        (clr) => chroma.deltaE(clr, colors[0]) < acceptableColorDelta
      )
    )
      result = [...result, ...colorCombos.monochromaticColors];
    if (
      colorCombos.triadColors.find(
        (clr) => chroma.deltaE(clr, colors[0]) < acceptableColorDelta
      )
    )
      result = [...result, ...colorCombos.triadColors];
  } else {
    result = [...result, ...colors];
  }

  return result;
};

export { getColorCombinations, getNameOfNearestColor, getRecommendedColors };
