import { Image, StyleSheet, Text, View } from "react-native";
import React from "react";
import { useUserStore } from "../../hooks";
import { getImage } from "../../helpers/resourceProvider";
import TabLink from "./TabLink";
import { styles } from "./styles";
import ProductList from "../../components/product-list/ProductList";
import { ProductDescriptor } from "../../entities/common/ProductDescriptor";
import { typography } from "../../theme";

const JeansGuide = () => {
  const { user } = useUserStore();
  if (!user) return [];
  const recommendations = user.recommendations;
  const { bodytype } = recommendations;

  const slides = [
    {
      slide: (
        <View>
          <Image
            source={{
              uri: "https://i.gyazo.com/315c2b4ade1afb4852c2815a60861971.png",
            }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>Jeans Guide</Text>
          <Text style={styles.description}>
            Uncover the jeans styles that best complement your body type with
            the insights from our AI-powered stylist!
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink
          img={{
            uri: "https://i.gyazo.com/315c2b4ade1afb4852c2815a60861971.png",
          }}
          text="Welcome"
        />
      ),
    },
    {
      slide: (
        <View>
          {/* <Image
            source={{ uri: "" }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          /> */}
          <Text style={styles.title}>Best Jeans</Text>
          <Text style={styles.description}>
            {bodytype.explanationBestJeans}
          </Text>
          <View style={{ height: 20 }} />
          <Text style={typography.h3}>Examples of Best Jeans</Text>
          <View style={{ height: 10 }} />
          <View style={styles.description}>
            {bodytype.bestJeans.map((jean) => (
              <View>
                <Text key={jean} style={typography.p1}>
                  - {jean}
                </Text>
                {/* <ProductList
                  productDescriptor={ProductDescriptor.createByDescriptionGenderColorCategory(
                    jean,
                    user.params.gender,
                    "",
                    "pants"
                  )}
                /> */}
              </View>
            ))}
          </View>
        </View>
      ),
      tabLink: <TabLink img={getImage("bestJeans")} text="Best Jeans" />,
    },
    // {
    //   slide: (
    //     <View>
    //       <Image
    //         source={{ uri: "" }}
    //         style={[styles.mainIllustration]}
    //         resizeMode="cover"
    //       />
    //       <Text style={styles.title}>Examples of Best Jeans</Text>

    //       <View style={styles.description}>
    //         {bodytype.bestJeans.map((jean) => (
    //           <View>
    //             <Text key={jean}>{jean}</Text>
    //             <ProductList
    //               productDescriptor={ProductDescriptor.createByDescriptionGenderColorCategory(
    //                 jean,
    //                 user.params.gender,
    //                 "",
    //                 "pants"
    //               )}
    //             />
    //           </View>
    //         ))}
    //       </View>
    //     </View>
    //   ),
    //   tabLink: (
    //     <TabLink
    //       img={getImage("examplesBestJeans")}
    //       text="Best Jeans Examples"
    //     />
    //   ),
    // },
    {
      slide: (
        <View>
          {/* <Image
            source={{ uri: "" }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          /> */}
          <Text style={styles.title}>Worst Jeans</Text>
          <Text style={styles.description}>
            {bodytype.explanationWorstJeans}
          </Text>
          <View style={{ height: 20 }} />
          <Text style={typography.h3}>Examples of Worst Jeans</Text>
          <View style={{ height: 10 }} />
          <View style={styles.description}>
            {bodytype.worstJeans.map((jean) => (
              <View>
                <Text key={jean} style={typography.p1}>
                  - {jean}
                </Text>
                {/* <ProductList
                  productDescriptor={ProductDescriptor.createByDescriptionGenderColorCategory(
                    jean,
                    user.params.gender,
                    "",
                    "pants"
                  )}
                /> */}
              </View>
            ))}
          </View>
        </View>
      ),
      tabLink: <TabLink img={getImage("worstJeans")} text="Worst Jeans" />,
    },
    // {
    //   slide: (
    //     <View>
    //       <Image
    //         source={{ uri: "" }}
    //         style={[styles.mainIllustration]}
    //         resizeMode="cover"
    //       />
    //       <Text style={styles.title}>Examples of Worst Jeans</Text>
    //       <View style={styles.description}>
    //         {bodytype.worstJeans.map((jean) => (
    //           <View>
    //             <Text key={jean}>{jean}</Text>
    //             <ProductList
    //               productDescriptor={ProductDescriptor.createByDescriptionGenderColorCategory(
    //                 jean,
    //                 user.params.gender,
    //                 "",
    //                 "pants"
    //               )}
    //             />
    //           </View>
    //         ))}
    //       </View>
    //     </View>
    //   ),
    //   tabLink: (
    //     <TabLink
    //       img={getImage("examplesWorstJeans")}
    //       text="Worst Jeans Examples"
    //     />
    //   ),
    // },
    {
      slide: (
        <View>
          <Image
            source={getImage("thanks")}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>Thank You!</Text>
          <Text style={styles.description}>
            Thanks for exploring Best and Worst Jeans! Wear the jeans that
            flatter your shape the most.
          </Text>
        </View>
      ),
      tabLink: <TabLink img={getImage("thanks")} text="Thanks" />,
    },
  ];

  return slides;
};

export default JeansGuide;
