import { Image, StyleSheet, Text, View } from "react-native";
import React from "react";
import { useUserStore } from "../../hooks";
import { getImage } from "../../helpers/resourceProvider";
import TabLink from "./TabLink";
import { styles } from "./styles";
import ProductList from "../../components/product-list";
import { ProductDescriptor } from "../../entities/common/ProductDescriptor";
import { typography } from "../../theme";

const GlassesGuide = () => {
  const { user } = useUserStore();
  if (!user) return [];
  const recommendations = user.recommendations;

  const { faceshape } = recommendations;

  const slides = [
    {
      slide: (
        <View>
          <Image
            source={{
              uri: "https://i.gyazo.com/2b4d6419e72c2c91ce7126ee8dd187fc.png",
            }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>Glasses Guide</Text>
          <Text style={styles.description}>
            Discover the glasses styles that enhance your face's features. Let
            our AI-powered stylist guide you!
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink
          img={{
            uri: "https://i.gyazo.com/2b4d6419e72c2c91ce7126ee8dd187fc.png",
          }}
          text="Welcome"
        />
      ),
    },
    {
      slide: (
        <View>
          {/* <Image
            source={{ uri: "" }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          /> */}
          <Text style={styles.title}>Best Glasses</Text>
          <Text style={styles.description}>
            {faceshape.explanationBestGlasses}
          </Text>
          <View style={{ height: 20 }} />
          <Text style={typography.h3}>Examples of Best Glasses</Text>
          <View style={{ height: 10 }} />
          <View style={styles.description}>
            {faceshape.bestGlasses.map((jean) => (
              <View>
                <Text key={jean} style={typography.p1}>
                  - {jean}
                </Text>
                {/* <ProductList
                  productDescriptor={ProductDescriptor.createByDescriptionGenderColorCategory(
                    jean,
                    user.params.gender,
                    "",
                    "glasses"
                  )}
                /> */}
              </View>
            ))}
          </View>
        </View>
      ),
      tabLink: <TabLink img={getImage("bestGlasses")} text="Best Glasses" />,
    },
    // {
    //   slide: (
    //     <View>
    //       <Image
    //         source={{ uri: "" }}
    //         style={[styles.mainIllustration]}
    //         resizeMode="cover"
    //       />
    //       <Text style={styles.title}>Examples of Best Glasses</Text>
    //       <View style={styles.description}>
    //         {faceshape.bestGlasses.map((jean) => (
    //           <View>
    //             <Text key={jean}>{jean}</Text>
    //             <ProductList
    //               productDescriptor={ProductDescriptor.createByDescriptionGenderColorCategory(
    //                 jean,
    //                 user.params.gender,
    //                 "",
    //                 "glasses"
    //               )}
    //             />
    //           </View>
    //         ))}
    //       </View>
    //     </View>
    //   ),
    //   tabLink: (
    //     <TabLink
    //       img={getImage("examplesBestGlasses")}
    //       text="Best Glasses Examples"
    //     />
    //   ),
    // },
    {
      slide: (
        <View>
          {/* <Image
            source={{ uri: "" }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          /> */}
          <Text style={styles.title}>Worst Glasses</Text>
          <Text style={styles.description}>
            {faceshape.explanationWorstGlasses}
          </Text>
          <View style={{ height: 20 }} />
          <Text style={typography.h3}>Examples of Worst Glasses</Text>
          <View style={{ height: 10 }} />
          <Text style={styles.description}>
            {faceshape.worstGlasses.map((glass) => (
              <Text key={glass}>
                - {glass} {"\n"}
              </Text>
            ))}
          </Text>
        </View>
      ),
      tabLink: <TabLink img={getImage("worstGlasses")} text="Worst Glasses" />,
    },
    // {
    //   slide: (
    //     <View>
    //       <Image
    //         source={{ uri: "" }}
    //         style={[styles.mainIllustration]}
    //         resizeMode="cover"
    //       />
    //       <Text style={styles.title}>Examples of Worst Glasses</Text>
    //       <Text style={styles.description}>
    //         {faceshape.worstGlasses.map((glass) => (
    //           <Text key={glass}>
    //             {glass} {"\n"}
    //           </Text>
    //         ))}
    //       </Text>
    //     </View>
    //   ),
    //   tabLink: (
    //     <TabLink
    //       img={getImage("examplesWorstGlasses")}
    //       text="Worst Glasses Examples"
    //     />
    //   ),
    // },
    {
      slide: (
        <View>
          <Image
            source={getImage("thanks")}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>Thank You!</Text>
          <Text style={styles.description}>
            Thanks for exploring Best and Worst Glasses! Frame your face with
            the perfect pair.
          </Text>
        </View>
      ),
      tabLink: <TabLink img={getImage("thanks")} text="End" />,
    },
  ];

  return slides;
};

export default GlassesGuide;
