interface Env {
  ENV: "development" | "production";
  FACEBOOK_OAUTH_CLIENTID: string;
  GOOGLE_OAUTH_CLIENTID_WEB: string;
  GOOGLE_OAUTH_CLIENTID_ANDROID: string;
  GOOGLE_OAUTH_CLIENTID_IOS: string;
  OAUTH_PATH: string;
  PRIVACY_POLICY_URL: string;
  TERMS_OF_SERVICE_URL: string;
  BASE_URL: string;
  is_quiz_before: boolean;
  firebase_config: any;
  stripe_key: string;
  stripe_customer_portal_url: string;
}

export const env: Env = {
  ENV: "production",
  stripe_customer_portal_url:
    "https://billing.stripe.com/p/login/5kAbIOcdedA5cJW288",
  get stripe_key() {
    if (this.ENV === "development") {
      return "pk_test_51J6w9cK4E7Lk3jZKj7jZ4o6p3rK9Q7QYJ3JQ6wYh5XjQ6M9L5O6J3d5LkY9nU0e0Z7K8Q5i7V7J8Z3X3m6zX7dYB00j5X1kX3";
    } else {
      return "";
    }
  },
  FACEBOOK_OAUTH_CLIENTID: "674500444436597",
  GOOGLE_OAUTH_CLIENTID_WEB:
    "1054392035428-p6lm1bvruhhbigufivnbhik1h217m774.apps.googleusercontent.com",
  GOOGLE_OAUTH_CLIENTID_ANDROID: "",
  GOOGLE_OAUTH_CLIENTID_IOS:
    "1054392035428-iec0diu36lk66l30am3ffdk9gj1qh7l2.apps.googleusercontent.com",
  get OAUTH_PATH() {
    if (this.ENV === "development") {
      return "";
    }
    return "";
  },
  get BASE_URL() {
    if (this.ENV === "development") {
      return "http://192.168.1.76:8080/";
    }
    return "https://main-service-v0-z5dbucy7ba-uc.a.run.app/";
  },
  PRIVACY_POLICY_URL: "https://stylee.app/privacy-policy",
  TERMS_OF_SERVICE_URL: "https://stylee.app/tos",
  is_quiz_before: true,
  get firebase_config() {
    if (this.ENV === "development") {
      return {
        apiKey: "AIzaSyD8vK4UJSbNNjf3SI3_qJU5fqpU6hexyYI",
        authDomain: "stylee-dev-e5b49.firebaseapp.com",
        projectId: "stylee-dev-e5b49",
        storageBucket: "stylee-dev-e5b49.appspot.com",
        messagingSenderId: "950718406697",
        appId: "1:950718406697:web:8a63b03ab6b3283423fc38",
        measurementId: "G-6TC9NPLW8P",
      };
    } else {
      return {
        apiKey: "AIzaSyA7O6d7u8ggc7vUareVAk1iIWkeuC3jHKw",
        authDomain: "stylee-81712.firebaseapp.com",
        databaseURL: "https://stylee-81712-default-rtdb.firebaseio.com",
        projectId: "stylee-81712",
        storageBucket: "stylee-81712.appspot.com",
        messagingSenderId: "1054392035428",
        appId: "1:1054392035428:web:704d7ba4380c267dd15771",
        measurementId: "G-7M24K5X271",
      };
    }
  },
};
