import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { useUserStore } from "../hooks";
import usePayments from "../hooks/usePayments";
import AboutScreen from "../screens/about-screen/AboutScreen";
import ChangePasswordScreen from "../screens/change-password-screen/ChangePasswordScreen";
import ChangeProfileParameterScreen from "../screens/change-profile-parameter-screen/ChangeProfileParameterScreen";
import FeedbackScreen from "../screens/feedback-screen/FeedbackScreen";
import InProgressScreen from "../screens/in-progress-screen/InProgressScreen";
import PrivacyPolicyScreen from "../screens/privacy-policy-screen/PrivacyPolicyScreen";
import ProfileScreen from "../screens/profile-screen/ProfileScreen";
import QuizScreen from "../screens/quiz-screen/QuizScreen";
import SettingsScreen from "../screens/settings-screen/SettingsScreen";
import SubscriptionsScreen from "../screens/subscriptions-screen/SubscriptionsScreen";
import TosScreen from "../screens/tos-screen/TosScreen";
import HeaderAuthenticated from "./HeaderAuthenticated";
import NavigationParamList from "./NavigationParamList";

const Stack = createNativeStackNavigator<NavigationParamList>();

const SettingsStackNavigator = () => {
  const { user } = useUserStore();
  const { isPremium } = usePayments(user?.id);
  return (
    <Stack.Navigator
      screenOptions={{
        header: (args) => HeaderAuthenticated(args, user, isPremium),
        headerShown: true,
      }}
    >
      <Stack.Screen name="Settings" component={SettingsScreen} />
      <Stack.Screen name="InProgress" component={InProgressScreen} />
      <Stack.Screen name="TOS" component={TosScreen} />
      <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} />
      <Stack.Screen name="ChangePassword" component={ChangePasswordScreen} />
      <Stack.Screen name="Subscription" component={SubscriptionsScreen} />
      <Stack.Screen name="Feedback" component={FeedbackScreen} />
      <Stack.Screen name="Profile" component={ProfileScreen} />
      <Stack.Screen
        name="ChangeProfileParameters"
        component={ChangeProfileParameterScreen}
      />
      <Stack.Screen name="AboutScreen" component={AboutScreen} />
      <Stack.Screen name="QuizScreen" component={QuizScreen} />
    </Stack.Navigator>
  );
};

export default SettingsStackNavigator;
