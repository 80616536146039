import { StyleSheet, View } from "react-native";
import {
  FiltersI,
  StoreFilterI,
  FiltersUnitI,
} from "../../types/common/FiltersI";
import { useGlobalFiltersStore } from "../../hooks";
import SplashScreen from "../../screens/splash-screen/SplashScreen";
import { typography } from "../../theme";
import containers from "../../theme/containers";
import BrandOrStoreFilterCard from "../brand-or-store-filter-card";
import Dropdown from "../dropdown/Dropdown";
import { useEffect, useState } from "react";
import { Column, Input, Radio, Row, Slider, Text } from "native-base";
import { PrimaryButton } from "../buttons";

const mapListToDropdown = (list: StoreFilterI["stores"]) => {
  return list.map((item, index) => {
    return { id: index, name: item };
  });
};

export type StoreFilterProps = {
  hideModal?: () => void;
  hook: () => {
    filtersUnit: FiltersUnitI;
    setFiltersUnit: (filters: FiltersI) => void;
    description?: string;
    setDescription?: (description: string) => void;
  };
};

const FiltersPage = ({ hook, hideModal }: StoreFilterProps) => {
  const { filtersUnit, setFiltersUnit, description, setDescription } = hook();
  const [descriptionLocal, setDescriptionLocal] = useState<string | undefined>(
    description
  );
  const [filtersUnitLocal, setFiltersUnitLocal] = useState<FiltersUnitI>();

  useEffect(() => {
    console.log("filtersUnit", filtersUnit);
    setFiltersUnitLocal(filtersUnit);
  }, []);

  const brandPossibleValues =
    filtersUnitLocal?.possibleValues?.brandFilter?.brands;
  const storePossibleValues =
    filtersUnitLocal?.possibleValues?.storeFilter?.stores;
  const brandChosenValues = filtersUnitLocal?.chosenValues?.brandFilter?.brands;
  const storeChosenValues = filtersUnitLocal?.chosenValues?.storeFilter?.stores;
  console.log("filtersUnit", filtersUnitLocal);
  console.log(
    "brandPossibleValues",
    brandPossibleValues,
    brandChosenValues,
    storePossibleValues,
    storeChosenValues
  );
  if (
    !brandPossibleValues ||
    !storePossibleValues ||
    !brandChosenValues ||
    !storeChosenValues
  )
    return <SplashScreen></SplashScreen>;
  let chosenBrands: string[] = [...brandChosenValues];
  if (brandChosenValues.length == brandPossibleValues.length) {
    chosenBrands = [];
  }

  let chosenStores: string[] = [...storeChosenValues];
  if (storeChosenValues.length == storePossibleValues.length) {
    chosenStores = [];
  }

  const possibleStores = mapListToDropdown(storePossibleValues);
  const possibleBrands = mapListToDropdown(brandPossibleValues);

  return (
    <View style={styles.container}>
      {setDescription && (
        <View>
          <Text style={styles.h} mb={2}>
            Description
          </Text>
          <Input
            multiline={true}
            numberOfLines={0}
            placeholder="Description"
            style={styles.textInputProps}
            value={descriptionLocal || ""}
            onChangeText={(text) => {
              setDescriptionLocal(text);
            }}
          />
        </View>
      )}
      <Text style={styles.h} mb={2}>
        Source
      </Text>
      <View style={{ flexDirection: "row" }}>
        <Radio.Group
          name="myRadioGroup"
          accessibilityLabel="favorite number"
          value={filtersUnitLocal.chosenValues?.source}
          onChange={(nextValue) => {
            setFiltersUnitLocal({
              ...(filtersUnitLocal as FiltersUnitI),
              chosenValues: {
                ...(filtersUnitLocal.chosenValues as FiltersI),
                source: nextValue as FiltersI["source"],
              },
            });
          }}
          width={"100%"}
        >
          <Column justifyContent={"space-between"} width={"100%"}>
            {/* <Radio value="all" my={1} colorScheme="green">
              <Text>All</Text>
            </Radio> */}
            <Radio value="store" my={1} colorScheme="green">
              <Text>Stores</Text>
            </Radio>
            <Radio value="wardrobe" my={1} colorScheme="green">
              <Text>Wardrobe</Text>
            </Radio>
          </Column>
        </Radio.Group>
      </View>
      <Text style={styles.h} mb={2}>
        Brands
      </Text>
      <View style={styles.selectedContainer}>
        {chosenBrands.map((item) => {
          return (
            <BrandOrStoreFilterCard
              item={item}
              onPress={() => {
                setFiltersUnitLocal({
                  ...filtersUnitLocal,
                  chosenValues: {
                    ...(filtersUnitLocal.chosenValues as FiltersI),
                    brandFilter: {
                      brands: [...chosenBrands],
                    },
                  },
                });
              }}
            />
          );
        })}
      </View>

      <Dropdown
        placeholder="Select brands"
        items={possibleBrands}
        // selectedItems={[]}
        onItemSelect={(item: { name: string; id: number }) => {
          setFiltersUnitLocal({
            ...filtersUnitLocal,
            chosenValues: {
              ...(filtersUnitLocal.chosenValues as FiltersI),
              brandFilter: {
                brands: [...chosenBrands, item.name],
              },
            },
          });
        }}
      />
      {/* 
      <Text style={styles.h}>Stores</Text>
      <View style={styles.selectedContainer}>
        {chosenStores.map((item) => {
          return (
            <BrandOrStoreFilterCard
              item={item}
              onPress={() => {
                setFiltersUnit({
                  ...(filtersUnit.chosenValues as FiltersI),
                  storeFilter: {
                    stores: [
                      ...chosenStores.filter((stores) => stores != item),
                    ],
                  },
                });
              }}
            />
          );
        })}
      </View>

      <Dropdown
        placeholder="Select stores"
        items={possibleStores}
        // selectedItems={[]}
        onItemSelect={(item: { name: string }) => {
          setFiltersUnit({
            ...(filtersUnit.chosenValues as FiltersI),
            storeFilter: {
              stores: [...chosenStores, item.name],
            },
          });
        }}
      /> */}
      <Text style={styles.h} mb={2}>
        Price
      </Text>
      <Input
        mb={2}
        placeholder="Min"
        keyboardType="numeric"
        style={styles.textInputProps}
        value={filtersUnitLocal.chosenValues?.priceFilter?.minPrice || ""}
        onChangeText={(text) => {
          console.log("text", text);
          setFiltersUnitLocal({
            ...filtersUnitLocal,
            chosenValues: {
              ...(filtersUnitLocal.chosenValues as FiltersI),
              priceFilter: {
                minPrice: Number.parseInt(text),
                maxPrice: filtersUnitLocal.chosenValues?.priceFilter?.maxPrice,
              },
            },
          });
        }}
      />
      <Input
        mb={2}
        placeholder="Max"
        keyboardType="numeric"
        style={styles.textInputProps}
        value={
          filtersUnitLocal.chosenValues?.priceFilter?.maxPrice?.toString() || ""
        }
        onChangeText={(text) => {
          console.log("text", text);
          setFiltersUnitLocal({
            ...filtersUnitLocal,
            chosenValues: {
              ...(filtersUnitLocal.chosenValues as FiltersI),
              priceFilter: {
                maxPrice: Number.parseInt(text),
                minPrice: filtersUnitLocal.chosenValues?.priceFilter?.maxPrice,
              },
            },
          });
        }}
      />
      {hideModal && (
        <PrimaryButton
          text="Apply"
          onPress={() => {
            console.log("filtersUnitLocal", filtersUnitLocal);
            setFiltersUnit(filtersUnitLocal.chosenValues as FiltersI);
            setDescription && setDescription(descriptionLocal || "");
            hideModal();
          }}
        />
      )}
    </View>
  );
};

export default FiltersPage;

const styles = StyleSheet.create({
  container: { ...containers.screenContainer },
  h: { ...typography.h2 },
  selectedContainer: { flexDirection: "row", flexWrap: "wrap" },
  dropdownContainer: { padding: 5 },
  dropdownItem: {
    padding: 10,
    marginTop: 2,
    backgroundColor: "#ddd",
    borderColor: "#bbb",
    borderWidth: 1,
    borderRadius: 5,
  },
  dropdownItemText: { color: "#222" },
  dropdownItemContainer: { maxHeight: 140 },
  textInputProps: {
    padding: 12,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
  },
});
