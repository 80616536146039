import { Camera, CameraCapturedPicture, CameraType } from "expo-camera";
import * as ImagePicker from "expo-image-picker";
import * as MediaLibrary from "expo-media-library";
import React, { useEffect, useState } from "react";
import {
  Image,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { getImage } from "../../helpers/resourceProvider";
import PrimaryButton from "../buttons/PrimaryButton";
import { typography } from "../../theme";

let camera: Camera | null;

export type Props = {
  onLoad: (photo: string) => void;
  style?: any;
  element?: JSX.Element;
};

const GalleryPicker = ({ onLoad: setPhoto, style, element }: Props) => {
  const [hasMediaLibraryPermission, setHasMediaLibraryPermission] =
    useState<boolean>(false);

  let openImagePickerAsync = async () => {
    let permissionResult =
      await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted === false) {
      console.log("Permission to access camera roll is required!");
      return;
    }

    let pickerResult = await ImagePicker.launchImageLibraryAsync({
      base64: true,
    });
    if (pickerResult.canceled) {
      return;
    }
    let photo = "data:image/jpg;base64," + pickerResult.assets[0].base64;
    setPhoto(photo);
  };

  useEffect(() => {
    (async () => {
      const mediaLibraryPermission =
        await MediaLibrary.requestPermissionsAsync();
      setHasMediaLibraryPermission(mediaLibraryPermission.status === "granted");
    })();

    return () => {};
  }, []);

  return (
    <Pressable
      style={style}
      onPress={async () => {
        openImagePickerAsync();
      }}
    >
      {element ? (
        element
      ) : (
        <Image
          source={getImage("filmRoll")}
          style={{ width: 50, height: 50 }}
        />
      )}
    </Pressable>
  );
};

export default GalleryPicker;

const styles = StyleSheet.create({
  camera: {
    flexDirection: "column-reverse",
    paddingBottom: 0,
  },
  buttonContainer: {
    flexDirection: "row",
    alignSelf: "stretch",
    justifyContent: "center",
    paddingBottom: 20,
  },
  flipButton: {
    position: "absolute",
    left: 20,
    alignSelf: "center",
    width: 30,
    height: 30,
  },
  text: {
    ...typography.p1,
  },
  shutterButton: {
    width: 70,
    height: 70,

    alignSelf: "center",
  },
  mediaLibrary: {
    position: "absolute",
    right: 20,
    alignSelf: "center",
    width: 30,
    height: 30,
  },
  shutterButtonImg: {
    width: "100%",
    height: "100%",
  },
  promptText: {
    ...typography.p1,
  },
});
