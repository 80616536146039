import { NavigationProp, useNavigation } from "@react-navigation/native";
import React from "react";
import { Image, Text, View } from "react-native";
import { getImage } from "../../helpers/resourceProvider";
import useUserStore from "../../hooks/useUserStore";
import NavigationParamList, {
  StyleGuideArticleSlide,
} from "../../navigators/NavigationParamList";
import { typography } from "../../theme";
import TabLink from "./TabLink";
import { styles } from "./styles";

const ColorSeasonGuideScreen = (): StyleGuideArticleSlide[] => {
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const { user } = useUserStore();

  const slides = [
    {
      slide: (
        <View style={{}}>
          <Image
            source={{
              uri: "https://i.gyazo.com/143e4c736a7732b3c465496029b0cbd3.png",
            }}
            style={styles.mainIllustration}
            resizeMode="contain"
          />
          <Text style={styles.title}>Your Personal Color Palette</Text>
          <Text style={styles.description}>
            Discover the colors that complement you best! Our Color Palette
            feature creates a personalized color guide based on your skin tone,
            hair color, and personal preferences.
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink
          img={{
            uri: "https://i.gyazo.com/143e4c736a7732b3c465496029b0cbd3.png",
          }}
          text="Welcome"
        />
      ),
    },
    {
      slide: (
        <View>
          <Image
            source={{
              uri: "https://i.gyazo.com/e994b535583e5d7ce2bf9708c14c5bf7.png",
            }}
            style={styles.mainIllustration}
            resizeMode="cover"
          />
          <Text style={styles.title}>Harmony with Colors</Text>
          <Text style={styles.description}>
            In fashion, color harmony refers to combinations that are visually
            pleasing. This can include complementary colors (opposites on the
            color wheel for a bold contrast), analogous colors (colors next to
            each other on the color wheel for a coordinated look), and more.
            Understanding color harmony can help you put together outfits that
            truly pop!
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink
          img={{
            uri: "https://i.gyazo.com/e994b535583e5d7ce2bf9708c14c5bf7.png",
          }}
          text="Creating Harmony with Colors"
        />
      ),
    },
    {
      slide: (
        <View>
          <Image
            source={{
              uri: "https://i.gyazo.com/a8c54074de6e13d18e9c498017f53a88.png",
            }}
            style={[styles.mainIllustration]}
            resizeMode="contain"
          />
          <Text style={styles.title}>Color Seasons</Text>
          <Text style={styles.description}>
            In fashion, your 'color season' describes the palette of colors that
            most flatter your complexion, based on your skin undertone, hair,
            and eye color. There are four main seasons - Winter, Spring, Summer,
            and Autumn - each associated with specific color palettes. By
            understanding your color season, you can select clothing and
            accessory colors that truly complement your natural coloring.
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink
          img={{
            uri: "https://i.gyazo.com/a8c54074de6e13d18e9c498017f53a88.png",
          }}
          text="Understanding Color Seasons"
        />
      ),
    },
    {
      slide: (
        <View>
          <Image
            source={{
              uri: "https://i.gyazo.com/e8854e19e2e754db319cf30159f9d883.png",
            }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />

          <Text style={styles.title}>Why It Matters</Text>
          <Text style={styles.description}>
            Wearing colors from your color season enhances your natural features
            and complexion. They highlight your best features, create harmony,
            and enhance your overall style. Remember, while your color season
            provides a great starting point, personal style is also about
            expressing your unique personality. Feel free to experiment and
            break the rules!
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink
          img={{
            uri: "https://i.gyazo.com/e8854e19e2e754db319cf30159f9d883.png",
          }}
          text="Why Your Color Season Matters"
        />
      ),
    },
    {
      slide: (
        <View>
          <Image
            source={{
              uri: "https://i.gyazo.com/84eb213e8f526c28bcabd22c269fc1b4.png",
            }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>
            You are {user?.recommendations.colors.colortypeReadable}
          </Text>
          <Text style={styles.description}>
            {user?.recommendations.colors.justification}
          </Text>

          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          ></View>
        </View>
      ),
      tabLink: (
        <TabLink
          img={{
            uri: "https://i.gyazo.com/84eb213e8f526c28bcabd22c269fc1b4.png",
          }}
          text="Your color season"
        />
      ),
    },
    {
      slide: (
        <View>
          <View style={styles.colorContainer}>
            {user?.recommendations.colors.bestColors.map(
              (color: any, index: number) => (
                <View
                  key={index}
                  style={[styles.colorBox, { backgroundColor: color }]}
                />
              )
            )}
          </View>
          <Text style={styles.title}>Best Colors</Text>
          <Text style={styles.description}>
            {user?.recommendations.colors.explanationBestColors}
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink
          img={{
            uri: "https://i.gyazo.com/b50861adb4e293e535e22f54f4080122.png",
          }}
          text="Best Colors"
        />
      ),
    },
    {
      slide: (
        <View>
          <View style={styles.colorContainer}>
            {user?.recommendations.colors.worstColors.map(
              (color: any, index: number) => (
                <View
                  key={index}
                  style={[styles.colorBox, { backgroundColor: color }]}
                />
              )
            )}
          </View>
          <Text style={styles.title}>Worst Colors</Text>
          <Text style={styles.description}>
            {user?.recommendations.colors.explanationWorstColors}
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink
          img={{
            uri: "https://i.gyazo.com/b50861adb4e293e535e22f54f4080122.png",
          }}
          text="Worst Colors"
        />
      ),
    },
    {
      slide: (
        <View>
          <View style={styles.colorContainer}>
            {user?.recommendations.colors.neutralColors.map(
              (color: any, index: number) => (
                <View
                  key={index}
                  style={[styles.colorBox, { backgroundColor: color }]}
                />
              )
            )}
          </View>
          <Text style={styles.title}>Neutral Colors</Text>
          <Text style={styles.description}>
            {user?.recommendations.colors.explanationNeutralColors}
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink
          img={{
            uri: "https://i.gyazo.com/b50861adb4e293e535e22f54f4080122.png",
          }}
          text="Neutral Colors"
        />
      ),
    },
    {
      slide: (
        <View>
          <Image
            source={{
              uri: "https://i.gyazo.com/d2088aa5e82bac6be457194ad443eb56.png",
            }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>Contrast Analysis</Text>
          <Text style={styles.description}>
            Understanding your contrast level helps determine how intense your
            colors should be. High contrast individuals look best in bright
            colors, while low contrast individuals look better in softer, muted
            colors. Testing your contrast can be done by converting your image
            to black and white and checking the greyscale.
          </Text>
          <View style={{ height: 20 }} />
          <Text style={typography.h3}>Your Contrast Level</Text>
          <View style={{ height: 10 }} />
          <Text style={styles.description}>
            {user?.recommendations.colors.contrastAnalysis}
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink
          img={{
            uri: "https://i.gyazo.com/d2088aa5e82bac6be457194ad443eb56.png",
          }}
          text="Contrast Analysis"
        />
      ),
    },
    // {
    //   slide: (
    //     <View>
    //       <Image
    //         source={{
    //           uri: "https://i.gyazo.com/f67ecc7ca0b48f90941e7a48b1ba6018.png",
    //         }}
    //         style={[styles.mainIllustration]}
    //         resizeMode="cover"
    //       />
    //       <Text style={styles.title}>Your Contrast Level</Text>
    //       <Text style={styles.description}>
    //         {user?.recommendations.colors.contrastAnalysis}
    //       </Text>
    //     </View>
    //   ),
    //   tabLink: (
    //     <TabLink
    //       img={{
    //         uri: "https://i.gyazo.com/f67ecc7ca0b48f90941e7a48b1ba6018.png",
    //       }}
    //       text="Your Contrast Level"
    //     />
    //   ),
    // },
    {
      slide: (
        <View>
          <Image
            source={getImage("thanks")}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>Thanks!</Text>
          <Text style={styles.description}>
            You can generate the looks that perfectly match your color season
          </Text>
         
        </View>
      ),
      tabLink: <TabLink img={getImage("thanks")} text="Thanks!" />,
    },
  ];

  return slides;
};

export default ColorSeasonGuideScreen;
