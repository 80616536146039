import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import HeaderAuthenticated from "./HeaderAuthenticated";
import { Gender } from "../types/common/Gender";
import { LookI, Style } from "../types/common/LookI";
import FavoriteProductsScreen from "../screens/favorite-products-screen/FavoriteProductsScreen";
import FavoritesScreen from "../screens/favorites-screen/FavoritesScreen";
import ShopLookScreen from "../screens/shop-look-screen/ShopLookScreen";
import NavigationParamList from "./NavigationParamList";
import { useUserStore } from "../hooks";
import usePayments from "../hooks/usePayments";

export type FavoritesNavigatorParamList = {
  ShopLook: { look: LookI };
  HomeScreen: undefined;
  LookBook: { style: Style; gender: Gender };
  Filters: undefined;
  StyleGuide: undefined;
  CreateProductDescriptor: undefined;
  CreateLook: undefined;
  InProgress: undefined;
  StealLook: undefined;
  Favorites: undefined;
  FavoriteProducts: undefined;
};

const Stack = createNativeStackNavigator<NavigationParamList>();

const FavoritesStackNavigator = () => {
  const { user } = useUserStore();
  const { isPremium } = usePayments(user?.id);
  return (
    <Stack.Navigator
      screenOptions={{
        header: (args) => HeaderAuthenticated(args, user, isPremium),
        headerShown: true,
      }}
    >
      <Stack.Screen name="Favorites" component={FavoritesScreen} />
      <Stack.Screen name="ShopLook" component={ShopLookScreen} />
      <Stack.Screen
        name="FavoriteProducts"
        component={FavoriteProductsScreen}
      />
    </Stack.Navigator>
  );
};

export default FavoritesStackNavigator;
