import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import React from "react";
import { useUserStore } from "../../hooks";
import ProductI from "../../types/common/ProductI";
import { getImage } from "../../helpers/resourceProvider";

export type LikeButtonProps = {
  product: ProductI;
  style?: any;
};
const LikeButton = ({ product, style }: LikeButtonProps) => {
  const { user, addFavoriteProduct, removeFavoriteProduct } = useUserStore();
  const favoriteProducts = user?.favorites.products;
  const isLiked =
    favoriteProducts &&
    favoriteProducts.filter(
      (productFavoriteId) => productFavoriteId == product?.id
    ).length > 0;
  return (
    <Pressable
      style={[styles.likeIcon, style]}
      hitSlop={{ top: 20, left: 20, bottom: 20, right: 20 }}
      onPress={() => {
        if (isLiked) {
          removeFavoriteProduct(product);
        } else {
          addFavoriteProduct(product);
        }
      }}
    >
      <Image
        style={styles.imageHeart}
        source={
          isLiked ? getImage("filledHeartIcon") : getImage("unfilledHeartIcon")
        }
      />
    </Pressable>
  );
};

export default LikeButton;

const styles = StyleSheet.create({
  imageHeart: {
    resizeMode: "contain",
    width: "100%",
    height: "100%",
  },
  likeIcon: {
    zIndex: 100,

    height: 28,
    width: 28,
    resizeMode: "contain",
  },
});
