import colors from "./colors";
import spacing from "./spacing";

const fonts = {
  LibreBaskerville: require("../assets/fonts/LibreBaskerville-Bold.ttf"),
  RobotoBold: require("../assets/fonts/Roboto-Bold.ttf"),
  RobotoRegular: require("../assets/fonts/Roboto-Regular.ttf"),
  RobotoMedium: require("../assets/fonts/Roboto-Medium.ttf"),
  PoppinsRegular: require("../assets/fonts/Poppins-Regular.ttf"),
  PoppinsMedium: require("../assets/fonts/Poppins-Medium.ttf"),
  InterBold: require("../assets/fonts/Inter-Bold.ttf"),
} as const;

export enum FontFamily {
  LibreBaskerville = "LibreBaskerville",
  RobotoBold = "RobotoBold",
  RobotoRegular = "RobotoRegular",
  RobotoMedium = "RobotoMedium",
  PoppinsRegular = "PoppinsRegular",
  PoppinsMedium = "PoppinsMedium",
  InterBold = "InterBold",
}

const lineHeightCoeff = 1.3;
const typography = {
  h1: {
    fontSize: spacing.l,
    fontFamily: FontFamily.InterBold,
    color: colors.dark,
    lineHeight: 1.3 * spacing.l,
  },
  h2: {
    fontSize: spacing.mll,
    fontFamily: FontFamily.PoppinsMedium,
    color: colors.dark,
    lineHeight: spacing.mll * lineHeightCoeff,
  },
  h3: {
    fontSize: spacing.ml,
    fontFamily: FontFamily.PoppinsMedium,
    color: colors.dark,
    lineHeight: spacing.ml * lineHeightCoeff,
  },
  h4: {
    fontSize: spacing.mml,
    fontFamily: FontFamily.PoppinsMedium,
    color: colors.dark,
    lineHeight: spacing.mml * lineHeightCoeff,
  },
  h5: {
    fontSize: 14,
    fontFamily: FontFamily.PoppinsMedium,
    color: colors.dark,
    lineHeight: spacing.mml * lineHeightCoeff,
  },
  p1: {
    fontSize: spacing.m,
    fontFamily: FontFamily.PoppinsRegular,
    color: colors.gray,
    // letterSpacing: 1,
    lineHeight: spacing.m * lineHeightCoeff,
  },
  p1Dark: {
    fontSize: spacing.m,
    fontFamily: FontFamily.PoppinsRegular,
    color: colors.dark,
    // letterSpacing: 1,
    lineHeight: spacing.m * lineHeightCoeff,
  },
  p2: {
    fontSize: spacing.smm,
    fontFamily: FontFamily.PoppinsRegular,
    color: colors.gray,
    lineHeight: spacing.smm * lineHeightCoeff,
  },
  p2Dark: {
    fontSize: spacing.smm,
    fontFamily: FontFamily.PoppinsRegular,
    lineHeight: spacing.smm * lineHeightCoeff,
    color: colors.dark,
  },
  p3: {
    fontSize: spacing.sm,
    fontFamily: FontFamily.PoppinsRegular,
    color: colors.gray,
    // lineHeight: spacing.sm * lineHeightCoeff,
  },
  p4: {
    fontSize: spacing.ssm,
    fontFamily: FontFamily.PoppinsRegular,
    color: colors.gray,
    // lineHeight: spacing.ssm * lineHeightCoeff,
  },
  button: {
    fontSize: 14,
    fontFamily: FontFamily.PoppinsMedium,
    lineHeight: 1.3 * spacing.mml,
  },
};

export { typography, fonts };
