import {
  NavigationProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { Image, Pressable, Text, View } from "native-base";
import { getImgUrl } from "../../helpers/getImgUrl";
import NavigationParamList from "../../navigators/NavigationParamList";
import { colors, typography } from "../../theme";
import ProductI from "../../types/common/ProductI";
import LikeButton from "./likeButton";
import { useState } from "react";

export type ProductCardV2Props = {
  product: ProductI;
  style?: any;
  onPress?: (product: ProductI) => void;
};

export const ProductCardV2 = ({
  product,
  style,
  onPress,
}: ProductCardV2Props) => {
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const route = useRoute();
  const [fontCoeff, setFontCoeff] = useState(0.8); // Default font size

  const handleLayout = (event: any) => {
    const { width } = event.nativeEvent.layout;
    setFontCoeff(width * 0.007);
  };

  const navigateToProductScreen = async () => {
    if (onPress) onPress(product);
    else if (route.name == "Product")
      navigation.push("Product", { product: product });
    else navigation.navigate("Product", { product: product });
  };
  return (
    <View
      // onLayout={handleLayout}
      borderRadius={10}
      overflow="hidden"
      style={[style, { aspectRatio: 0.7 }]}
    >
      <LikeButton
        product={product}
        style={{ position: "absolute", top: "59%", right: 10 }}
      />
      <Pressable width="100%" height="100%" onPress={navigateToProductScreen}>
        <Image
          source={{ uri: getImgUrl(product?.image[0]) }}
          resizeMode="cover"
          borderRadius={10}
          style={{ width: "100%", height: "74%" }}
        />
        <Text mt={1} style={[typography.p4, { fontSize: fontCoeff * 10 }]}>
          {product?.brand}
        </Text>
        <Text
          numberOfLines={1}
          style={{
            fontFamily: typography.h1.fontFamily,
            fontSize: 13 * fontCoeff,

            color: colors.black,
          }}
        >
          {product?.name}
        </Text>
        <Text
          style={{
            fontFamily: typography.h1.fontFamily,
            fontSize: 13 * fontCoeff,
            color: colors.black,
          }}
        >
          {product?.price}$
        </Text>
      </Pressable>
    </View>
  );
};
