export enum ColorSeason {
  SPRING_BRIGHT = "SPRING_BRIGHT",
  SPRING_TRUE = "SPRING_TRUE",
  SPRING_LIGHT = "SPRING_LIGHT",
  SUMMER_LIGHT = "SUMMER_LIGHT",
  SUMMER_TRUE = "SUMMER_TRUE",
  SUMMER_SOFT = "SUMMER_SOFT",
  AUTUMN_SOFT = "AUTUMN_SOFT",
  AUTUMN_TRUE = "AUTUMN_TRUE",
  AUTUMN_DARK = "AUTUMN_DARK",
  WINTER_DARK = "WINTER_DARK",
  WINTER_TRUE = "WINTER_TRUE",
  WINTER_BRIGHT = "WINTER_BRIGHT",
  UNDEFINED = "UNDEFINED",
}
