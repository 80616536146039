import objectHash from "object-hash";

export function objectToIntHash(obj: any) {
  function stringHash(s: string): number {
    let hash = 0;
    if (s.length === 0) return hash;
    for (let i = 0; i < s.length; i++) {
      const char = s.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
  return stringHash(objectHash(obj));
}
