import { StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
// @ts-ignore
import Slider from "react-native-sliders";
import { NumberQuestionI, QuestionI } from "../../../types/Quiz";
import { convertMetricToImperial } from "../../../helpers/convertMetricToImperial";
import { typography } from "../../../theme";

const convertSliderValueToMetric = (
  value: number,
  min: number,
  max: number
) => {
  return Math.round(value * (max - min) + min);
};

const convertMetricToSlider = (metric: number, min: number, max: number) => {
  return (metric - min) / (max - min);
};

export type Props = {
  question: NumberQuestionI;
  onChange: (chosenAnswers: QuestionI["chosenAnswer"]) => void;
};
const SliderQuestion = ({ onChange, question }: Props) => {
  const [value, setValue] = useState(
    convertMetricToSlider(
      question.chosenAnswer,
      question.minValue,
      question.maxValue
    )
  );

  const metricValue = convertSliderValueToMetric(
    value,
    question.minValue,
    question.maxValue
  );
  const imperialValue = convertMetricToImperial(
    question.unitsToDisplay[0],
    metricValue
  );
  const caption = `${metricValue} ${question.unitsToDisplay[0]}  ${
    question.unitsToDisplay.length > 1
      ? "/" + imperialValue + question.unitsToDisplay[1]
      : ""
  }`;
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{caption}</Text>
      <Slider
        value={value}
        onSlidingComplete={(value: number) => {
          setValue(value);
          onChange(metricValue);
        }}
        onValueChange={(value: number) => {
          setValue(value);
        }}
      />
    </View>
  );
};

export default SliderQuestion;

const styles = StyleSheet.create({
  container: { flex: 1 },
  text: {
    ...typography.h3,
    marginBottom: 30,
    textAlign: "center",
  },
});
