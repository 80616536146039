import { Image, StyleSheet, View } from "react-native";
import containers from "../../theme/containers";
import { getImage } from "../../helpers/resourceProvider";

const SplashScreen = () => {
  return (
    <View style={styles.container}>
      <Image source={getImage("logo")} style={styles.image} />
    </View>
  );
};

export default SplashScreen;

const styles = StyleSheet.create({
  container: {
    ...containers.screenContainer,
    alignItems: "center",
    justifyContent: "center",
  },

  image: { height: 90, width: 170, resizeMode: "contain" },
});
