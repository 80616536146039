import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import React from "react";
import {
  Image,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import DrawerLayoutCustom from "../../components/drawer-layout-custom";
import { PageSlider } from "../../components/page-slider";
import { getImage } from "../../helpers/resourceProvider";
import { useUserStore } from "../../hooks";
import NavigationParamList from "../../navigators/NavigationParamList";
import colors from "../../theme/colors";
import containers from "../../theme/containers";
import { theme } from "../../theme/defaultTheme";

const sizeActive = 50;
const sizeInactive = 6;
const heightOfHandle = 40;

const StyleGuideArticleScreen = () => {
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const { user, getPhoto } = useUserStore();
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const route = useRoute<RouteProp<NavigationParamList, "StyleGuideArticle">>();

  const slides = route.params?.useSlides();
  const isShowDrawer = route.params?.isShowDrawer;
  const drawer = React.useRef(null);
  const marginForDots = 20;
  // calculates margin between dots so that they occupy the screen width
  const calculateMargin = () => {
    const numberOfDots = slides.length;
    const margin =
      (400 - marginForDots * 2 - sizeActive - sizeInactive * numberOfDots) /
      numberOfDots /
      2;
    return margin;
  };
  const margin = calculateMargin();
  if (!slides || slides.length == 0) return <Text>Loading</Text>;
  return (
    <View
      style={{
        height: "100%",
        overflow: "hidden",
      }}
    >
      <DrawerLayoutCustom
        drawerContent={
          <View style={{ width: "100%", height: "100%" }}>
            <View style={{ height: heightOfHandle }}>
              <View
                style={{
                  width: sizeActive,
                  height: heightOfHandle,
                  marginLeft:
                    marginForDots +
                    activeIndex * (2 * margin + sizeInactive) +
                    margin,
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "visible",
                }}
              >
                <Image
                  source={getImage("bottomDrawerHandle")}
                  style={{ width: 117, height: 41 }}
                />
              </View>
            </View>
            <ScrollView
              showsVerticalScrollIndicator={true}
              style={{ backgroundColor: colors.plask }}
              // contentContainerStyle={{ flexGrow: 1 }}
            >
              <View
                style={{
                  backgroundColor: colors.plask,
                  // height: 200,
                  overflow: "hidden",
                  marginBottom: 17,
                  height: 70 * slides.length,
                }}
              >
                {slides.map((slide, index) => {
                  return (
                    <Pressable
                      key={index}
                      style={{ marginVertical: 5, marginHorizontal: 20 }}
                      onPress={() => {
                        // @ts-ignore
                        drawer.current?.closeDrawer();
                        setActiveIndex(index);
                      }}
                    >
                      {slide.tabLink}
                    </Pressable>
                  );
                })}
              </View>
            </ScrollView>
          </View>
        }
        ref={drawer}
        drawerHeight={Math.min(50 * slides.length + heightOfHandle, 500)}
        closedDrawerHeight={heightOfHandle}
      >
        <PageSlider
          peek={0}
          pageMargin={0}
          style={{ flex: 1, backgroundColor: colors.bg }}
          selectedPage={activeIndex}
          onSelectedPageChange={setActiveIndex}
          onCurrentPageChange={function (currentPage: number): void {}}
          mode="card"
        >
          {slides.map((slide, index) => {
            return (
              // <View>
              <ScrollView
                style={[styles.container]}
                contentContainerStyle={{ flexGrow: 1 }}
                key={index}
              >
                <View style={{}}>{slide.slide}</View>
              </ScrollView>
              // {/* </View> */}
            );
          })}
        </PageSlider>

        <View
          style={{
            paddingTop: 20,
            paddingBottom: 20,
            paddingHorizontal: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: colors.bg,
          }}
        >
          {activeIndex > 0 && (
            <SecondaryButton
              text="Back"
              onPress={() => setActiveIndex(activeIndex - 1)}
              style={{ flex: 1, marginRight: 10 }}
            />
          )}
          {
            <PrimaryButton
              text={activeIndex < slides.length - 1 ? "Next" : "Home"}
              onPress={() =>
                activeIndex < slides.length - 1
                  ? setActiveIndex(activeIndex + 1)
                  : navigation.navigate("HomeScreen")
              }
              style={{ flex: 1 }}
            />
          }
        </View>
        <View
          style={{
            height: heightOfHandle,
            flexDirection: "row",
            backgroundColor: colors.bg,
            justifyContent: "flex-start",
            alignItems: "center",
            paddingLeft: marginForDots,
          }}
        >
          {slides.map((_, index) => {
            return (
              <View
                key={index}
                style={{
                  width: index == activeIndex ? sizeActive : sizeInactive,
                  height: sizeInactive,
                  borderRadius: sizeInactive,
                  backgroundColor: index == activeIndex ? "white" : "#D9D9D9",
                  marginHorizontal: margin,
                }}
              />
            );
          })}
        </View>
      </DrawerLayoutCustom>
      <View
        style={{
          height: Platform.OS == "ios" ? 50 : 20,
          backgroundColor: colors.plask,
        }}
      />
    </View>
  );
};

export default StyleGuideArticleScreen;

const styles = StyleSheet.create({
  container: {
    ...containers.screenContainer,
    padding: 20,
    backgroundColor: colors.bg,
    maxWidth: containers.rootContainer.maxWidth,
  },
  title: {
    fontFamily: theme.fontPrimaryBold,
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
  },
  subtitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 10,
  },
  description: {
    fontFamily: theme.fontSecondaryRegular,

    fontSize: 16,
    marginBottom: 10,
  },
  colorContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 20,
    flexWrap: "wrap",
  },
  colorBox: {
    width: 50,
    height: 50,
    borderRadius: 25,
    margin: 10,
  },
  celebritiesContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  celebrityBox: {
    width: "48%",
    marginBottom: 20,
  },
  celebrityImage: {
    width: "100%",
    height: 150,
    resizeMode: "cover",
    borderRadius: 10,
    marginBottom: 10,
  },
  celebrityName: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
  },
});
