import { Ionicons } from "@expo/vector-icons";
import { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import React from "react";
import {
  Image,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { spacing, typography } from "../../theme";
import colors from "../../theme/colors";
import { getImage } from "../../helpers/resourceProvider";
import { theme } from "../../theme/defaultTheme";

const BottomTabBar = ({
  state,
  descriptors,
  navigation,
}: BottomTabBarProps) => {
  return (
    <View
      style={{
        flexDirection: "row",
        backgroundColor: colors.lightgray,
        height: 60 + (Platform.OS == "web" ? 10 : 34),
        paddingBottom: 18,

        paddingTop: 18,
      }}
    >
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;

        const isFocused = state.index === index;

        const onPress = () => {
          const event = navigation.emit({
            type: "tabPress",
            target: route.key,
            canPreventDefault: true,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate({
              name: route.name,
              merge: true,
              params: route.params,
            }); //,
          }
        };

        const onLongPress = () => {
          navigation.emit({
            type: "tabLongPress",
            target: route.key,
          });
        };

        return (
          <Pressable
            key={index}
            accessibilityRole="button"
            accessibilityState={isFocused ? { selected: true } : {}}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
            onLongPress={onLongPress}
            style={{
              flex: 1,
              alignItems: "center",
            }}
          >
            {getIcon(route.name, isFocused)}
            <Text
              style={{
                color: isFocused
                  ? tabBarActiveTintColor
                  : tabBarInactiveTintColor,
                fontSize: 12,
                marginTop: 5,
                fontFamily: theme.fontPrimaryBold,
              }}
            >
              {route.name}
            </Text>
          </Pressable>
        );
      })}
    </View>
  );
};

const iconSize = 24;
const tabBarInactiveTintColor = colors.middlegray;
const tabBarActiveTintColor = colors.blue;

const getIcon = (name: string, isFocused: boolean) => {
  if (name === "Main") {
    return (
      <Image
        source={getImage(isFocused ? "navbarHomeFilled" : "navbarHomeUnfilled")}
        style={{ width: iconSize, height: iconSize }}
        resizeMode="contain"
      />
    );
  } else if (name === "Settings") {
    return (
      <Image
        source={getImage(
          isFocused ? "navbarSettingsFilled" : "navbarSettingsUnfilled"
        )}
        style={{ width: iconSize, height: iconSize }}
        resizeMode="contain"
      />
    );
  } else if (name === "Favorites") {
    return (
      <Image
        source={getImage(
          isFocused ? "navbarHeartFilled" : "navbarHeartUnfilled"
        )}
        style={{ width: iconSize, height: iconSize }}
        resizeMode="contain"
      />
    );
  } else if (name === "Wardrobe") {
    return (
      <Image
        source={getImage(
          isFocused ? "navbarBriefcaseFilled" : "navbarBriefcaseUnfilled"
        )}
        style={{ width: iconSize, height: iconSize }}
        resizeMode="contain"
      />
    );
  } else if (name === "Profile") {
    return (
      <Ionicons
        name={isFocused ? "body" : "body-outline"}
        size={iconSize}
        color={isFocused ? tabBarActiveTintColor : tabBarInactiveTintColor}
      />
    );
  } else if (name === "DeckSwiper") {
    return (
      <Ionicons
        name={isFocused ? "egg" : "egg-outline"}
        size={iconSize}
        color={isFocused ? tabBarActiveTintColor : tabBarInactiveTintColor}
      />
    );
  }
};

const styles = StyleSheet.create({});

export default BottomTabBar;
