import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { getImage } from "../../helpers/resourceProvider";
import containers from "../../theme/containers";

const InProgressScreen = () => {
  return (
    <View style={styles.container}>
      <Image source={getImage("logo")} style={styles.image} />
      <Text style={styles.p}>Sorry, we are still working on it...</Text>
    </View>
  );
};

export default InProgressScreen;

const styles = StyleSheet.create({
  container: { ...containers.screenContainer },

  image: { height: 150, width: 200, resizeMode: "contain" },
  p: { fontSize: 18 },
});
