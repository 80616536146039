import { createNativeStackNavigator } from "@react-navigation/native-stack";
import ChangeProfileParameterScreen from "../screens/change-profile-parameter-screen/ChangeProfileParameterScreen";
import ProfileScreen from "../screens/profile-screen/ProfileScreen";
import QuizScreen from "../screens/quiz-screen/QuizScreen";
import HeaderAuthenticated from "./HeaderAuthenticated";
import NavigationParamList from "./NavigationParamList";

const Stack = createNativeStackNavigator<NavigationParamList>();

const ProfileStackNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: HeaderAuthenticated,
        headerShown: true,
      }}
    >
      <Stack.Screen name="Profile" component={ProfileScreen} />
      <Stack.Screen
        name="ChangeProfileParameters"
        component={ChangeProfileParameterScreen}
      />
      <Stack.Screen name="QuizScreenProfile" component={QuizScreen} />
    </Stack.Navigator>
  );
};

export default ProfileStackNavigator;
