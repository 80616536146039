import React from "react";
import { StyleSheet, View } from "react-native";
import { useGetProductByIdQuery } from "../../store/api";
import ProductCard from "./ProductCard";
import { ProductCardV2 } from "./ProductCardV2";

export type Props = {
  id: number;
  style?: any;
};

const ProductByIdCard = ({ id, style }: Props) => {
  const { data: product } = useGetProductByIdQuery(id);
  if (!product) return <View></View>;
  return <ProductCardV2 product={product} style={[style]} />;
};

export default ProductByIdCard;

const styles = StyleSheet.create({});
