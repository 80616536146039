import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { setStatus } from "../store/reducers/modalReducer";

export const useModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector((state: RootState) => state.modal);

  const showModal = (content: RootState["modal"]["content"]) => {
    dispatch(setStatus({ isShowing: true, content: content }));
  };
  const hideModal = () => {
    dispatch(setStatus({ isShowing: false, content: null }));
  };

  return {
    modal,
    showModal,
    hideModal,
  };
};
