import { createMongoAbility } from "@casl/ability";

import { NavigationProp, useNavigation } from "@react-navigation/native";
import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { Fade, Placeholder, PlaceholderMedia } from "rn-placeholder";
import PremiumLabel from "../../components/premium-label/PremiumLabel";
import RectangleCard from "../../components/rectangle-card/RectangleCard";
import { getImage } from "../../helpers/resourceProvider";
import useUserStore from "../../hooks/useUserStore";
import { typography } from "../../theme";
import colors from "../../theme/colors";
import containers from "../../theme/containers";
import ColorSeasonGuideScreen from "../style-guide-articles/ColorSeasonGuide";
import HairstylesGuide from "../style-guide-articles/HairstylesGuide";
import PrintsPatternsGuide from "../style-guide-articles/PrintsPatternsGuide";
import NavigationParamList, {
  StyleGuideArticleSlide,
} from "../../navigators/NavigationParamList";
import AccessoriesGuide from "../style-guide-articles/AccessoriesGuide";
import JeansGuide from "../style-guide-articles/JeansGuide";
import OuterwearGuide from "../style-guide-articles/OuterwearGuide";
import GlassesGuide from "../style-guide-articles/GlassesGuide";
import BodytypeGuide from "../style-guide-articles/BodytypeGuide";
import usePayments from "../../hooks/usePayments";
import Loader from "../../components/loader";

const styleArticles = [
  {
    id: 0,
    image: getImage("colors"),
    header: "Colors",
    description: "Know your colors",
  },
  {
    id: 1,
    image: getImage("hairstyles"),
    header: "Hairstyles",
    description: "Hairstyles for your face shape",
  },
  {
    id: 2,
    image: getImage("patterns"),
    header: "Prints",
    description: "Prints and patterns for your type",
  },
  {
    id: 3,
    image: getImage("accessories"),
    header: "Accessories",
    description: "Elements to complete your look",
  },
  {
    id: 4,
    image: getImage("jeans"),
    header: "Jeans",
    description: "Jeans for your body type",
  },
  {
    id: 5,
    image: getImage("outerwear"),
    header: "Outerwear",
    description: "Outerwear for your body type",
  },
  {
    id: 6,
    image: getImage("glasses"),
    header: "Glasses",
    description: "Glasses for your face shape",
  },
  {
    id: 7,
    image: getImage("bodytype"),
    header: "Body type",
    description: "Recommendations for your body type",
  },
];

const StyleGuideScreen = () => {
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const { user, getPhoto, rules } = useUserStore();
  const ability = createMongoAbility(rules);
  const {
    makePurchase,
    currentOffering,
    customerInfo,
    getCustomerInfo,
    isPremium,
  } = usePayments(user?.id);

  if (!user) return <LoadingState />;
  if (!user.recommendations.isSetup) {
    return (
      <View style={styles.container}>
        <Loader
          time={20000}
          messages={[
            "Generating best colors",
            "Generating accessories",
            "Personalizing how to dress for your bodytype",
            "Generating best hairstyles",
            "Generating best prints and patterns",
            "Generating best jeans",
            "Generating best outerwear",
            "Generating best glasses",
          ]}
          sections={10}
          textStyle={[typography.p1, { marginTop: 20 }]}
          containerStyle={{
            width: "70%",
          }}
          progressBarStyle={{
            height: 20,
          }}
          type={"flow"}
        />
      </View>
    );
  }
  return (
    <ScrollView style={styles.scrollView}>
      <View style={styles.container}>
        {styleArticles.map((article, index) => (
          <RectangleCard
            key={article.id}
            onPress={() => {
              if (article.id == 0) {
                navigation.navigate("StyleGuideArticle", {
                  useSlides: ColorSeasonGuideScreen,
                  isShowDrawer: true,
                });
                return;
              }
              if (isPremium) {
                if (article.id == 1) {
                  navigation.navigate("StyleGuideArticle", {
                    useSlides: HairstylesGuide,
                    isShowDrawer: true,
                  });
                  return;
                }
                if (article.id == 2) {
                  navigation.navigate("StyleGuideArticle", {
                    useSlides: PrintsPatternsGuide,
                    isShowDrawer: true,
                  });
                  return;
                }
                if (article.id == 3) {
                  navigation.navigate("StyleGuideArticle", {
                    useSlides: AccessoriesGuide,
                    isShowDrawer: true,
                  });
                  return;
                }
                if (article.id == 4) {
                  navigation.navigate("StyleGuideArticle", {
                    useSlides: JeansGuide,
                    isShowDrawer: true,
                  });
                  return;
                }
                if (article.id == 5) {
                  navigation.navigate("StyleGuideArticle", {
                    useSlides: OuterwearGuide,
                    isShowDrawer: true,
                  });
                  return;
                }
                if (article.id == 6) {
                  navigation.navigate("StyleGuideArticle", {
                    useSlides: GlassesGuide,
                    isShowDrawer: true,
                  });
                  return;
                }
                if (article.id == 7) {
                  navigation.navigate("StyleGuideArticle", {
                    useSlides: BodytypeGuide,
                    isShowDrawer: true,
                  });
                  return;
                }
              } else
                navigation.navigate("Subscription", {
                  context: `styleguide_${article.header}`,
                });
            }}
            image={article.image}
            header={article.header}
            description={article.description}
            style={{
              marginBottom: 10,
              width: "45%",
            }}
            ar={1.15}
          >
            {!isPremium && article.id != 0 && <PremiumLabel text="Pro" />}
          </RectangleCard>
        ))}
      </View>
    </ScrollView>
  );
};

// casl relies on class names to check permissions and bundler minifies class names - so we need to create a class with a name that will not be minified
const StyleGuide = new Function(`return class StyleGuide {
  constructor(id) {
    this.id = id;
  }
}`)();

export default StyleGuideScreen;

const styles = StyleSheet.create({
  scrollView: { flex: 1, paddingTop: 20, backgroundColor: colors.bg },
  container: {
    ...containers.screenContainer,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },

  card: {
    flex: 1,
    minWidth: 100,
    maxWidth: 250,
    marginHorizontal: 10,
  },
  image: {
    width: 100,
    height: 100,
  },
  h1: { ...typography.h1, marginBottom: 30 },

  featuresContainer: {
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    marginBottom: 30,
    flex: 1,
  },
});

const LoadingState = () => {
  return (
    <View style={stylesLoading.placeholder}>
      <Placeholder Animation={Fade}>
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
      </Placeholder>
    </View>
  );
};

const stylesLoading = StyleSheet.create({
  placeholder: { flex: 1 },
  placeholderBasicAndGuide: {
    width: "100%",
    height: 140,
    borderRadius: 5,
    marginBottom: 10,
  },
  placeholderOccasion: {
    width: "30%",
    height: 140,
    borderRadius: 5,
    marginBottom: 10,
  },
});
