import tinycolor from "tinycolor2";
import { ColorSeason } from "../types/common/ColorSeason";
import { Platform } from "react-native";
export const images = {
  plusIcon: require("../assets/images/plus-icon.png"),
  filmRoll: require("../assets/images/film-roll.png"),
  //styleguide
  thanks: require("../assets/images/styleguide/thanks.png"),
  //chhat
  sendUnfilled: require("../assets/images/send-unfilled.png"),
  sendFilled: require("../assets/images/send-filled.png"),
  userAvatar: require("../assets/images/user-avatar.png"),
  // subscription screen
  checkmarkIcon: require("../assets/images/checkmark-icon.png"),
  // generateLook
  trashIcon: require("../assets/images/trash-icon.png"),
  refreshIcon: require("../assets/images/refresh-icon.png"),
  shopIcon: require("../assets/images/shop-icon.png"),
  likeIcon: require("../assets/images/like-icon.png"),
  editIcon: require("../assets/images/edit-icon.png"),
  filtersIcon: require("../assets/images/filters-icon.png"),

  //navbar
  navbarHeartUnfilled: require("../assets/images/navbar-heart-unfilled.png"),
  navbarHeartFilled: require("../assets/images/navbar-heart-filled.png"),
  navbarHomeUnfilled: require("../assets/images/navbar-home-unfilled.png"),
  navbarHomeFilled: require("../assets/images/navbar-home-filled.png"),
  navbarSettingsUnfilled: require("../assets/images/navbar-settings-unfilled.png"),
  navbarSettingsFilled: require("../assets/images/navbar-settings-filled.png"),
  navbarPlusUnfilled: require("../assets/images/navbar-plus-unfilled.png"),
  navbarBriefcaseUnfilled: require("../assets/images/navbar-briefcase-unfilled.png"),
  navbarBriefcaseFilled: require("../assets/images/navbar-briefcase-filled.png"),
  //shuffle
  shuffle: require("../assets/images/shuffle.png"),
  //bottom-drawer-handle
  bottomDrawerHandle: require("../assets/images/bottom-drawer-handle.png"),

  // lookbook
  nightOut: require("../assets/images/cards/night-out.png"),
  formal: require("../assets/images/cards/formal.png"),
  street: require("../assets/images/cards/street-style.png"),
  casual: require("../assets/images/cards/casual.png"),
  allStyles: require("../assets/images/cards/all-styles.png"),
  //home
  stealLookCard: require("../assets/images/cards/steal-look-card.png"),
  arrowForwardRounded: require("../assets/images/arrow-forward-rounded.png"),
  styleGuideCard: require("../assets/images/cards/style-guide-card.png"),
  capsuleWardrobeCard: require("../assets/images/cards/capsule-card.png"),
  analyzeLookCard: require("../assets/images/cards/analyze-look-card.png"),
  // Logo
  googleLogo: require("../assets/images/google-icon-color.png"),
  facebookLogo: require("../assets/images/facebook-icon-color.png"),
  logo: require("../assets/images/logo_black.png"),
  //Onboarding
  onboarding1: require("../assets/images/onboarding/onboarding1.png"),
  onboarding2: require("../assets/images/onboarding/onboarding2.png"),
  onboarding3: require("../assets/images/onboarding/onboarding3.png"),
  onboarding4: require("../assets/images/onboarding/onboarding4.png"),
  //Style Guide
  colors: require("../assets/images/cards/style-guide-color.png"),
  hairstyles: require("../assets/images/cards/style-guide-hairstyle.png"),
  outerwear: require("../assets/images/cards/style-guide-outerwear.png"),
  patterns: require("../assets/images/cards/style-guide-pattern.png"),
  accessories: require("../assets/images/cards/style-guide-accessories.png"),
  jeans: require("../assets/images/cards/style-guide-jeans.png"),
  bodytype: require("../assets/images/cards/style-guide-bodytype.png"),
  glasses: require("../assets/images/cards/style-guide-glasses.png"),
  // SETTINGS
  settings_reset: require("../assets/images/Settings/reset-profile.png"),
  settings_subscriptions: require("../assets/images/Settings/subscriptions.png"),
  settings_password: require("../assets/images/Settings/password.png"),
  settings_notifications: require("../assets/images/Settings/notifications.png"),
  settings_thanks: require("../assets/images/Settings/say-thanks.png"),
  settings_feedback: require("../assets/images/Settings/feedback.png"),
  settings_tos: require("../assets/images/Settings/tos.png"),
  settings_privacy: require("../assets/images/Settings/privacy.png"),
  settings_logout: require("../assets/images/Settings/logout.png"),
  settings_delete: require("../assets/images/Settings/delete.png"),
  settings_about: require("../assets/images/Settings/about.png"),
  settings_profile: require("../assets/images/Settings/profile.png"),
  // QUIZ
  quiz_male: require("../assets/images/quiz/male.png"),
  quiz_female: require("../assets/images/quiz/female.png"),
  quiz_nonBinary: require("../assets/images/quiz/non-binary.png"),
  quiz_hairtypeCurly: require("../assets/images/quiz/hairtype-curly.png"),
  quiz_hairtypeStraight: require("../assets/images/quiz/hairtype-straight.png"),
  quiz_hairtypeWavy: require("../assets/images/quiz/hairtype-wavy.png"),
  quiz_longhair: require("../assets/images/quiz/longhair.png"),
  quiz_middlehair: require("../assets/images/quiz/middlehair.png"),
  quiz_shorthair: require("../assets/images/quiz/shorthair.png"),
  quiz_maternity: require("../assets/images/quiz/maternity.png"),
  quiz_tall: require("../assets/images/quiz/tall.png"),
  quiz_petite: require("../assets/images/quiz/petite.png"),
  quiz_plussize: require("../assets/images/quiz/plussize.png"),
  quiz_faceshapeRound: require("../assets/images/quiz/faceshape-round.png"),
  quiz_faceshapeSquare: require("../assets/images/quiz/faceshape-square.png"),
  quiz_faceshapeOval: require("../assets/images/quiz/faceshape-oval.png"),
  quiz_faceshapeOblong: require("../assets/images/quiz/faceshape-oblong.png"),
  quiz_faceshapeTriangle: require("../assets/images/quiz/faceshape-triangle.png"),
  quiz_faceshapeDiamond: require("../assets/images/quiz/faceshape-diamond.png"),
  // bodytype
  quiz_rectangle: require("../assets/images/quiz/rectangle_female.png"),
  quiz_pear: require("../assets/images/quiz/pear_female.png"),
  quiz_hourglass: require("../assets/images/quiz/hourglass_female.png"),
  quiz_invertedTriangle: require("../assets/images/quiz/inverted-triangle_female.png"),
  quiz_apple: require("../assets/images/quiz/apple_female.png"),

  // Misc
  loadingIndicator: require("../assets/images/loading.gif"),
  choosePhoto: require("../assets/images/choose-photo.png"),
  cameraProcessing: require("../assets/images/camera/loading.gif"),
  // deck swiper
  heart: require("../assets/images/heart.png"),
  dislike: require("../assets/images/dislike.png"),
  rewind: require("../assets/images/rewind.png"),
  star: require("../assets/images/star.png"),
  cart: require("../assets/images/cart.png"),

  "star-filled": require("../assets/images/star-filled.png"),
  filledHeartIcon: require("../assets/images/heart-icon-filled.png"),
  unfilledHeartIcon: require("../assets/images/heart-icon.png"),
  trashBinIcon: require("../assets/images/trashbin-icon.png"),
  threeDotsIcon: require("../assets/images/more.png"),
  goodTrends: require("../assets/images/good-trends.png"),
  // Subscription
  premium_unlimited: require("../assets/images/cards/premium_unlimited.png"),
  premium_personalization: require("../assets/images/cards/premium_personalization.png"),
  premium_trends: require("../assets/images/cards/premium_trends.png"),
  premium_discounts: require("../assets/images/cards/premium_save-money.png"),
  // BrandOrFilterCard
  xIcon: require("../assets/images/x-mark-32.png"),
  // Header
  backArrow: require("../assets/images/back-arrow.png"),
  mailLine: require("../assets/images/mail-line.png"),
  passwordLeftIcon: require("../assets/images/password-left.png"),
  passwordRightIcon: require("../assets/images/password-right.png"),
  //camera
  cameraFlip: require("../assets/images/camera/flip-white.png"),
  cameraShutter: require("../assets/images/camera/shutter-white.png"),
  cameraFromGallery: require("../assets/images/camera/folder.png"),
  // signup-signin
  loginScreen: require("../assets/images/login-screen-image.png"),
} as const;

export const getImage = (name: keyof typeof images) => {
  const image = images[name];

  if (!image) {
    console.error(`Image ${name} not found`);
    return;
  }
  // fix ios gives error that json value is not a number
  if (Platform.OS != "ios") {
    return { uri: image };
  }
  return image;
};

export const detectSeason = (color: string): ColorSeason => {
  const hsl = tinycolor(color).toHsl();
  let result: ColorSeason;
  if (hsl.h < 91 || hsl.h > 269) {
    if (hsl.s > 0.5) {
      result = ColorSeason.SPRING_TRUE;
    } else {
      result = ColorSeason.AUTUMN_TRUE;
    }
  } else {
    if (hsl.s > 0.5) {
      result = ColorSeason.WINTER_TRUE;
    } else {
      result = ColorSeason.SUMMER_TRUE;
    }
  }
  return result;
};
