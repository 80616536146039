import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { QuestionI } from "../../types/Quiz";

import NavigationParamList from "../../navigators/NavigationParamList";
import { typography } from "../../theme";
import containers from "../../theme/containers";
import { renderQuestion } from "../quiz-screen/QuizScreen";

const ChangeProfileParameterScreen = () => {
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const route =
    useRoute<RouteProp<NavigationParamList, "ChangeProfileParameters">>();
  const [question, setQuestion] = useState<QuestionI>({
    ...route.params.question,
  });

  const handleSave = () => {
    if (question.result != "photo")
      route.params.onSave({ [question.result]: question.chosenAnswer });
    navigation.goBack();
  };

  return (
    <View style={styles.container}>
      <Text style={styles.h}>{question.question}</Text>
      {renderQuestion(question, (answer) =>
        setQuestion({ ...question, chosenAnswer: answer })
      )}
      <PrimaryButton text="Save" onPress={handleSave} />
    </View>
  );
};

export default ChangeProfileParameterScreen;

const styles = StyleSheet.create({
  container: { ...containers.screenContainer },

  h: {
    ...typography.h2,
    marginBottom: 30,
  },
});
