import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import HeaderAuthenticated from "./HeaderAuthenticated";
import { LookFilterI, LookI } from "../types/common/LookI";
import DeckSwiperScreen from "../screens/deck-swiper-screen/DeckSwiperScreen";
import ShopLookScreen from "../screens/shop-look-screen/ShopLookScreen";

export type DeckSwiperRouteParamList = {
  ShopLook: { look: LookI };
  HomeScreen: undefined;
  LookBook: { lookFilter: LookFilterI };
  Filters: undefined;
  StyleGuide: undefined;
  CreateProductDescriptor: undefined;
  CreateLook: undefined;
  InProgress: undefined;
  StealLook: undefined;
  DeckSwiper: { lookFilter: LookFilterI };
};

const Stack = createNativeStackNavigator<DeckSwiperRouteParamList>();

const DeckSwiperStackNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: HeaderAuthenticated,
        headerShown: true,
      }}
    >
      <Stack.Screen
        name="DeckSwiper"
        component={DeckSwiperScreen}
        initialParams={{ lookFilter: {} }}
      />
      <Stack.Screen name="ShopLook" component={ShopLookScreen} />
    </Stack.Navigator>
  );
};

export default DeckSwiperStackNavigator;
