import React from "react";
import { StyleSheet, Text, View } from "react-native";
import containers from "../../theme/containers";

const AboutScreen = () => {
  return (
    <View style={styles.container}>
      <Text>
        <a
          href="https://www.flaticon.com/free-icons/shuffle-arrows"
          title="shuffle arrows icons"
        >
          Shuffle arrows icons created by SeyfDesigner - Flaticon
        </a>
        <a href="https://www.freepik.com/free-vector/male-female-different-body-types-flat-set-isolated-vector-illustration_31643742.htm#query=body%20type&position=8&from_view=search&track=sph">
          Image by macrovector
        </a>
        on Freepik
        <a
          href="https://www.flaticon.com/free-icons/photo-and-camera"
          title="photo and camera icons"
        >
          Photo and camera icons created by orvipixel - Flaticon
        </a>
        <a
          href="https://www.flaticon.com/free-icons/dislike"
          title="dislike icons"
        >
          Dislike icons created by Graphics Plazza - Flaticon
        </a>
        <a href="https://www.flaticon.com/free-icons/heart" title="heart icons">
          Heart icons created by Kiranshastry - Flaticon
        </a>
        <a
          href="https://www.flaticon.com/free-icons/rewind"
          title="rewind icons"
        >
          Rewind icons created by RomStu - Flaticon
        </a>
        <a href="https://www.flaticon.com/free-icons/star" title="star icons">
          Star icons created by Freepik - Flaticon
        </a>
        <a
          href="https://www.flaticon.com/free-icons/delete"
          title="delete icons"
        >
          Delete icons created by Freepik - Flaticon
        </a>
        <a href="https://www.flaticon.com/free-icons/more" title="more icons">
          More icons created by Freepik - Flaticon
        </a>
      </Text>
    </View>
  );
};
const styles = StyleSheet.create({
  container: { ...containers.screenContainer },
});

export default AboutScreen;
