import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";

import { Camera } from "expo-camera";
import { PhotoQuestionI, QuestionI } from "../../../types/Quiz";
import { getImage } from "../../../helpers/resourceProvider";
import useAppStatus from "../../../hooks/useAppStatus";
import useUserStore from "../../../hooks/useUserStore";
import CameraComponent from "../../camera-component";
import PrimaryButton from "../../buttons/PrimaryButton";
import { typography } from "../../../theme";

let camera: Camera | null;

export type Props = {
  question: PhotoQuestionI;
  onChange: (chosenAnswers: QuestionI["chosenAnswer"]) => void;
};

const CameraQuestion = ({ question, onChange }: Props) => {
  const { setPhotoResult, setPhoto } = useUserStore();
  const [photo, setPhotoLocal] = useState<string>("");
  const { showMessage } = useAppStatus();

  useEffect(() => {
    if (
      setPhotoResult.status == "PROCESSED" &&
      photo != question.chosenAnswer
    ) {
      console.log("setPhotoResult", setPhotoResult);
      if (setPhotoResult.result?.result == "SUCCESS") {
        onChange(photo);
      } else {
        console.log("error", setPhotoResult.result?.message);
        showMessage(setPhotoResult.result?.message as string);
      }
    } else if (setPhotoResult.status == "PROCESSING") {
      console.log("PROCESSING");
    }
  }, [setPhotoResult]);
  const isPhoto = question.chosenAnswer != "";

  return (
    <View style={[styles.container]}>
      {setPhotoResult.status == "PROCESSING" ? (
        <View
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            source={getImage("cameraProcessing")}
            style={{ width: 100, height: 100 }}
          />
        </View>
      ) : isPhoto ? (
        <View
          style={[
            styles.container,
            { flexDirection: "column", justifyContent: "space-between" },
          ]}
        >
          <Image
            defaultSource={getImage("loadingIndicator")}
            source={{ uri: question.chosenAnswer }}
            style={[{ width: "100%", height: "80%" }]}
            resizeMode="contain"
          />
          <PrimaryButton
            text="take another photo"
            onPress={() => {
              setPhotoLocal("");
              onChange("");
            }}
          />
        </View>
      ) : (
        <View style={{ flex: 1 }}>
          <CameraComponent
            onLoad={(photo: string) => {
              setPhoto(photo);
              setPhotoLocal(photo);
            }}
            style={{ marginBottom: 20 }}
          />
          <Text style={styles.promptText}>
            For better results, take the photo with natural lightning, facing
            the window. In any case you will be able to take it again as many
            times as you want.
          </Text>
        </View>
      )}
    </View>
  );
};

export default CameraQuestion;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  camera: {
    resizeMode: "contain",
    flex: 0.9,
    flexDirection: "column-reverse",
    paddingBottom: 0,
    marginBottom: 10,
  },
  buttonContainer: {
    flexDirection: "row",
    alignSelf: "stretch",
    justifyContent: "center",
    paddingBottom: 20,
  },
  flipButton: {
    position: "absolute",
    // backgroundColor: "yellow",
    left: 20,
    //flex: 0.1,
    // alignSelf: "flex-end",
    alignSelf: "center",
    width: 30,
    height: 30,
  },
  text: {
    fontSize: 18,
    color: "white",
  },
  shutterButton: {
    //padding: 20,
    // backgroundColor: "blue",
    //  borderRadius: 50,
    width: 70,
    height: 70,

    alignSelf: "center",
  },
  mediaLibrary: {
    position: "absolute",
    // backgroundColor: "yellow",
    right: 20,
    //flex: 0.1,
    // alignSelf: "flex-end",
    alignSelf: "center",
    width: 30,
    height: 30,
  },
  shutterButtonImg: {
    width: "100%",
    height: "100%",
  },
  promptText: {
    ...typography.p1,
  },
});
