import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React, { useEffect } from "react";
import { StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import BottomTabBar from "./bottom-tab";
import { UserParamsI } from "../types/common/UserParamsI";
import { quiz } from "../helpers/quiz";
import { useAuth, useUserStore } from "../hooks";
import QuizScreen, {
  QuizProps,
  mapQuizStateTouserParams,
} from "../screens/quiz-screen/QuizScreen";
import SplashScreen from "../screens/splash-screen/SplashScreen";
import { RootState } from "../store";
import FavoritesStackNavigator from "./FavoritesStackNavigator";
import HomeStackNavigator from "./HomeStackNavigator";
import ProfileStackNavigator from "./ProfileStackNavigator";
import SettingsStackNavigator from "./SettingsStackNavigator";
import NavigationParamList from "./NavigationParamList";
import WardrobeScreen from "../screens/wardrobe-screen/WardrobeScreen";
import FirebaseService from "../helpers/FirebaseService";
import WardrobeStackNavigator from "./WardrobeStackNavigator";

const Stack = createNativeStackNavigator<NavigationParamList>();

const Tab = createBottomTabNavigator<NavigationParamList>();

const AuthenticatedTabNavigator = () => {
  const { user, changeUserParam } = useUserStore();
  const quizState = useSelector((state: RootState) => state.quiz);
  const { signOut } = useAuth();
  const handleOnFinishQuiz = (userParams: Partial<UserParamsI>) => {
    changeUserParam(userParams);
  };
  useEffect(() => {
    if (quizState.isQuizTaken && user && !user.params.isSetup) {
      const newParams = mapQuizStateTouserParams(quizState);
      changeUserParam(newParams);
    }
  }, [user]);

  useEffect(() => {
    if (user && user.params.isSetup && user.params.gender && user.params.age) {
      FirebaseService.setUserProperties(
        user.params.gender,
        user.params.age,
        user.id
      );
    }
  }, [user]);

  if (!user) return <SplashScreen />;

  if (user.params.isSetup) {
    return (
      <Tab.Navigator
        tabBar={BottomTabBar}
        screenOptions={({ route }) => ({
          tabBarOptions: {
            showLabel: false,
          },
          showLabel: false,
          headerShown: false,
        })}
      >
        <Tab.Screen name="Main" component={HomeStackNavigator} />
        <Tab.Screen name="Wardrobe" component={WardrobeStackNavigator} />
        <Tab.Screen name="Favorites" component={FavoritesStackNavigator} />

        <Tab.Screen name="Settings" component={SettingsStackNavigator} />
      </Tab.Navigator>
    );
  }

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="QuizScreen"
        component={QuizScreen}
        initialParams={{
          quiz: quiz,
          isWelcomeScreen: true,
          onFinish: handleOnFinishQuiz,
          exitText: "Log Out",
          onExit: () => {
            console.log("signing out");
            signOut();
          },
        }}
      />
    </Stack.Navigator>
  );
};

export default AuthenticatedTabNavigator;

const styles = StyleSheet.create({});
