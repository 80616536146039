import React from "react";
import {
  Image,
  ImageSourcePropType,
  Pressable,
  StyleSheet,
  Text,
} from "react-native";
import colors from "../../theme/colors";
import { theme } from "../../theme/defaultTheme";
import { typography } from "../../theme";

export type Props = {
  onPress: () => void;
  icon: ImageSourcePropType;
  text: string;
  style?: any;
};

const AuthButton = ({ onPress, icon, text, style }: Props) => {
  return (
    <Pressable style={[styles.container, style]} onPress={onPress}>
      <Image source={icon} style={styles.icon} />
      <Text style={styles.text}>{text}</Text>
    </Pressable>
  );
};

export default AuthButton;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.plask,
    paddingHorizontal: 0,
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 4,
    justifyContent: "center",
    borderWidth: 1,
    borderColor: colors.plask,
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  text: {
    ...typography.p2,
    overflow: "visible",
  },
});
