import { env } from "../../env";

const getInternalImgUrl = (key: string): string => {
  return `${env.BASE_URL}imgs/?name=${key}`;
};

export const getImgUrl = (image: string): string => {
  return image?.includes("http") || image?.includes("//lp2")
    ? image
    : getInternalImgUrl(image);
};

export default getInternalImgUrl;
