import { Text, View } from "native-base";
import { useEffect, useState } from "react";
import { Image, ScrollView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import GalleryPicker from "../../components/gallery-picker/GalleryPicker";
import ProductList from "../../components/product-list";
import { randomInteger } from "../../helpers/RandomInteger";
import { getImage } from "../../helpers/resourceProvider";
import { useUserStore } from "../../hooks";
import { RootState } from "../../store";
import {
  useDeleteWardrobeProductMutation,
  usePostWardrobeProductMutation,
} from "../../store/api";
import { setLookItem } from "../../store/reducers/lookItemsReducer";
import { colors, typography } from "../../theme";
import { LookItemI } from "../../types/common/LookI";
import { SearchBar } from "../search-bar";
import { logAddWardrobe } from "../../helpers/analytics";

export const AddNewItemScreen = () => {
  const { user } = useUserStore();
  const [photo, setPhoto] = useState<undefined | string>();
  const [searchText, setSearchText] = useState<string>("");
  const [lookItemId, setLookItemId] = useState<number | undefined>();
  const lookItem = useSelector(
    (state: RootState) => lookItemId && state.lookItems.state[lookItemId]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const lookItemId = randomInteger(0, Number.MAX_SAFE_INTEGER);
    const lookItem: LookItemI = {
      description: "",
      filters: {},
      id: lookItemId,
      gender: user?.params.gender,
    };
    dispatch(setLookItem({ id: lookItemId, lookItem: lookItem }));
    setLookItemId(lookItemId);
  }, []);

  const [addWardrobeProduct, result] = usePostWardrobeProductMutation();
  const [deleteWardrobeProduct, resultDelete] =
    useDeleteWardrobeProductMutation();
  if (!user || !lookItem) return null;
  return (
    <ScrollView style={{ backgroundColor: colors.bg }}>
      <View style={{ height: 20 }} />
      <View
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {result.isLoading ? (
          <Image
            source={getImage("loadingIndicator")}
            style={{ width: 20, height: 20 }}
          />
        ) : (
          <GalleryPicker
            element={
              <View
                px={2}
                py={2}
                backgroundColor={colors.plask}
                borderRadius={8}
                width={"100%"}
              >
                <Text
                  color={colors.dark}
                  textAlign="center"
                  style={typography.button}
                >
                  📷 Upload a photo
                </Text>
              </View>
            }
            style={{ width: "100%", height: "100%" }}
            onLoad={(photo) => {
              setPhoto(photo);
              console.log(photo);
              logAddWardrobe({ isByImg: true });
              addWardrobeProduct({ imgBase64: photo });
            }}
          />
        )}
      </View>
      <Text style={typography.p1} alignSelf={"center"} my={2}>
        or
      </Text>

      <SearchBar
        placeholder="Add product from stores"
        value={searchText}
        onInputChange={(text) => {
          setSearchText(text);
        }}
        onPress={() => {
          dispatch(
            setLookItem({
              id: lookItemId,
              lookItem: {
                ...lookItem,
                description: searchText,
              },
            })
          );
        }}
      />
      {/* <View
       style={{
         flexDirection: "row",
         justifyContent: "space-between",
         alignItems: "center",
         borderRadius: 32,
         paddingHorizontal: 10,
         backgroundColor: colors.lightgray,
         alignSelf: "stretch",
       }}
      >
        <Image
          source={getImage("logo")}
          style={{ width: 15, height: 15, borderRadius: 0 }}
          resizeMode="contain"
        />
        <TextInput
          style={[
            {
              flex: 1,
              padding: 10,
              paddingTop: 10,
              ...typography.p1,
            },
            Platform.OS == "web" && { outline: "none" },
          ]}
          onChangeText={(text) => {
            setSearchText(text);
          }}
          value={searchText}
          placeholder="Add product from stores"
        />

        <Pressable
          onPress={() => {
            dispatch(
              setLookItem({
                id: lookItemId,
                lookItem: {
                  ...lookItem,
                  description: searchText,
                },
              })
            );
          }}
          style={{ padding: 10 }}
          hitSlop={{ top: 20, left: 20, bottom: 20, right: 20 }}
        >
          <Image
            source={
              searchText.length == 0
                ? getImage("sendUnfilled")
                : getImage("sendFilled")
            }
            style={{ width: 15, height: 15, borderRadius: 0 }}
            resizeMode="contain"
          />
        </Pressable>
      </View> */}
      {lookItem.description?.length > 0 && (
        <View style={{ width: "100%" }}>
          <ProductList lookItem={lookItem} direction="vertical" />
        </View>
      )}
    </ScrollView>
  );
};
