import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import AuthButton from "../../components/buttons/AuthButton";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import InputEmail from "../../components/input-email/InputEmail";
import InputPassword from "../../components/input-password/InputPassword";
import { getImage } from "../../helpers/resourceProvider";
import { useAppStatus, useAuth } from "../../hooks";
import { typography } from "../../theme";
import colors from "../../theme/colors";
import containers from "../../theme/containers";
import { theme } from "../../theme/defaultTheme";
import NavigationParamList from "../../navigators/NavigationParamList";
import { openBrowserAsync } from "expo-web-browser";

function SignUpScreen() {
  const {
    email,
    setEmail,
    password,
    setPassword,
    handleCreateUserWithEmailAndPassword,
    handleGoogleLogin,
    handleFacebookLogin,
  } = useAuth();
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const { showMessage } = useAppStatus();

  return (
    <View style={styles.container}>
      <Image source={getImage("loginScreen")} style={styles.image} />
      <View style={styles.welcomeTextContainer}>
        <Text style={styles.h1}>Welcome!</Text>
      </View>
      <InputEmail email={email} setEmail={setEmail} />
      <InputPassword password={password} setPassword={setPassword} />
      <View style={styles.policiesContainer}>
        <BouncyCheckbox
          style={{ marginRight: 0, marginLeft: 5 }}
          size={18}
          fillColor={colors.blue}
          unfillColor={colors.white}
          isChecked={isConsentChecked}
          iconStyle={{ borderColor: colors.blue, borderRadius: 2 }}
          innerIconStyle={{ borderWidth: 1, borderRadius: 2 }}
          onPress={() => setIsConsentChecked(!isConsentChecked)}
        />
        <View>
          <Text style={styles.policiesTextOuter}>
            By signing up you agree to our{" "}
            <Text
              style={styles.policiesTextInner}
              onPress={() => openBrowserAsync("https://stylee.app/tos.html")}
            >
              Terms of Service
            </Text>
          </Text>
          <Text style={styles.policiesTextOuter}>
            View our{" "}
            <Text
              style={styles.policiesTextInner}
              onPress={() =>
                openBrowserAsync("https://stylee.app/privacy-policy.html")
              }
            >
              Privacy Policy
            </Text>
          </Text>
        </View>
      </View>

      <PrimaryButton
        style={{ marginBottom: 15 }}
        text={"Sign Up"}
        onPress={() => {
          if (isConsentChecked) handleCreateUserWithEmailAndPassword();
          else showMessage("Please agree to our policies");
        }}
      />

      <Text style={styles.orDeviderText}>Or</Text>
      <View style={styles.socialSignInContainer}>
        <AuthButton
          onPress={handleGoogleLogin}
          text={"Google"}
          icon={getImage("googleLogo")}
          style={{ width: "45%" }}
        />
        <AuthButton
          onPress={handleFacebookLogin}
          text={"Facebook"}
          icon={getImage("facebookLogo")}
          style={{ width: "45%" }}
        />
      </View>
      <Text style={styles.dontHaveAccountText}>
        Already have an account?{" "}
        <Text
          style={styles.signupText}
          onPress={() => {
            navigation.navigate("LoginScreen");
          }}
        >
          Sign in
        </Text>
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  image: {
    marginTop: 0,
    resizeMode: "contain",
    width: 323,
    height: 250,
    alignSelf: "center",
  },
  dontHaveAccountText: {
    ...typography.p1,
    alignSelf: "center",
  },
  signupText: {
    fontFamily: theme.fontSecondarySemibold,
  },
  socialSignInContainer: {
    alignSelf: "stretch",
    height: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  orDeviderText: {
    ...typography.p3,
    color: colors.gray,
    marginBottom: 15,
    alignSelf: "center",
  },
  policiesTextInner: { textDecorationLine: "underline" },
  policiesTextOuter: {
    ...typography.p3,
    color: colors.gray,
    alignSelf: "flex-start",
    marginBottom: 0,
  },
  policiesContainer: {
    marginBottom: 35,
    marginTop: 15,
    alignSelf: "stretch",
    flexDirection: "row",
  },
  p1: { ...typography.p1, textAlign: "center" },
  h1: {
    ...typography.h1,
    textAlign: "center",
    marginBottom: 10,
  },
  welcomeTextContainer: {
    alignSelf: "stretch",
    marginBottom: 15,
  },
  container: {
    ...containers.screenContainer,
    minHeight: 550,
    justifyContent: "center",
  },
});
export default SignUpScreen;
