import { useEffect, useState } from "react";
import {
  FlatList,
  Image,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { useDispatch } from "react-redux";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";
import { useGlobalFiltersStore } from "../../hooks";
import useUserStore from "../../hooks/useUserStore";
import { api, useGetProductsQuery } from "../../store/api";
import { typography } from "../../theme";
import { FiltersI } from "../../types/common/FiltersI";
import ProductI from "../../types/common/ProductI";
import { ProductDescriptorI } from "../../types/common/productDescriptorI";
import ProductCard from "../product-card/ProductCard";
import { getImage } from "../../helpers/resourceProvider";
import LikeButton from "../product-card/likeButton";
import { useNavigation, NavigationProp } from "@react-navigation/native";
import NavigationParamList from "../../navigators/NavigationParamList";
import { ProductCardV2 } from "../product-card/ProductCardV2";

const numberOfItemsToLoad = 10;

export type Props = {
  productDescriptor: ProductDescriptorI;
  filters: FiltersI;
  limit?: number;
  products?: ProductI[];
  direction?: "horizontal" | "vertical";
  style?: any;
};

const ProductListInCard = ({
  productDescriptor,
  limit = numberOfItemsToLoad,
  products,
  direction = "horizontal",
  style,
  filters,
}: Props) => {
  const dispatch = useDispatch();
  const [isProductNeeded, setIsProductNeeded] = useState(products == undefined);
  const { user } = useUserStore();
  const [limitLocal, setLimitLocal] = useState(limit);
  const { globalFilters } = useGlobalFiltersStore();
  const [productsData, setProductsData] = useState<ProductI[] | undefined>(
    products
  );
  const [productIndx, setProductIndx] = useState<number>(0);

  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const {
    data: getPruductsData,
    isFetching,
    error,
    isLoading,
    refetch,
  } = useGetProductsQuery(
    {
      productDescriptor: productDescriptor,
      filters: {
        ...globalFilters.chosenValues,
        ...filters,
      } as unknown as FiltersI,
      offset: 0,
      limit: limitLocal,
      // userParams: user?.params,
    },
    {
      skip:
        user == undefined ||
        globalFilters.isLoading ||
        globalFilters.chosenValues == undefined ||
        !isProductNeeded,
    }
  );

  useEffect(() => {
    dispatch(
      api.util.invalidateTags([{ type: "Product", id: productDescriptor.id }])
    );
    setLimitLocal(limit);
  }, [globalFilters.chosenValues]);

  useEffect(() => {
    setProductsData(products);
  }, [products]);

  useEffect(() => {
    if (getPruductsData) {
      setProductsData(getPruductsData.products);
    }
  }, [getPruductsData]);

  const handleLoadMoreResults = () => {
    if (!isFetching) {
      setLimitLocal(limitLocal + limit);
      if (!isProductNeeded) setIsProductNeeded(true);
      // setProductsData(undefined);
    }
  };

  if (
    !productsData ||
    globalFilters.isLoading ||
    (isFetching && limitLocal == numberOfItemsToLoad)
  )
    return renderLoading();
  return (
    <View style={style}>
      {productsData.length > 0 ? (
        <View
          key={productsData[productIndx].id}
          style={{
            width: "100%",
            margin: "0%",
            borderRadius: 10,
            overflow: "hidden",
            marginBottom: 10,
          }}
        >
          <ProductCardV2 product={productsData[productIndx]} />
          {/* <Pressable
            onPress={() =>
              navigation.navigate("Product", {
                product: productsData[productIndx],
              })
            }
          >
            <Image
              source={{
                uri: productsData[productIndx].image[0],
              }}
              style={{ width: "100%", height: 150 }}
              resizeMode="cover"
            />
            
          </Pressable> */}
          {/* <LikeButton
            product={productsData[productIndx]}
            style={{ position: "absolute", top: 10, right: 10 }}
          /> */}
          <Pressable
            onPress={() => {
              const newIndx = (productIndx + 1) % productsData.length;
              setProductIndx(newIndx);
            }}
            style={{
              height: 20,
              width: 20,
              position: "absolute",
              top: 10,
              left: 10,
            }}
            hitSlop={{ top: 20, left: 20, bottom: 20, right: 20 }}
          >
            <Image
              source={getImage("shuffle")}
              style={{ width: "100%", height: "100%" }}
            />
          </Pressable>
        </View>
      ) : (
        renderEmptyList(productDescriptor.description)
      )}
    </View>
  );
};

export default ProductListInCard;

const renderEmptyList = (description: string) => {
  return (
    <View
      style={{
        height: 200,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text style={typography.p1}>{description}</Text>
    </View>
  );
};

const renderLoading = () => {
  const data = [{}];
  const renderPlaceholder = () => {
    return (
      <View style={styles.placeholder}>
        <View style={styles.placeholderImage}>
          <Placeholder Animation={Fade}>
            <PlaceholderMedia style={styles.placeholderImage} />
          </Placeholder>
        </View>
        <Placeholder Animation={Fade}>
          <PlaceholderLine noMargin={true} style={styles.textPlaceholder} />
          <PlaceholderLine noMargin={true} style={styles.textPlaceholder} />
        </Placeholder>
      </View>
    );
  };
  return renderPlaceholder();
};

const styles = StyleSheet.create({
  list: {
    marginTop: 20,
  },
  priceSlider: {
    height: 50,
    backgroundColor: "red",
  },
  placeholder: {
    width: 170,
    height: 200,
    flexDirection: "column",
    marginHorizontal: 5,
    alignItems: "center",
  },
  placeholderImage: {
    width: "100%",
    height: 140,
    borderRadius: 5,
  },
  textPlaceholder: {
    marginTop: 5,
    height: 20,
  },
});
