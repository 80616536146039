import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserInfoStateI {
  isPremium: boolean;
}

const initialState: UserInfoStateI = {
  isPremium: false,
};

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: initialState,
  reducers: {
    setIsPremium: (state, action: PayloadAction<{ value: boolean }>) => {
      state.isPremium = action.payload.value;
    },
  },
});

export const { setIsPremium } = userInfoSlice.actions;
export default userInfoSlice.reducer;
