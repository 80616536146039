import React, { useState } from "react";
import { Animated, Pressable, StyleSheet, Text } from "react-native";
import { typography } from "../../theme";
import colors from "../../theme/colors";

export type Props = {
  text: string;
  onPress: () => void;
  style?: any;
};

const PrimaryButton = ({ text, onPress, style }: Props) => {
  return (
    <Pressable onPress={onPress} style={[styles.container, style]}>
      <Text style={styles.text}>{text}</Text>
    </Pressable>
  );
};

export default PrimaryButton;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: colors.blue,
    borderWidth: 2,
    borderColor: colors.blue,
    borderRadius: 8,
    padding: 12,
  },
  text: {
    ...typography.button,
    color: colors.white,
    textAlign: "center",
  },
});
