import { Unit } from "./Unit";
import { UserParamsI } from "./common/UserParamsI";
export interface QuestionI {
  result: keyof UserParamsI | "photo";
  type: QuestionType;
  question: string;
  chosenAnswer: UserParamsI[keyof UserParamsI] | undefined;
  validation: (answer: UserParamsI[keyof UserParamsI]) => ValidationResponseI;
}

export interface ValidationResponseI {
  isValid: boolean;
  message?: string;
}

export enum QuestionType {
  NUMBER,
  MULTIPLE,
  TEXT,
  PHOTO,
  COLOR_PICKER,
}

export interface MultipleQuestionI extends QuestionI {
  numberOfOptions: number;
  option: MultipleQuestionOptionI[];
}

export interface TextQuestionI extends QuestionI {
  chosenAnswer: string;
}

export interface NumberQuestionI extends QuestionI {
  unitsToDisplay: Unit[];
  minValue: number;
  maxValue: number;
}
export interface MultipleQuestionOptionI {
  caption: string;
  value: UserParamsI[keyof UserParamsI];
  image: string;
  paragraph?: string;
  element?: JSX.Element;
}

export interface PhotoQuestionI extends QuestionI {
}

export interface ColorPickerQuestionI extends QuestionI {
  colors: string[];
}
