import { AppRegistry, Linking, LogBox, Platform } from "react-native";
import { RootSiblingParent } from "react-native-root-siblings";
import { Provider } from "react-redux";

import { store } from "./src/store/index";
import { AuthenticationStackNavigator } from "./src/navigators";
import { registerRootComponent } from "expo";
import { useEffect } from "react";
import { LookCardV2 } from "./src/components/look-card-v2/LookCardV2";
import { LookI } from "./src/types/common/LookI";
import { Gender } from "./src/types/common/Gender";
import { FiltersI } from "./src/types/common/FiltersI";
import { Category } from "./src/types/common/ProductI";

import { NativeBaseProvider, Box } from "native-base";

LogBox.ignoreLogs([/AsyncStorage has been extracted from react-native core/]);
LogBox.ignoreAllLogs(true);

AppRegistry.registerComponent("main", () => App);
registerRootComponent(App);
const IS_PULL_DOWN_REFRESH = false;

function App() {
  useEffect(() => {
    if (Platform.OS === "web" && !IS_PULL_DOWN_REFRESH) {
      let lastTouchY = 0;
      const buffer = 10; // Adjust this buffer size as needed

      function touchStartHandler(evt: TouchEvent) {
        lastTouchY = evt.touches[0].clientY;
      }

      function touchMoveHandler(evt: any) {
        let isScrollingUp = evt.touches[0].clientY > lastTouchY;
        let isScrollingDown = evt.touches[0].clientY < lastTouchY;
        let target = evt.target;
        while (target && target !== document.body) {
          let style = window.getComputedStyle(target);
          let overflowY = style.overflowY;
          let canScroll = overflowY === "auto" || overflowY === "scroll";
          let maxScrollY = target.scrollHeight - target.clientHeight;
          if (canScroll) {
            if (isScrollingUp && target.scrollTop > 0) return;
            if (isScrollingDown && target.scrollTop < maxScrollY) return;
          }
          target = target.parentNode;
        }
        evt.preventDefault();
        lastTouchY = evt.touches[0].clientY;
      }

      // only add the event listeners if we're in a browser
      if (typeof window !== "undefined") {
        document.body.addEventListener("touchstart", touchStartHandler, {
          passive: false,
        });
        document.body.addEventListener("touchmove", touchMoveHandler, {
          passive: false,
        });
      }

      // specify how to clean up after this effect
      return function cleanup() {
        if (typeof window !== "undefined") {
          document.body.removeEventListener("touchstart", touchStartHandler);
          document.body.removeEventListener("touchmove", touchMoveHandler);
        }
      };
    }
  }, []);

  return (
    <RootSiblingParent>
      <NativeBaseProvider>
        <Provider store={store}>
          <AuthenticationStackNavigator />
        </Provider>
      </NativeBaseProvider>
    </RootSiblingParent>
  );
}

export default App;
