import { NavigationProp, useNavigation } from "@react-navigation/native";
import { FlatList, ScrollView, StyleSheet } from "react-native";
import LookByIdCard from "../../components/look-card/LookByIdCard";
import ProductByIdCard from "../../components/product-card/ProductByIdCard";

import { useLookStore } from "../../hooks";
import useUserStore from "../../hooks/useUserStore";
import { spacing, typography } from "../../theme";
import containers from "../../theme/containers";
import SplashScreen from "../splash-screen/SplashScreen";
import NavigationParamList from "../../navigators/NavigationParamList";
import { Pressable, Row, Text, View } from "native-base";
import { LookI } from "../../types/common/LookI";
import { LookCardV2 } from "../../components/look-card-v2/LookCardV2";
import { LookCardV2InLine } from "../../components/look-card-v2/LookCarV2InLine";

const FavoritesScreen = () => {
  const { user } = useUserStore();
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  let favoriteProducts = user?.favorites.products;
  const favoriteLooks = user?.favorites.looks;
  if (!favoriteProducts || !favoriteLooks) return <SplashScreen />;

  return (
    <View style={styles.container}>
      <ScrollView>
        <Row justifyContent="space-between" alignItems={"center"} mb={2}>
          <Text style={typography.h2}>Favorite Products</Text>
          <Pressable onPress={() => navigation.navigate("FavoriteProducts")}>
            <Text alignSelf={"center"} style={typography.p2}>
              See all →{" "}
            </Text>
          </Pressable>
        </Row>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {favoriteProducts.length > 0 ? (
            favoriteProducts
              .slice(0, 4)
              .map((productId) => (
                <ProductByIdCard
                  id={productId}
                  style={{ width: "45%" }}
                  key={productId.toString()}
                />
              ))
          ) : (
            <View style={{ height: 200 }}>
              <Text style={{ ...typography.p1, alignSelf: "center" }}>
                You don't have any favorite products yet
              </Text>
            </View>
          )}
        </View>
        <Text style={styles.h2}>Favorite Looks</Text>
        {favoriteLooks.length > 0 ? (
          <FlatList
            data={favoriteLooks}
            renderItem={({ item }) => (
              <LookByIdCard
                lookId={item}
                element={(look: LookI) => (
                  <View>
                    <View mb={2}>
                      <Row
                        justifyContent="space-between"
                        alignItems={"center"}
                        mb={1}
                      >
                        <Text style={typography.p2Dark}></Text>
                        <Pressable
                          onPress={() =>
                            navigation.navigate("Look", { look: look })
                          }
                        >
                          <Text alignSelf={"center"} style={typography.p2}>
                            Edit →{" "}
                          </Text>
                        </Pressable>
                      </Row>
                      {/* <LookCardV2 look={look} /> */}
                      <LookCardV2InLine look={look} />
                    </View>
                  </View>
                )}
              />
            )}
            keyExtractor={(item) => item.toString()}
            horizontal={false}
          />
        ) : (
          <View style={{ height: 200 }}>
            <Text style={{ ...typography.p1, alignSelf: "center" }}>
              You don't have any favorite looks yet
            </Text>
          </View>
        )}
      </ScrollView>
    </View>
  );
};

export default FavoritesScreen;

const styles = StyleSheet.create({
  container: { ...containers.screenContainer },
  h: { ...typography.h1, marginBottom: 20, alignSelf: "center" },
  h2: {
    ...typography.h2,
    marginBottom: 16,
  },
});
