import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { useSelector } from "react-redux";
import { theme } from "../../theme/defaultTheme";
import colors from "../../theme/colors";
import { RootState } from "../../store";
import { spacing, typography } from "../../theme";

const StatusMessage = () => {
  const message = useSelector((state: RootState) => state.appStatus.message);
  const isShowing = useSelector(
    (state: RootState) => state.appStatus.isShowing
  );

  return (
    <View style={[styles.container, !isShowing && { width: 0, height: 0 }]}>
      <Text style={styles.message}>{message}</Text>
    </View>
  );
};

export default StatusMessage;

const styles = StyleSheet.create({
  container: {
    width: "40%",
    alignSelf: "center",
    opacity: 0.8,
    position: "absolute",
    bottom: 110,
    backgroundColor: colors.bg,

    // borderRadius: spacing.s,
  },
  message: {
    ...typography.p2,
    fontFamily: theme.fontSecondarySemibold,
    opacity: 1,
    textAlign: "center",
  },
});
