import { env } from "../../env";
import FirebaseService from "./FirebaseService";
const isDev = env.ENV == "development";

export type analyticsEvent =
  | "screen_view"
  | "login"
  | "logout"
  | "quiz"
  | "pay_intent"
  | "swipe_look"
  | "photo_uploaded"
  | "signup"
  | "exception"
  | "onboarding"
  | "go_partner"
  | "try_on"
  | "generate_look"
  | "add_wardrobe";

export const logEvent = (eventName: analyticsEvent, params?: any) => {
  if (isDev) {
    console.log("logEvent", eventName, params);
  } else {
    FirebaseService.logEvent(eventName as string, params);
  }
};

export const logGoPartner = async (params: any) => {
  logEvent("go_partner", {
    params: JSON.stringify(params),
  });
};

export const logAddWardrobe = async (params: any) => {
  logEvent("add_wardrobe", {
    params: JSON.stringify(params),
  });
};

export const logGenerateLook = async (params: any) => {
  logEvent("generate_look", {
    params: JSON.stringify(params),
  });
};
export const logTryOn = async (params: any) => {
  logEvent("try_on", {
    params: JSON.stringify(params),
  });
};

export const logScreenView = async (screenName: string, params: string) => {
  logEvent("screen_view", {
    screen_name: screenName,
    screen_class: screenName,
    params: params,
  });
};

export const logOnboarding = async (params: any) => {
  logEvent("onboarding", {
    params: JSON.stringify(params),
  });
};

export const logError = (error: any) => {
  logEvent("exception", { description: error });
};

export const logPayIntent = (period: "yearly" | "monthly", context: string) => {
  logEvent("pay_intent", {
    params: JSON.stringify({ period: period, context: context }),
  });
};

export const logLogin = (provider: string) => {
  logEvent("login", { params: provider });
};

export const logLogout = () => {
  logEvent("logout");
};

export const logSingUp = (provider: string) => {
  logEvent("signup", { params: provider });
};

export const logQuiz = (quizId: string, score: any) => {
  logEvent("quiz", { quizId, score });
};

export const logSwipeLook = (lookId: string, direction: "left" | "right") => {
  logEvent("swipe_look", { lookId, direction });
};
