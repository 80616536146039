import React from "react";
import { ScrollView, StyleSheet } from "react-native";
import SubscriptionsComponent from "../../components/subscriptions-component/SubscriptionsComponent";
import colors from "../../theme/colors";

const CapsuleWardobeScreen = () => {
  return (
    <ScrollView style={styles.scrollView}>
      <SubscriptionsComponent context={"CapsuleWardobeScreen"} />
    </ScrollView>
  );
};

export default CapsuleWardobeScreen;

const styles = StyleSheet.create({
  scrollView: { flex: 1, paddingTop: 20, backgroundColor: colors.bg },
});
