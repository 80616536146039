import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import NavigationParamList from "../../navigators/NavigationParamList";
import { Column, Pressable, Row, ScrollView, View } from "native-base";
import { colors } from "../../theme";
import { SecondaryButton } from "../../components/buttons";
import { LookCardV2 } from "../../components/look-card-v2/LookCardV2";
import PremiumLabel from "../../components/premium-label";
import { useState, useEffect } from "react";
import { env } from "../../../env";
import { useUserStore } from "../../hooks";

export const LookScreen = () => {
  const navigation = useNavigation<NavigationParamList>();
  const route = useRoute<RouteProp<NavigationParamList, "Look">>();
  const { look } = route.params;

  return (
    <ScrollView style={{ backgroundColor: colors.bg, height: "100%" }}>
      <View>
        <Row
          justifyContent={"space-around"}
          mt={2}
          mb={2}
          alignItems={"center"}
        ></Row>

        <LookCardV2 look={look} style={{ width: "100%" }} />
        <Column width={"100%"} mt={2}></Column>
      </View>
    </ScrollView>
  );
};
