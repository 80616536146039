import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { useUserStore } from "../hooks";
import usePayments from "../hooks/usePayments";
import CapsuleWardobeScreen from "../screens/capsule-wardrobe-screen/CapsuleWardrobeScreen";
import ChatScreen from "../screens/chat-screen";
import DeckSwiperScreen from "../screens/deck-swiper-screen/DeckSwiperScreen";
import GenerateLookScreen from "../screens/generate-look-screen/GenerateLookScreen";
import HomeScreen from "../screens/home-screen/HomeScreen";
import InProgressScreen from "../screens/in-progress-screen/InProgressScreen";
import LookbookScreen from "../screens/lookbook-screen/LookbookScreen";
import ProductScreen from "../screens/product-screen";
import QuizScreen from "../screens/quiz-screen/QuizScreen";
import ShopLookScreen from "../screens/shop-look-screen/ShopLookScreen";
import StealLookScreen from "../screens/steal-look-screen/StealLookScreen";
import StyleGuideScreen from "../screens/style-guide-screen/StyleGuideScreen";
import HeaderAuthenticated from "./HeaderAuthenticated";
import NavigationParamList from "./NavigationParamList";
import { TryOnScreen } from "../screens/try-on-screen";
import { LookScreen } from "../screens/look-screen/LookScreen";
import { ChangeProductScreen } from "../screens/change-product-screen";

const Stack = createNativeStackNavigator<NavigationParamList>();

const HomeStackNavigator = () => {
  const { user } = useUserStore();
  const { isPremium } = usePayments(user?.id);
  return (
    <Stack.Navigator
      screenOptions={{
        header: (props) => HeaderAuthenticated(props, user, isPremium),
        headerShown: true,
      }}
    >
      <Stack.Screen name="HomeScreen" component={HomeScreen} />
      <Stack.Screen name="LookBook" component={LookbookScreen} />
      <Stack.Screen name="ShopLook" component={ShopLookScreen} />
      <Stack.Screen name="StealLook" component={StealLookScreen} />
      <Stack.Screen name="CapsuleWardrobe" component={CapsuleWardobeScreen} />
      <Stack.Screen name="InProgress" component={InProgressScreen} />
      <Stack.Screen name="LookBookSwiper" component={DeckSwiperScreen} />
      <Stack.Screen name="QuizScreen" component={QuizScreen} />
      <Stack.Screen name="StyleGuide" component={StyleGuideScreen} />
      <Stack.Screen name="GenerateLook" component={GenerateLookScreen} />
      <Stack.Screen name="Chat" component={ChatScreen} />
      <Stack.Screen name="Product" component={ProductScreen} />
      <Stack.Screen name="GeneratedLook" component={GenerateLookScreen} />
      <Stack.Screen name="TryOn" component={TryOnScreen} />
      <Stack.Screen name="Look" component={LookScreen} />
      <Stack.Screen name="ChangeProduct" component={ChangeProductScreen} />
    </Stack.Navigator>
  );
};

export default HomeStackNavigator;
