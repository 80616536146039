import React, { useState } from "react";
import { StyleSheet, Text, TextInput, View } from "react-native";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import useAppStatus from "../../hooks/useAppStatus";
import { spacing, typography } from "../../theme";
import colors from "../../theme/colors";
import containers from "../../theme/containers";

const FeedbackScreen = () => {
  const [feedbackText, setFeedbackText] = useState("");
  const { showMessage } = useAppStatus();

  return (
    <View style={styles.container}>
      <Text style={styles.h}>Share your thoughts</Text>
      <Text style={styles.p}>
        Your feedback will help us make Stylee better
      </Text>
      <TextInput
        value={feedbackText}
        multiline={true}
        numberOfLines={10}
        style={styles.textInput}
        onChangeText={(text) => {
          setFeedbackText(text);
        }}
      />
      <PrimaryButton
        text="Submit"
        onPress={() => {
          setFeedbackText("");
          showMessage("Tanks for your feedback");
        }}
      />
    </View>
  );
};

export default FeedbackScreen;

const styles = StyleSheet.create({
  container: { ...containers.screenContainer },

  textInput: {
    marginBottom: 30,
    borderWidth: 1,
    borderColor: colors.gray,
    padding: 10,
    borderRadius: spacing.m,
  },
  p: {
    ...typography.p1,
    marginBottom: 20,
  },
  h: {
    ...typography.h2,

    marginBottom: 30,
  },
});
