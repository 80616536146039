import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { Modal, Pressable, Row, Text, View } from "native-base";
import React, { useEffect, useState } from "react";
import {
  Image,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../../components/buttons";
import FiltersComponent from "../../components/filters-page/FiltersComponent";
import GalleryPicker from "../../components/gallery-picker/GalleryPicker";
import Loader from "../../components/loader";
import { getImage } from "../../helpers/resourceProvider";
import { useAppStatus, useGlobalFiltersStore } from "../../hooks";
import { useModal } from "../../hooks/useModal";
import useUserStore from "../../hooks/useUserStore";
import NavigationParamList from "../../navigators/NavigationParamList";
import { RootState } from "../../store";
import { useGenerateLookMutation } from "../../store/api";
import { setLookItem } from "../../store/reducers/lookItemsReducer";
import { setLook } from "../../store/reducers/lookReducer";
import { typography } from "../../theme";
import colors from "../../theme/colors";
import containers from "../../theme/containers";
import { Gender } from "../../types/common/Gender";
import { LookItemI } from "../../types/common/LookI";
import { SearchBar } from "../search-bar";
import { logGenerateLook } from "../../helpers/analytics";

const prompts = [
  {
    name: "🔥 girls night out",
    prompt: "generate a look for a night out",
    gender: Gender.FEMALE,
  },
  {
    name: "🏖️ boho beach day",
    prompt: "generate a look for a beach day",
    gender: Gender.FEMALE,
  },
  {
    name: "📚 first day at school cool girl outfit",
    prompt: "generate a look for a first day at school",
    gender: Gender.FEMALE,
  },
  {
    name: "💃🏽 Coachella",
    prompt: "generate a  Coachella look",
    gender: Gender.FEMALE,
  },
  {
    name: "🏖️ boho beach day",
    prompt: "generate a look for a beach day",
    gender: Gender.FEMALE,
  },
  {
    name: "Burning Man apocalyptical outfit",
    prompt: "generate a look burning man",
    gender: Gender.FEMALE,
  },
  {
    name: "🏙️ street style",
    prompt: "generate a  street style look",
    gender: Gender.FEMALE,
  },
  {
    name: "👓 preppy",
    prompt: "generate a preppy look",
    gender: Gender.FEMALE,
  },
  {
    name: "🕺 date night",
    prompt: "generate a look for a date night",
    gender: Gender.MALE,
  },
  {
    name: "🕴️ James Bond style",
    prompt: "generate a look for a James Bond style, classy, suits, old money",
    gender: Gender.MALE,
  },
  {
    name: "💰 old money",
    prompt: "quiet luxury, old money, classy, suits",
    gender: Gender.MALE,
  },
  {
    name: "🌇 casual",
    prompt: "generate a casual look",
    gender: Gender.MALE,
  },
  {
    name: "👨‍💼 business casual",
    prompt: "generate a business casual look",
    gender: Gender.MALE,
  },
  {
    name: "👔 formal",
    prompt: "generate a formal look",
    gender: Gender.MALE,
  },
  {
    name: "🥊 sporty",
    prompt: "generate a sporty look",
    gender: Gender.MALE,
  },
  {
    name: "☕ hipster",
    prompt: "generate a hipster look",
    gender: Gender.MALE,
  },
  {
    name: "🎸 grunge",
    prompt: "generate a grunge look",
    gender: Gender.MALE,
  },
];

const GenerateLookScreen = () => {
  const renderLoading = () => {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          backgroundColor: colors.bg,
        }}
      >
        <Loader
          time={10000}
          messages={[
            "Generating looks...",
            "Generating looks...",
            "Personalizing for your type...",
            "Fetching the results...",
          ]}
          sections={10}
          textStyle={[typography.p1, { marginTop: 20 }]}
          containerStyle={{
            width: "70%",
          }}
          progressBarStyle={{
            height: 20,
          }}
          type="flow"
        />
      </View>
    );
  };
  const { showMessage } = useAppStatus();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { showModal, hideModal, modal } = useModal();
  const [rationStoreItems, setRatioStoreItems] = useState(8);

  const [preferences, setPreferences] = useState("");
  const dispatch = useDispatch();
  const [lookId, setLookId] = useState<number | undefined>();

  const look = useSelector(
    (state: RootState) => lookId && state.looks.state[lookId]
  );
  const { globalFilters, setGlobalFilters } = useGlobalFiltersStore();
  const { user } = useUserStore();
  const [lookItemLocal, setLookItemLocal] = useState<LookItemI>({
    id: 121,
    description: "",
    gender: user?.params.gender,
    filters: { source: "wardrobe" },
  });
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const route = useRoute<RouteProp<NavigationParamList, "GenerateLook">>();
  useEffect(() => {
    if (route.params?.initialPrompt) {
      generate({ preferences: route.params.initialPrompt, look: undefined });
    }
  }, [route.params?.initialPrompt]);

  const [generate, responseGenerateLook] = useGenerateLookMutation();

  const isLoading = responseGenerateLook.isLoading;

  const generateLook = (preferences: string, imgBase64?: string) => {
    logGenerateLook({ preferences: preferences, isByImg: !!imgBase64 });
    generate({
      preferences: preferences,
      look: undefined,
      filters: lookItemLocal?.filters,
      imgBase64: imgBase64,
    });
  };

  useEffect(() => {
    if (responseGenerateLook.error) {
      console.log(responseGenerateLook.error + " error ");
      showMessage("Something went wrong. Please try again later.", 3000);
      responseGenerateLook.reset();
    } else if (responseGenerateLook.data) {
      dispatch(
        setLook({
          look: responseGenerateLook.data.look,
          id: responseGenerateLook.data.look.id,
        })
      );
      setLookId(responseGenerateLook.data.look.id);

      responseGenerateLook.data.look.lookItems.forEach((lookItem) => {
        dispatch(
          setLookItem({
            lookItem: lookItem,
            id: lookItem.id,
          })
        );
      });

      navigation.navigate("Look", { look: responseGenerateLook.data.look });
    } else {
      setLookId(undefined);
    }
  }, [responseGenerateLook]);

  if (isLoading) {
    return renderLoading();
  }

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === "ios" ? "padding" : "padding"}
      keyboardVerticalOffset={Platform.OS === "ios" ? 40 : 300}
      enabled={true}
    >
      <View style={{ flex: 1 }}>
        <Modal isOpen={isModalOpen} closeOnOverlayClick={true}>
          <View padding={10} borderRadius={20} backgroundColor={colors.bg}>
            <FiltersComponent
              filtersUnit={{
                ...globalFilters,
                chosenValues: {
                  ...globalFilters?.chosenValues,
                  ...lookItemLocal?.filters,
                },
              }}
              setFiltersUnit={(filters) =>
                setLookItemLocal({ ...lookItemLocal, filters })
              }
            />
            <PrimaryButton
              text={"Apply"}
              onPress={() => {
                setIsModalOpen(false);
              }}
            />
          </View>
        </Modal>

        <SearchBar
          placeholder="What are you looking for?"
          value={preferences}
          onInputChange={setPreferences}
          onPress={() => {
            generateLook(preferences);
          }}
        />
        <Row
          justifyContent={"flex-end"}
          py={2}
          alignItems={"center"}
          backgroundColor={"red"}
        >
          <Pressable onPress={() => setIsModalOpen(true)}>
            <Image
              source={getImage("filtersIcon")}
              style={{ width: 30, height: 30 }}
            />
          </Pressable>
        </Row>
        <View
          style={{
            width: "100%",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            flexDirection: "row",
          }}
        >
          {/* <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Text style={typography.p1Dark}>Only My Wardrobe</Text>
            <Text style={typography.p1Dark}>Only Stores</Text>
          </View>
          <Slider
            style={{ width: "100%", height: 40 }}
            minimumValue={0}
            maximumValue={8}
            minimumTrackTintColor={colors.blue}
            maximumTrackTintColor={colors.plask}
            thumbTintColor={colors.blue}
            step={1}
            value={rationStoreItems}
            onValueChange={(value: number) => setRatioStoreItems(value)}
          /> */}
          {prompts
            .filter((prompt) => prompt.gender == user?.params.gender)
            .map((prompt, key) => {
              return (
                <Pressable
                  key={key}
                  onPress={() => {
                    generateLook(prompt.prompt);
                  }}
                  style={{
                    backgroundColor: colors.bg,
                    margin: 6,
                    paddingHorizontal: 18,
                    paddingVertical: 12,
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: colors.middlegray,
                  }}
                >
                  <Text
                    style={[typography.p2Dark, { textTransform: "capitalize" }]}
                  >
                    {prompt.name}
                  </Text>
                </Pressable>
              );
            })}
        </View>
      </View>

      <GalleryPicker
        element={
          <View px={2} py={2} backgroundColor={colors.plask} borderRadius={8}>
            <Text
              color={colors.dark}
              textAlign="center"
              style={typography.button}
            >
              📷 Recreate from a photo
            </Text>
          </View>
        }
        style={{ width: "100%" }}
        onLoad={(photo) => {
          generateLook("", photo);
        }}
      />
      {/* </TouchableWithoutFeedback> */}
    </KeyboardAvoidingView>
  );
};

export default GenerateLookScreen;

const styles = StyleSheet.create({
  scrollView: { flex: 1, paddingTop: 20, backgroundColor: colors.bg },

  container: {
    ...containers.screenContainer,
    justifyContent: "flex-start",
    height: "100%",
  },
  card: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    overflow: "hidden",
    shadowColor: colors.dark,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 8,
    height: "100%",
    backgroundColor: colors.bg,
  },
  centeredView: {
    flexDirection: "column",
    flex: 1,
    // justifyContent: "center",
    alignItems: "center",
    paddingTop: "20%",
    backgroundColor: "rgba(0,0,0, 0.40)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    maxWidth: 400,
    alignSelf: "center",
  },
});
