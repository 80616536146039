import { UserParamsI } from "../types/common/UserParamsI";
import { MultipleQuestionOptionI } from "../types/Quiz";

export class MultipleQuestionOption implements MultipleQuestionOptionI {
  constructor(
    public caption: string,
    public value: UserParamsI[keyof UserParamsI],
    public image: string,
    public paragraph?: string,
    public element?: JSX.Element
  ) {}
}
