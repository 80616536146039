import React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import ProductByIdCard from "../../components/product-card/ProductByIdCard";
import { useLookStore } from "../../hooks";
import useUserStore from "../../hooks/useUserStore";
import { typography } from "../../theme";
import containers from "../../theme/containers";
import SplashScreen from "../splash-screen/SplashScreen";

const FavoriteProductsScreen = () => {
  const { user } = useUserStore();

  let favoriteProducts = user?.favorites.products;
  if (!favoriteProducts) return <SplashScreen />;

  return (
    <View style={styles.container}>
      <ScrollView>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          {favoriteProducts.length > 0 ? (
            favoriteProducts.map((productId) => (
              <ProductByIdCard
                id={productId}
                key={productId.toString()}
                style={{ width: "45%" }}
              />
            ))
          ) : (
            <View style={{ height: 200 }}>
              <Text style={{ ...typography.p1, alignSelf: "center" }}>
                You don't have any favorite products yet
              </Text>
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
};

export default FavoriteProductsScreen;

const styles = StyleSheet.create({
  container: { ...containers.screenContainer },

  h: { ...typography.h1, marginBottom: 20, alignSelf: "center" },
  h2: {
    fontSize: 17,
    marginBottom: 16,
    fontWeight: "bold",
  },
});
