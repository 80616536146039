import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FiltersI } from "../../types/common/FiltersI";
import { Filters } from "../../entities/common/Filters";
import { ProductDescriptorI } from "../../types/common/productDescriptorI";

export interface filtersStateI {
  localFilterschosenValues: { [key: number]: Partial<FiltersI> | undefined };
  globalFiltersChosenValues: FiltersI | undefined;
}

const initialState = {
  localFilterschosenValues: {},
  globalFiltersChosenValues: undefined,
} as filtersStateI;

export const localFiltersChosenValuesSlice = createSlice({
  name: "productDescriptors",
  initialState: initialState,
  reducers: {
    setGlobalFiltersChosenValue: (
      state,
      action: PayloadAction<{ chosenValues: FiltersI }>
    ) => {
      state.globalFiltersChosenValues = action.payload.chosenValues;
    },
    setLocalFiltersChosenValue: (
      state,
      action: PayloadAction<{
        chosenValues: FiltersI;
        id: ProductDescriptorI["id"];
      }>
    ) => {
      state.localFilterschosenValues[action.payload.id] =
        action.payload.chosenValues;
    },
  },
});

export const { setLocalFiltersChosenValue, setGlobalFiltersChosenValue } =
  localFiltersChosenValuesSlice.actions;
export default localFiltersChosenValuesSlice.reducer;
