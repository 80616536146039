import React from "react";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { getImage } from "../../helpers/resourceProvider";
import colors from "../../theme/colors";

export type Props = {
  item: string;
  onPress: () => void;
};
const BrandOrStoreFilterCard = ({ item, onPress }: Props) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{item}</Text>
      <Pressable
        onPress={onPress}
        style={{ width: 16, height: 16, backgroundColor: "" }}
      >
        <Image
          source={getImage("xIcon")}
          style={{ width: "100%", height: "100%" }}
        />
      </Pressable>
    </View>
  );
};

export default BrandOrStoreFilterCard;

const styles = StyleSheet.create({
  text: {
    marginRight: 10,
  },
  container: {
    backgroundColor: colors.bg,
    borderRadius: 20,
    padding: 10,
    alignSelf: "flex-start",
    margin: 10,
    flexDirection: "row",
    alignItems: "center",
    borderColor: "black",
    borderWidth: 1,
  },
});
