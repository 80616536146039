export type Unit = "kg" | "cm" | "years" | "lbs" | "ft";

export function convertMetricToImperial(units: Unit, value: number) {
  const kgToPounds = 2.20462;
  const cmToFeet = 0.0328084;
  const feetToInch = 12;
  if (units == "kg") {
    return Math.round(value * kgToPounds);
  } else if (units == "cm") {
    const feet = value * cmToFeet;
    const inch = (feet - Math.floor(feet)) * feetToInch;
    return `${Math.floor(feet)}'${Math.floor(inch)}`;
  }
}
