import { Camera, CameraCapturedPicture, CameraType } from "expo-camera";
import * as ImagePicker from "expo-image-picker";
import * as MediaLibrary from "expo-media-library";
import React, { useEffect, useState } from "react";
import {
  Image,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { getImage } from "../../helpers/resourceProvider";
import PrimaryButton from "../buttons/PrimaryButton";
import { typography } from "../../theme";

let camera: Camera | null;

export type Props = {
  onLoad: (photo: string) => void;
  style?: any;
};
const CameraComponent = ({ onLoad: setPhoto, style }: Props) => {
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const [hasMediaLibraryPermission, setHasMediaLibraryPermission] =
    useState<boolean>(false);

  const [type, setType] = useState(
    Platform.OS == "android" || Platform.OS == "ios"
      ? CameraType.front
      : CameraType.back
  );

  let openImagePickerAsync = async () => {
    let permissionResult =
      await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted === false) {
      console.log("Permission to access camera roll is required!");
      return;
    }

    let pickerResult = await ImagePicker.launchImageLibraryAsync({
      base64: true,
    });
    if (pickerResult.canceled) {
      return;
    }
    let photo = "data:image/jpg;base64," + pickerResult.assets[0].base64;
    setPhoto(photo);
  };

  useEffect(() => {
    (async () => {
      const cameraPermission = await Camera.requestCameraPermissionsAsync();
      const mediaLibraryPermission =
        await MediaLibrary.requestPermissionsAsync();
      setHasPermission(cameraPermission.status === "granted");
      setHasMediaLibraryPermission(mediaLibraryPermission.status === "granted");
    })();

    return () => {};
  }, []);

  const handleTakePhoto = async () => {
    let options = {
      quality: 1,
      base64: true,
      exif: false,
    };
    if (camera) {
      let capturedPhoto: CameraCapturedPicture = await camera.takePictureAsync(
        options
      );
      let photo: string;
      if (Platform.OS == "web") {
        photo = capturedPhoto.base64 as string;
      } else {
        photo = "data:image/jpg;base64," + (capturedPhoto.base64 as string);
      }
      setPhoto(photo);
    }
  };

  if (true) {
    return (
      <View style={{ flexDirection: "column", alignItems: "center" }}>
        <Text style={[styles.text, { marginBottom: 20, marginTop: 20 }]}>
          Please grant access to the camera
        </Text>
        <PrimaryButton
          text={"Grant access"}
          onPress={async () => {
            const resp = await Camera.requestCameraPermissionsAsync();
            setHasPermission(resp.status === "granted");
          }}
        />
        <Text style={[styles.text, { marginBottom: 20 }]}>Or</Text>

        <PrimaryButton
          text={"Choose from gallery"}
          onPress={async () => {
            openImagePickerAsync();
          }}
        />
      </View>
    );
  }

  return (
    <Camera
      style={[styles.camera, style]}
      type={type}
      ref={(ref) => {
        camera = ref;
      }}
    >
      <View style={styles.buttonContainer}>
        <Pressable
          style={styles.flipButton}
          onPress={() => {
            setType(
              type === CameraType.back ? CameraType.front : CameraType.back
            );
          }}
        >
          <Image
            style={styles.shutterButtonImg}
            source={getImage("cameraFlip")}
          />
        </Pressable>
        <Pressable onPress={handleTakePhoto} style={styles.shutterButton}>
          <Image
            style={styles.shutterButtonImg}
            source={getImage("cameraShutter")}
          />
        </Pressable>
        <Pressable
          onPress={() => openImagePickerAsync()}
          style={styles.mediaLibrary}
        >
          <Image
            style={styles.shutterButtonImg}
            source={getImage("cameraFromGallery")}
          />
        </Pressable>
      </View>
    </Camera>
  );
};

export default CameraComponent;

const styles = StyleSheet.create({
  camera: {
    flexDirection: "column-reverse",
    paddingBottom: 0,
  },
  buttonContainer: {
    flexDirection: "row",
    alignSelf: "stretch",
    justifyContent: "center",
    paddingBottom: 20,
  },
  flipButton: {
    position: "absolute",
    left: 20,
    alignSelf: "center",
    width: 30,
    height: 30,
  },
  text: {
    ...typography.p1,
  },
  shutterButton: {
    width: 70,
    height: 70,

    alignSelf: "center",
  },
  mediaLibrary: {
    position: "absolute",
    right: 20,
    alignSelf: "center",
    width: 30,
    height: 30,
  },
  shutterButtonImg: {
    width: "100%",
    height: "100%",
  },
  promptText: {
    ...typography.p1,
  },
});
