import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import React from "react";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { getImage } from "../helpers/resourceProvider";
import colors from "../theme/colors";

const HeaderAuthentication = ({
  navigation,
  route,
  options,
  back,
}: NativeStackHeaderProps) => {
  const isLoginScreen = route.name == "LoginScreen";
  return (
    <>
      {
        <View style={styles.container}>
          <Pressable
            onPress={navigation.goBack}
            hitSlop={{ top: 100, left: 100, bottom: 100, right: 100 }}
          >
            {isLoginScreen && (
              <Image
                source={getImage("backArrow")}
                style={styles.arrow}
                resizeMode="contain"
              />
            )}
          </Pressable>
        </View>
      }
    </>
  );
};

export default HeaderAuthentication;

const styles = StyleSheet.create({
  container: {
    height: 50,
    backgroundColor: colors.bg,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  arrow: {
    width: 20,
    height: 15,
  },
  bell: {
    width: 20,
    height: 20,
  },
  logo: {
    width: 70,
    height: 40,
  },
});
