import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { GiftedChat, IMessage, MessageProps } from "react-native-gifted-chat";
import EventSource from "react-native-sse";
import { env } from "../../../env";

import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";

import "text-encoding-polyfill";
import ProductListInCard from "../../components/product-list/ProductListInCard";
import getInternalImgUrl from "../../helpers/getImgUrl";
import { objectToIntHash } from "../../helpers/objectToIntHash";
import { getImage } from "../../helpers/resourceProvider";
import { useAuth, useUserStore } from "../../hooks";
import NavigationParamList from "../../navigators/NavigationParamList";
import {
  generateLookSchemaReponse,
  getRefreshToken,
  usePostLookMutation,
} from "../../store/api";
import { colors, typography } from "../../theme";
import { Gender } from "../../types/common/Gender";
import { LookI } from "../../types/common/LookI";
import { Category } from "../../types/common/ProductI";

type ChatGptMessage = {
  role: "system" | "user" | "assistant" | "function";
  content?: string;
  name?: string;
  function_call?: {
    name: "display_colors" | "text" | "generate_look";
    arguments: string;
  };
};

type chatMsg = IMessage & {
  role: "system" | "user" | "assistant" | "function";
  name?: "display_colors" | "text" | "generate_look";
  function_call?: {
    name: "display_colors" | "text" | "generate_look";
    arguments: string;
  };
  content?: string;
};

const USER_ID = 1;
const SYSTEM_ID = 2;
const LOADING_MESSAGE_ID = Number.MAX_SAFE_INTEGER;
const convertGiftedChatToChatGpt = (chatMsg: chatMsg): ChatGptMessage => {
  return {
    content: chatMsg.content,
    name: chatMsg.name,
    role: chatMsg.role,
    function_call: chatMsg.function_call,
  };
};

export const convertLookItemToProductDescriptor = (
  item: generateLookSchemaReponse["items"][0],
  gender: Gender
) => {
  return {
    id: objectToIntHash(item),
    author: 0,
    name: "",
    gender: gender,
    createdAt: new Date().getMilliseconds(),
    category: item.category as unknown as Category,
    color: item.color,
    description: item.description,
    keywordsAcceptable: [],
    keywordsUnacceptable: [],
    image: "",
    imageBase64: "",
    imageUrl: "",
  };
};

const convertGenerateLookSchemaToLook = (
  look: generateLookSchemaReponse,
  gender: Gender
): LookI => {
  return {
    name: look.explanation,
    description: look.explanation,
    lookItems: look.items.map((item) => {
      return {
        productDescriptor: convertLookItemToProductDescriptor(item, gender),
        name: item.description,
        description: item.description,
        role: "head1",
        productDescriptorId: objectToIntHash(item),
      };
    }),
    gender: gender,
    style: "STREET",
    id: objectToIntHash(look),
    imageUrl: "",
    imageBase64: "",
    keywords: [],
    isPublic: true,
    aspectRatio: 1.4,
    year: new Date().getFullYear(),
    authorId: "0",
  };
};

const ChatScreen = () => {
  const { user } = useUserStore();
  const { token } = useAuth();
  const [messages, setMessages] = useState<chatMsg[]>([]);
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const route = useRoute<RouteProp<NavigationParamList, "Chat">>();
  const [isAnswering, setIsAnswering] = useState(false);
  const { addFavoriteLook } = useUserStore();
  const [createLook, responseCreateLook] = usePostLookMutation();
  useEffect(() => {
    if (responseCreateLook.data) {
      addFavoriteLook(responseCreateLook.data);
    }
  }, [responseCreateLook.data]);
  useEffect(() => {
    if (route.params.firstMessage) {
      const firstMessage: chatMsg = {
        role: "user",
        _id: 0,
        text: route.params.firstMessage,
        content: route.params.firstMessage,
        createdAt: new Date(),
        user: {
          _id: USER_ID,
          name: "User",
          avatar: "https://placeimg.com/140/140/any",
        },
      };
      onSend([firstMessage]);
    }
  }, [route.params.firstMessage]);

  useEffect(() => {
    if (isAnswering) {
      setMessages((previousMessages: chatMsg[]) => {
        return [
          {
            role: "assistant",
            _id: LOADING_MESSAGE_ID,
            text: "I am thinking...",
            content: "I am thinking...",
            createdAt: new Date(),
            user: {
              _id: SYSTEM_ID,
              name: "Stylee",
              avatar: "https://placeimg.com/140/140/any",
            },
          },
          ...previousMessages,
        ];
      });
    } else {
      setMessages((previousMessages: chatMsg[]) => {
        return previousMessages.filter((msg) => msg._id != LOADING_MESSAGE_ID);
      });
    }
  }, [isAnswering]);

  useEffect(() => {
    // setMessages([
    //   {
    //     role: "assistant",
    //     _id: 1,
    //     text: "How can I help you today?",
    //     content: "How can I help you today?",
    //     createdAt: new Date(),
    //     user: {
    //       _id: SYSTEM_ID,
    //       name: "Stylee",
    //       avatar: "https://placeimg.com/140/140/any",
    //     },
    //   },
    // ]);
  }, []);

  const renderMessage = useCallback((props: MessageProps<chatMsg>) => {
    if (props.currentMessage?.name == "display_colors") {
      const obj: {
        colors: string[];
        explanation: string;
      } = JSON.parse(props.currentMessage?.content as string);
      console.log("obj", obj);
      return (
        <View
          style={
            props.currentMessage?.user?._id == 1
              ? styles.userMessage
              : styles.systemMessage
          }
        >
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {obj.colors.map((color) => {
              return (
                <View
                  style={{
                    backgroundColor: color,
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                  }}
                />
              );
            })}
          </View>
          <Text style={styles.messageText}>{obj.explanation}</Text>
        </View>
      );
    } else if (props.currentMessage?.name == "generate_look") {
      let lookRaw: generateLookSchemaReponse;
      console.log(
        "props.currentMessage?.content",
        props.currentMessage?.content
      );
      try {
        lookRaw = JSON.parse(props.currentMessage?.content as string);
      } catch (e) {
        console.log("error", e, props.currentMessage?.content);
        return <View />;
      }
      const look = convertGenerateLookSchemaToLook(
        lookRaw,
        user?.params.gender as Gender
      );
      // console.log("look", look);

      return (
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          {lookRaw.items.map((item) => {
            const productDescriptor = convertLookItemToProductDescriptor(
              item,
              user?.params.gender as Gender
            );
            return (
              <View style={{ width: "40%", flexDirection: "column" }}>
                <ProductListInCard
                  style={{ width: "100%" }}
                  productDescriptor={productDescriptor}
                  key={productDescriptor.id}
                  source={item.isFromStore ? "store" : "wardrobe"}
                />
                <Text>{item.isFromStore}</Text>
              </View>
            );
          })}
          <Text style={styles.messageText}>{look.description}</Text>
        </View>
      );
    } else {
      const isUser = props.currentMessage?.user?._id == 1;
      return (
        <View
          style={[
            styles.messageCommon,
            isUser ? styles.userMessage : styles.systemMessage,
          ]}
        >
          <Image
            source={isUser ? getImage("userAvatar") : getImage("logo")}
            style={{
              height: 30,
              width: 30,
              borderRadius: 15,
              marginRight: 10,
            }}
            resizeMode="contain"
          />
          {props.currentMessage?._id == LOADING_MESSAGE_ID ? (
            <Image
              source={getImage("loadingIndicator")}
              style={{
                height: 20,
                width: 20,
                marginLeft: 10,
              }}
              resizeMode="contain"
            />
          ) : (
            <Text style={styles.messageText}>
              {props.currentMessage?.content}
            </Text>
          )}
        </View>
      );
    }
  }, []);

  const onSend = (newMessages = [] as chatMsg[]) => {
    setMessages((previousMessages: chatMsg[]) =>
      GiftedChat.append(previousMessages, newMessages)
    );
    getChatResponse([...newMessages, ...messages].reverse());
  };

  const getChatResponse = async (messages: chatMsg[]) => {
    const bodyId = Math.random().toString(36).substring(7);

    let url = env.BASE_URL + `chat/?bodyId=${bodyId}`;
    const refreshToken = getRefreshToken();
    const postBodyResponse = await axios.post(
      url,
      {
        isStream: true,
        messages: messages.map((value) => convertGiftedChatToChatGpt(value)),
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + refreshToken,
        },
      }
    );
    if (postBodyResponse.status != 200) throw new Error("could not post body");
    const response = new EventSource<"done" | "message">(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

      debug: true,
    });

    const messageId = messages[messages.length - 1]._id + "1";
    let functionCall: ChatGptMessage["function_call"];
    setIsAnswering(true);
    response.addEventListener("message", (data) => {
      //   @ts-ignore
      let msgObj = JSON.parse(data.data);
      if (msgObj.choices[0].delta.content != undefined) {
        setIsAnswering(false);
        setMessages((previousMessages: chatMsg[]) => {
          const existingMessage = previousMessages.find(
            (msg) => msg._id == messageId
          );
          if (existingMessage) {
            existingMessage.text =
              existingMessage.text + msgObj.choices[0].delta.content;
            existingMessage.content =
              existingMessage.content + msgObj.choices[0].delta.content;
          } else {
            previousMessages = [
              {
                role: "assistant",
                _id: messageId,
                content: msgObj.choices[0].delta.content,
                text: msgObj.choices[0].delta.content,
                createdAt: new Date(),
                user: {
                  _id: SYSTEM_ID,
                  name: "Stylee",
                  avatar: "https://placeimg.com/140/140/any",
                },
              },
              ...previousMessages,
            ];
          }

          return [...previousMessages];
        });
      } else if (msgObj.choices[0].delta.function_call != undefined) {
        if (!functionCall)
          functionCall = {
            arguments: "",
            name: "generate_look",
          };
        if (msgObj.choices[0].delta.function_call.arguments)
          functionCall.arguments +=
            msgObj.choices[0].delta.function_call.arguments;
        if (msgObj.choices[0].delta.function_call.name)
          functionCall.name = msgObj.choices[0].delta.function_call.name;
      }
    });
    response.addEventListener("done", (data) => {
      if (functionCall != undefined) {
        setIsAnswering(false);
        setMessages((previousMessages: chatMsg[]) => {
          return [
            {
              role: "function",
              name: functionCall?.name,
              _id: messageId,
              text: "",
              content: functionCall?.arguments,
              createdAt: new Date(),
              user: {
                _id: 2,
                name: "Stylee",
                avatar: "https://placeimg.com/140/140/any",
              },
            },
            ...previousMessages,
          ];
        });
      }
      response.close();
    });
    response.addEventListener("error", (data) => {
      console.log("received error", data);
      response.close();
    });
  };

  return (
    <View style={{ backgroundColor: colors.bg, flex: 1 }}>
      <GiftedChat
        messagesContainerStyle={{ backgroundColor: colors.bg }}
        messages={messages}
        onSend={(messages) =>
          onSend(
            messages.map((msg) => ({ ...msg, role: "user", content: msg.text }))
          )
        }
        user={{
          _id: 1,
        }}
        renderMessage={renderMessage}
      />
    </View>
  );
};

export default ChatScreen;

const styles = StyleSheet.create({
  text: {
    ...typography.p1,
  },
  messageCommon: {
    backgroundColor: colors.bg,
    alignSelf: "flex-end",
    padding: 10,
    borderColor: colors.plask,
    borderBottomWidth: 1,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  userMessage: {
    alignSelf: "flex-end",
    padding: 10,
  },
  systemMessage: {
    backgroundColor: colors.lightgray,
    padding: 10,
  },
  messageText: {
    ...typography.p1,
    color: colors.darkgray,
    paddingHorizontal: 10,
  },
});
