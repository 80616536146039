import { Unit } from "../types/Unit";
import { UserParamsI } from "../types/common/UserParamsI";
import {
  NumberQuestionI,
  QuestionType,
  ValidationResponseI,
} from "../types/Quiz";

export class NumberQuestion implements NumberQuestionI {
  type = QuestionType.NUMBER;
  constructor(
    public unitsToDisplay: Unit[],
    public minValue: number,
    public maxValue: number,
    public result: keyof UserParamsI,
    public question: string,
    public chosenAnswer: number,
    public validation: (
      answer: UserParamsI[keyof UserParamsI]
    ) => ValidationResponseI
  ) {}
}
