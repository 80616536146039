import { FlexAlignType, FlexStyle } from "react-native";
import { Platform } from "react-native";
import colors from "./colors";
const containers = {
  screenContainer: {
    flexDirection: "column" as FlexStyle["flexDirection"],
    flex: 1,
    paddingHorizontal: 20,
    backgroundColor: colors.bg,
    paddingBottom: 20,
  },
  rootContainer: {
    height: "100%",
    width: "100%",
    flex: 1,
    maxWidth: 400,
    minWidth: 375,
    alignSelf: "center" as FlexAlignType,
    // paddingBottom: 10,
    marginTop: Platform.OS === "ios" ? 30 : 0,
    backgroundColor: colors.bg,
    // overflow: "hidden",
  },
};
export default containers;
