import {
  createApi,
  defaultSerializeQueryArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { env } from "../../env";
import { FiltersI } from "../types/common/FiltersI";
import { LookI, LookItemI } from "../types/common/LookI";
import ProductI, { Category } from "../types/common/ProductI";

import { UserParamsI } from "../types/common/UserParamsI";
import { ProductDescriptorI } from "../types/common/productDescriptorI";
import { setPhotoProcessedResult } from "../types/common/setPhotoProcessedResult";
import { setToken } from "./reducers/authReducer";

import { getAuth } from "firebase/auth";
import jwtDecode from "jwt-decode";
import { RootState } from ".";
import objectHash from "object-hash";
import { WardrobeProductI } from "../types/common/WardrobeProductI";
import { UserI } from "../types/common/UserI";
import { Gender } from "../types/common/Gender";

export const baseURL = env.BASE_URL;
const userRoute = "users";
const productRoute = "products";
const looksRoute = "looks";
const styleGuideRoute = "styles";

export const getRefreshToken = async () => {
  const store = require("./index").store;

  const token = (store.getState() as RootState).auth.token;
  const currentTimestamp = Math.floor(Date.now() / 1000);
  let refreshedToken = token;

  if (token && jwtDecode<{ exp: number }>(token).exp < currentTimestamp) {
    // token is expired, refresh it
    const currentUser = getAuth().currentUser;
    if (!currentUser) {
      throw new Error("No user is signed in");
    }
    refreshedToken = await currentUser.getIdToken(true);
    if (!refreshedToken) {
      throw new Error("No token was returned from firebase");
    }
    store.dispatch(setToken({ token: refreshedToken }));
  }

  return refreshedToken;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: async (headers) => {
      const refreshToken = await getRefreshToken();

      headers.set("authorization", `Bearer ${refreshToken}`);

      return headers;
    },
  }),
  tagTypes: ["User", "Product", "ProductDescriptor", "Look", "StyleGuide"],
  endpoints: (builder) => ({
    getAllStyleGuide: builder.query<
      {
        gender: Gender;
        data: {
          title: string;
          description: string;
          img: string;
          formula: string;
        };
      }[],
      void
    >({
      query: () => `${styleGuideRoute}/`,
      providesTags: ["StyleGuide"],
    }),
    getUser: builder.query<{ user: UserI; rules: any }, void>({
      query: () => `${userRoute}/`,
      transformResponse: (response: { data: { user: UserI; rules: any } }) =>
        response.data,
      providesTags: ["User"],
    }),
    deleteUser: builder.mutation<void, void>({
      query: () => ({
        url: `${userRoute}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["User"],
    }),
    postUserParams: builder.mutation<UserI, Partial<UserParamsI>>({
      query: (userParams) => ({
        url: `${userRoute}/params`,
        method: "POST",
        body: { userParams: userParams },
      }),
      async onQueryStarted(userParams, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData(
            "getUser",
            undefined,
            (draft: { user: any }) => {
              draft.user.params = { ...draft.user.params, ...userParams };
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ["User", "StyleGuide"],
    }),
    getUserPhoto: builder.query<string, void>({
      query: () => `${userRoute}/photo`,
      providesTags: ["User"],
      transformResponse: (response: { data: string }) => response.data,
    }),
    setUserPhoto: builder.mutation<setPhotoProcessedResult, string>({
      query: (photo) => ({
        url: `${userRoute}/photo`,
        method: "POST",
        body: { photo: photo },
      }),
      transformResponse: (response: { data: setPhotoProcessedResult }) =>
        response.data,
      invalidatesTags: ["User"],
    }),

    generateLook: builder.mutation<
      generateLookSchemaReponseShort,
      {
        preferences: string;
        look: LookI | undefined;
        imgBase64?: string;
        filters?: Partial<FiltersI>;
      }
    >({
      query: (args) => ({
        url: `${looksRoute}/generate/`,
        method: "POST",
        body: args,
      }),
      transformResponse: (response: { data: generateLookSchemaReponseShort }) =>
        response.data,
    }),

    postWardrobeProduct: builder.mutation<
      WardrobeProductI,
      { imgBase64?: string; product?: ProductI }
    >({
      query: (product) => ({
        url: `${userRoute}/favorites/wardrobe`,
        method: "POST",
        body: { ...product },
      }),
      invalidatesTags: ["User"],
    }),

    deleteWardrobeProduct: builder.mutation<void, WardrobeProductI>({
      query: (wardrobeProduct) => ({
        url: `${userRoute}/favorites/wardrobe`,
        method: "DELETE",
        body: { wardrobeProduct: wardrobeProduct },
      }),
      invalidatesTags: ["User"],
    }),
    postFavoriteLook: builder.mutation<void, LookI["id"]>({
      query: (lookId) => ({
        url: `${userRoute}/favorites/looks`,
        method: "POST",
        body: { lookId: lookId },
      }),
      async onQueryStarted(lookId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getUser", undefined, (draft) => {
            draft.user.favorites.looks = [
              ...draft.user.favorites.looks,
              lookId,
            ];
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [],
    }),
    deleteFavoriteLook: builder.mutation<void, LookI["id"]>({
      query: (lookId) => ({
        url: `${userRoute}/favorites/looks`,
        method: "DELETE",
        body: { lookId: lookId },
      }),
      async onQueryStarted(lookId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getUser", undefined, (draft) => {
            draft.user.favorites.looks = [
              ...draft.user.favorites.looks.filter(
                (existingId) => existingId != lookId
              ),
            ];
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [],
    }),

    postFavoriteProduct: builder.mutation<void, ProductI["id"]>({
      query: (productId) => ({
        url: `${userRoute}/favorites/products`,
        method: "POST",
        body: { productId: productId },
      }),
      async onQueryStarted(productId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getUser", undefined, (draft) => {
            draft.user.favorites.products = [
              ...draft.user.favorites.products,
              productId,
            ];
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [],
    }),
    getLookById: builder.query<LookI, LookI["id"]>({
      query: (lookId) => `${looksRoute}/${lookId}`,
      transformResponse: (response: { data: LookI }) => response.data,
      providesTags: ["Look"],
    }),
    deleteFavoriteProduct: builder.mutation<void, ProductI["id"]>({
      query: (productId) => ({
        url: `${userRoute}/favorites/products`,
        method: "DELETE",
        body: { productId: productId },
      }),
      async onQueryStarted(productId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getUser", undefined, (draft) => {
            draft.user.favorites.products = [
              ...draft.user.favorites.products.filter(
                (productExistingId) => productExistingId != productId
              ),
            ];
          })
        );
        try {
          await queryFulfilled;
        } catch {
          console.log("Mistake in optimistic update delete fav prod");
          patchResult.undo();
        }
      },
      invalidatesTags: [],
    }),

    postLook: builder.mutation<LookI, createLookBody>({
      query: (args) => ({
        url: `${looksRoute}/`,
        method: "POST",
        body: { look: args.look, isCreateByImg: args.isCreateByImg },
      }),
      invalidatesTags: ["Look", "ProductDescriptor"],
      transformResponse: (response: { data: LookI }) => response.data,
    }),
    deleteLook: builder.mutation<LookI, LookI>({
      query: (look) => ({
        url: `${looksRoute}/`,
        method: "DELETE",
        body: { look: look },
      }),
      invalidatesTags: ["Look"],
    }),

    getProducts: builder.query<getProductsResponse, getFiltersOrProductsBody>({
      query: (args) => ({
        url: `${productRoute}/`,
        method: "POST",
        body: args,
      }),
      providesTags: (result, error, arg) => [
        { type: "Product", id: arg.lookItem?.id },
      ],
      // serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
      //   if (!queryArgs.lookItem)
      //     return defaultSerializeQueryArgs({
      //       queryArgs,
      //       endpointDefinition,
      //       endpointName,
      //     });
      //   return objectHash([
      //     queryArgs.lookItem.id,
      //     // queryArgs.lookItem.filters.priceFilter.maxPrice,
      //     // queryArgs.lookItem.filters.priceFilter.minPrice,
      //     // ...queryArgs.lookItem.filters.brandFilter.brands,
      //     // ...queryArgs.lookItem.filters.colorFilter.colors,
      //     // ...queryArgs.lookItem.filters.storeFilter.stores,
      //     // queryArgs.lookItem.filters.source,
      //     queryArgs.limit,
      //     queryArgs.offset,
      //   ]);
      // },
    }),
    getProductById: builder.query<ProductI, ProductI["id"]>({
      query: (id) => `${productRoute}/${id}`,
      transformResponse: (response: { data: ProductI }) => response.data,
    }),
  }),
});

export const {
  useGetProductsQuery,

  usePostLookMutation,
  useGetUserQuery,
  usePostUserParamsMutation,
  useDeleteFavoriteProductMutation,
  usePostFavoriteProductMutation,
  useSetUserPhotoMutation,
  useGetUserPhotoQuery,
  useGetAllStyleGuideQuery,
  useDeleteLookMutation,
  useGetProductByIdQuery,
  usePostFavoriteLookMutation,
  useDeleteFavoriteLookMutation,
  useDeleteUserMutation,
  useGenerateLookMutation,
  useGetLookByIdQuery,

  usePostWardrobeProductMutation,
  useDeleteWardrobeProductMutation,
} = api;

interface getFiltersOrProductsBody {
  lookItem: LookItemI;
  color?: string[];
  limit?: number;
  offset?: number;
}
export interface getProductsByLookBody {
  lookId?: LookI["id"];
  look?: LookI;
  filters: FiltersI;
  offset: number;
  limit: number;
}

export interface getProductByLookResponse {
  [key: LookItemI["id"]]: getProductsResponse;
}
interface createLookBody {
  look: LookI;
  isCreateByImg: boolean;
}

export interface getProductsResponse {
  products: ProductI[];
  filters: FiltersI;
}

export type generateLookSchemaReponse = {
  explanation: string;
  items: {
    category: Category;
    color: string;
    description: string;
    isFromStore: boolean;
  }[];
  // wardrobeItems: {
  //   itemId: number;
  //   category: Category;
  // }[];
};

export type generateLookSchemaReponseShort = {
  look: LookI;
  filters: { [key: LookItemI["id"]]: Partial<FiltersI>[] };
  items: string[];
};
