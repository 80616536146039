import { getImage } from "../../helpers/resourceProvider";
import { colors, typography } from "../../theme";
import { Input, Image, Pressable } from "native-base";
export type SearchBarProps = {
  value: string;
  onInputChange: (text: string) => void;
  onPress: () => void;
  placeholder?: string;
};

export const SearchBar = ({
  value: chatPrompt,
  onInputChange: setChatPrompt,
  onPress,
  placeholder,
}: SearchBarProps) => {
  return (
    <Input
      InputLeftElement={
        <Image
          source={getImage("logo")}
          style={{ width: 15, height: 15, borderRadius: 0 }}
          resizeMode="contain"
          ml={5}
        />
      }
      paddingX={5}
      w={"100%"}
      _focus={{
        borderColor: colors.darkgray,
        backgroundColor: colors.lightgray,
      }}
      backgroundColor={colors.lightgray}
      borderRadius={10}
      variant="unstyled"
      onChangeText={setChatPrompt}
      value={chatPrompt}
      placeholder={placeholder || "Type a message"}
      InputRightElement={
        <Pressable
          onPress={onPress}
          style={{ padding: 10 }}
          hitSlop={{ top: 20, left: 20, bottom: 20, right: 20 }}
          mr={5}
        >
          <Image
            source={
              chatPrompt.length == 0
                ? getImage("sendUnfilled")
                : getImage("sendFilled")
            }
            style={{ width: 15, height: 15, borderRadius: 0 }}
            resizeMode="contain"
          />
        </Pressable>
      }
      style={typography.p1}
      py={3}
    />
  );
};
