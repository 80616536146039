import React from "react";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { getImage } from "../../helpers/resourceProvider";
import { colors, typography } from "../../theme";
import containers from "../../theme/containers";

import { NavigationProp, useNavigation } from "@react-navigation/native";
import { openBrowserAsync } from "expo-web-browser";
import { logPayIntent } from "../../helpers/analytics";
import { useUserStore } from "../../hooks";
import usePayments from "../../hooks/usePayments";
import NavigationParamList from "../../navigators/NavigationParamList";
import { theme } from "../../theme/defaultTheme";
import { PrimaryButton } from "../buttons";
import { env } from "../../../env";

export type Props = {
  context: string;
};

const SubscriptionsComponent = ({ context }: Props) => {
  const { user } = useUserStore();
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const [selectedPlan, setSelectedPlan] = React.useState(0);
  const {
    makePurchase,
    currentOffering,
    customerInfo,
    getCustomerInfo,
    isPremium,
  } = usePayments(user?.id);

  const renderFeature = (text: string) => {
    return (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Image
          source={getImage("checkmarkIcon")}
          style={{ height: 24, width: 24, marginRight: 5 }}
        />
        <Text style={[typography.p1, { fontSize: 18 }]}>{text}</Text>
      </View>
    );
  };

  const periodMap: { [key: string]: [number, string] } = {
    P1M: [1, "mo"],
    P1Y: [12, "yr"],
    P6M: [6, "6m"],
  };

  const renderPlan = (
    price: number,
    period: string,
    onPress: () => void,
    isSelected: boolean
  ) => {
    const [periodNumber, periodText] = periodMap[period];

    const priceMonthly = `$${(price / periodNumber).toFixed(2)}/mo.`;
    const priceTotal = `$${price.toFixed(2)}/${periodText}.`;
    return (
      <Pressable
        onPress={onPress}
        style={{
          flex: 1,
          margin: 10,
          backgroundColor: colors.lightgray,
          borderRadius: 5,
          borderColor: isSelected ? colors.blue : colors.lightgray,
          borderWidth: 2,
          padding: 20,
          paddingHorizontal: 15,
          alignItems: "center",
        }}
      >
        <Text style={[typography.h4]}>{periodNumber}</Text>
        <Text style={[typography.h4]}>months</Text>
        <View style={{ height: 10 }} />
        <Text style={{ fontSize: 16, fontFamily: theme.fontSecondaryBold }}>
          {priceMonthly}
        </Text>
        <View style={{ height: 10 }} />
        <Text style={{ fontSize: 16, fontFamily: theme.fontSecondaryRegular }}>
          {priceTotal}
        </Text>
      </Pressable>
    );
  };
  // useEffect(() => {
  //   if (isPremium) navigation.navigate("HomeScreen");
  // }, [isPremium]);

  if (!currentOffering) return null;

  // if (isPremium) return <Text>Already Premium</Text>;
  return (
    <View style={styles.container}>
      {isPremium ? (
        <View>
          <Text style={typography.h1}>You are a PRO</Text>
          <View style={{ height: 15 }} />

          <PrimaryButton
            text="Manage Subscription"
            onPress={() => openBrowserAsync(env.stripe_customer_portal_url)}
          />
          {/* <View style={{ height: 15 }} />
          {/* <Text style={typography.p1}>
            Your subscription ends
            {" "}
            {new Date(
              customerInfo?.entitlements?.all?.pro?.expirationDateMillis
            ).toLocaleDateString()}
          </Text> */}
        </View>
      ) : (
        <View>
          <Text style={typography.h1}>Enjoy pro features</Text>
          <View style={{ height: 15 }} />
          {renderFeature("Personalized looks and advice")}
          <View style={{ height: 10 }} />
          {renderFeature("Access to the current trends")}
          <View style={{ height: 10 }} />
          {renderFeature("Access to the style guide")}
          <View style={{ height: 50 }} />
          <Text style={[typography.h3, { textAlign: "center" }]}>
            Select your subscription type
          </Text>
          <View style={{ height: 10 }} />

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            {currentOffering.availablePackages.map((pkg, index) => {
              return renderPlan(
                parseFloat(pkg.product.price.toFixed(2)),
                pkg.product.subscriptionPeriod as string,
                () => setSelectedPlan(index),
                index === selectedPlan
              );
            })}
          </View>
          <View style={{ height: 30 }} />
          <Text style={[typography.p1, { textAlign: "center" }]}>
            7 day free trial, cancel anytime
          </Text>
          <View style={{ height: 10 }} />
          <PrimaryButton
            onPress={() => {
              logPayIntent(
                currentOffering.availablePackages[selectedPlan].product
                  .subscriptionPeriod,
                context
              );
              makePurchase(currentOffering.availablePackages[selectedPlan]);
            }}
            text="Go PRO"
            style={{ marginBottom: 20 }}
          />
          <View style={{ height: 20 }} />

          <View
            style={{ justifyContent: "space-between", flexDirection: "row" }}
          >
            <Pressable
              onPress={() => openBrowserAsync(env.TERMS_OF_SERVICE_URL)}
            >
              <Text style={[typography.p1]}>Terms of service</Text>
            </Pressable>
            <Pressable onPress={() => openBrowserAsync(env.PRIVACY_POLICY_URL)}>
              <Text style={[typography.p1]}>Privacy policy</Text>
            </Pressable>
          </View>
        </View>
      )}
    </View>
  );
};

export default SubscriptionsComponent;

const styles = StyleSheet.create({
  buyButton: { marginBottom: 10 },
  container: { ...containers.screenContainer },

  featuresContainer: {
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    marginBottom: 20,
    flex: 1,
  },
});
