const colors = {
  light: "#F6F4FA",
  gray: "#929292",
  blue: "#6C42FF",
  bg: "#FFFFFF", //F9F8FF,
  lightgray: "#F9F8FF",
  plask: "#EDEDED",
  middlegray: "#D9D9D9",
  darkgray: "#505050",
  dark: "#101011",
  yellow: "#F0BA15",
  red: "#DD232B",
  green: "#00CB94",
  white: "#FFFFFF",
  black: "#000000",
};

export default colors;
