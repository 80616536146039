import { Dimensions, ScrollView, StyleSheet } from "react-native";
import React, { useEffect, useState } from "react";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import NavigationParamList from "../../navigators/NavigationParamList";
import containers from "../../theme/containers";
import { colors, typography } from "../../theme";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { openBrowserAsync } from "expo-web-browser";
import { currencySymbols } from "../../helpers/currencyConverter";
import { theme } from "../../theme/defaultTheme";
import ProductList from "../../components/product-list/ProductList";
import ProductI, { Category } from "../../types/common/ProductI";
import { ProductDescriptorI } from "../../types/common/productDescriptorI";
import { ProductDescriptor } from "../../entities/common/ProductDescriptor";
import { Gender } from "../../types/common/Gender";
import { PageSlider } from "../../components/page-slider";
import getInternalImgUrl, { getImgUrl } from "../../helpers/getImgUrl";
import { useModal } from "../../hooks/useModal";
import FiltersPage from "../../components/filters-page";
import {
  useGlobalFiltersStore,
  useLocalFiltersStore,
  useUserStore,
} from "../../hooks";
import { useSelector, useDispatch } from "react-redux";
import { randomInteger } from "../../helpers/RandomInteger";
import { RootState } from "../../store";
import { setLookItem } from "../../store/reducers/lookItemsReducer";
import { LookItemI } from "../../types/common/LookI";
import { FiltersI } from "../../types/common/FiltersI";
import {
  useDeleteWardrobeProductMutation,
  usePostWardrobeProductMutation,
} from "../../store/api";
import { isProductFromWardrobe } from "../../helpers/isProductFromWardrobe";
import LikeButton from "../../components/product-card/likeButton";
import { Image, Pressable, Row, Text, View } from "native-base";
import { getImage } from "../../helpers/resourceProvider";
import { logAddWardrobe, logGoPartner } from "../../helpers/analytics";

const ProductScreen = () => {
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const route = useRoute<RouteProp<NavigationParamList, "Product">>();
  const [addWardrobeProduct, result] = usePostWardrobeProductMutation();
  const [deleteWardrobeProduct, resultDelete] =
    useDeleteWardrobeProductMutation();
  const { productId, product } = route.params;
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const { showModal, hideModal, modal } = useModal();
  const [lookItemId, setLookItemId] = useState<number | undefined>();
  const lookItem = useSelector(
    (state: RootState) => lookItemId && state.lookItems.state[lookItemId]
  );
  const { globalFilters } = useGlobalFiltersStore();
  const { user } = useUserStore();
  const dispatch = useDispatch();

  useEffect(() => {
    const lookItemId = randomInteger(0, Number.MAX_SAFE_INTEGER);
    const lookItem: LookItemI = {
      description: product?.description || "",
      filters: {},
      id: lookItemId,
      gender: user?.params.gender,
    };
    dispatch(setLookItem({ id: lookItemId, lookItem: lookItem }));
    setLookItemId(lookItemId);
  }, []);
  const imgWidth =
    Math.min(
      Dimensions.get("window").width,
      containers.rootContainer.maxWidth
    ) - 40;
  if (!product) {
    return null;
  }

  const handleGoToPartner = () => {
    logGoPartner({ product: product });
    openBrowserAsync(product?.url as string);
  };
  const showModalContent = () => {
    showModal(
      <View>
        <FiltersPage
          hideModal={hideModal}
          hook={() => {
            const lookItemHook = useSelector(
              (state: RootState) =>
                lookItem && state.lookItems.state[lookItem.id]
            );

            const dispatch = useDispatch();
            return {
              filtersUnit: {
                possibleValues: globalFilters.possibleValues,
                chosenValues: {
                  ...globalFilters.chosenValues,
                  ...lookItemHook.filters,
                },
                isLoading: globalFilters.isLoading,
              },
              setFiltersUnit: (filters: FiltersI) =>
                dispatch(
                  setLookItem({
                    id: lookItemHook.id,
                    lookItem: { filters },
                  })
                ),
              description: lookItemHook.description,
              setDescription: (description: string) =>
                dispatch(
                  setLookItem({
                    id: lookItemHook.id,
                    lookItem: { description },
                  })
                ),
            };
          }}
        />
      </View>
    );
  };
  return (
    <ScrollView style={{ flex: 1, backgroundColor: colors.bg }}>
      <View style={containers.screenContainer}>
        <LikeButton
          product={product}
          style={{
            width: 40,
            height: 40,
            position: "absolute",
            top: 450,
            right: 40,
          }}
        />
        <PageSlider
          maxWidth={imgWidth}
          peek={0}
          pageMargin={0}
          selectedPage={activeIndex}
          onSelectedPageChange={setActiveIndex}
          onCurrentPageChange={function (currentPage: number): void {}}
          mode="card"
        >
          {product?.image.map((image, index) => {
            return (
              // <View>
              <Image
                source={{
                  uri: getImgUrl(image),
                }}
                style={{
                  width: imgWidth,
                  height: 500,
                  aspectRatio: 1,
                  borderRadius: 10,
                  marginBottom: 20,
                }}
                resizeMode="cover"
                key={index}
              />
            );
          })}
        </PageSlider>
        <Text style={typography.p2} mb={1}>
          {product?.brand}
        </Text>

        <Text style={[typography.h3]} mb={1}>
          {product?.name}
        </Text>

        <Text
          style={[typography.p2, { fontFamily: theme.fontSecondarySemibold }]}
          mb={2}
        >
          {product?.price}{" "}
          {currencySymbols[product?.currency.toLowerCase() as string]}
        </Text>

        <PrimaryButton
          onPress={handleGoToPartner}
          text={`Go to ${product?.store}`}
          style={{ marginBottom: 10 }}
        />

        {isProductFromWardrobe(product, user?.favorites.wardrobe) ? (
          <SecondaryButton
            onPress={() => {
              deleteWardrobeProduct(product);
            }}
            text="🗑️ Delete from my wardrobe"
          />
        ) : (
          <SecondaryButton
            onPress={() => {
              logAddWardrobe({ isByImg: false, product: product });

              addWardrobeProduct({ product: product });
            }}
            text="➕ Add to my wardrobe"
          />
        )}

        <Row mb={1} mt={3} justifyContent={"space-between"}>
          <Text style={typography.h3}>Similar products</Text>
          <Pressable onPress={showModalContent}>
            <Image
              source={getImage("editIcon")}
              style={{ width: 28, height: 28 }}
            />
          </Pressable>
        </Row>
        <ProductList
          lookItem={lookItem}
          direction="vertical"
          // filters={localFilters.chosenValues}
        />
      </View>
    </ScrollView>
  );
};

export default ProductScreen;

const styles = StyleSheet.create({});
