import { Pressable, StyleSheet, Text, View } from "react-native";
import React from "react";
import { theme } from "../../theme/defaultTheme";
import colors from "../../theme/colors";
import { typography } from "../../theme";

export type Props = {
  name: string;
  value: string | undefined | number;
  onPress: () => void;
};
const ProfileCard = ({ name, value, onPress }: Props) => {
  return (
    <Pressable style={styles.container} onPress={onPress}>
      <Text style={styles.name}>{name}</Text>
      <Text style={styles.value}>{value}</Text>
    </Pressable>
  );
};

export default ProfileCard;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 15,
    borderBottomColor: colors.plask,
    borderBottomWidth: 1,
  },
  name: {
    fontFamily: theme.fontSecondarySemibold,
    fontSize: typography.p1.fontSize,
    color: colors.dark,
  },
  value: {
    ...typography.p1,
  },
});
