import { StyleSheet, View } from "react-native";
import { FiltersI } from "../../types/common/FiltersI";
import { LookI } from "../../types/common/LookI";
import { ProductListInCardV2 } from "./ProductListInCardV2";
import { colors } from "../../theme";
import { ScrollView } from "native-base";
import { ProductCardV2 } from "../product-card/ProductCardV2";
import ProductI from "../../types/common/ProductI";

export type LookCardV2Props = {
  look: LookI;
  filters?: FiltersI;
  style?: any;
};

export const LookCardV2InLine = ({ look, style }: LookCardV2Props) => {
  // console.log("look", look);

  return (
    <View style={[styles.container, style]}>
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        style={{ width: "100%" }}
      >
        {look.lookItems.map((item, index) => (
          // <ProductListInCardV2
          //   lookItem={item}
          //   key={index}
          //   style={{ width: 200, marginBottom: 8, marginRight: 8 }}
          // />
          <ProductCardV2
            product={item.product as ProductI}
            key={index}
            style={{ width: 200, marginBottom: 8, marginRight: 8 }}
          />
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    borderRadius: 20,
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "space-between",
    // borderColor: colors.gray,
    // borderWidth: 2,
    // padding: 10,
    // backgroundColor: colors.lightgray,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
  },
  image: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
  },
  textContainer: {
    padding: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
  },
  description: {
    fontSize: 14,
    color: "gray",
  },
});
