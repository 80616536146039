import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import React from "react";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { getImage } from "../helpers/resourceProvider";
import colors from "../theme/colors";
import { typography } from "../theme";
import { useUserStore } from "../hooks";
import PremiumLabel from "../components/premium-label/PremiumLabel";
import { UserI } from "../types/common/UserI";

const routeNamesMap = {
  OnboardingScreen: "Onboarding",
  LoginScreen: "Login",
  SignupScreen: "Signup",
  TOS: "TOS",
  QuizScreenBeforeSignup: "Quiz",
  AuthenticatedTabNavigator: "",
  Main: "Main",
  Favorites: "Favorites",
  HomeScreen: "",
  StyleGuideScreen: "Style Guide",
  Settings: "Settings",
};

const HeaderAuthenticated = (
  { navigation, route, options, back }: NativeStackHeaderProps,
  user: UserI | undefined,
  isPremium: boolean
) => {
  const isHome = route.name == "HomeScreen";
  return (
    <View style={styles.container}>
      <Pressable
        onPress={isHome ? () => {} : navigation.goBack}
        // style={{ position: "absolute", left: 20 }}
        // hitSlop={{ top: 100, left: 100, bottom: 100, right: 100 }}
      >
        {isHome ? (
          <Text style={typography.h1}>Hello, {user?.params.name}</Text>
        ) : (
          // <Image
          //   source={getImage("backArrow")}
          //   style={styles.arrow}
          //   resizeMode="contain"
          // />
          <Text style={typography.p2Dark}>← {"  "}Back</Text>
        )}
      </Pressable>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {!isPremium && (
          <Pressable
            style={{ marginRight: 0 }}
            onPress={() =>
              navigation.navigate("Subscription", { context: "Header" })
            }
          >
            <PremiumLabel text="Get Pro" />
          </Pressable>
        )}

        {/* <Pressable onPress={() => navigation.navigate("Favorites")}>
          <Image
            source={getImage("navbarHeartUnfilled")}
            style={{ width: 24, height: 24 }}
            resizeMode="contain"
          />
        </Pressable> */}
      </View>
      {/* <Text style={typography.h2}>{}</Text> */}
    </View>
  );
};

export default HeaderAuthenticated;

const styles = StyleSheet.create({
  container: {
    height: 70,
    backgroundColor: colors.bg,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  arrow: {
    width: 24,
    height: 24,
  },
  bell: {
    width: 20,
    height: 20,
  },
  logo: {
    width: 70,
    height: 40,
  },
});
