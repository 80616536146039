import { Platform } from "react-native";
import * as Facebook from "expo-auth-session/providers/facebook";
import * as Google from "expo-auth-session/providers/google";
import * as WebBrowser from "expo-web-browser";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStatus } from "../store/reducers/appStatusReducer";
import { setToken } from "../store/reducers/authReducer";

import { env } from "../../env";
import FirebaseService from "../helpers/FirebaseService";
import { useDeleteUserMutation } from "../store/api";
import useAppStatus from "./useAppStatus";
import { RootState } from "../store";
import { logLogin, logLogout, logSingUp } from "../helpers/analytics";

WebBrowser.maybeCompleteAuthSession();

const useAuth = () => {
  const isLogin: boolean =
    useSelector((state: RootState) => state.auth.token) != undefined;
  const { showMessage } = useAppStatus();
  const [modalVisible, setModalVisible] = useState(false);
  const [request, response, promptAsync] = Google.useIdTokenAuthRequest(
    {
      clientId:
        Platform.OS == "web"
          ? env.GOOGLE_OAUTH_CLIENTID_WEB
          : Platform.OS == "android"
          ? env.GOOGLE_OAUTH_CLIENTID_ANDROID
          : env.GOOGLE_OAUTH_CLIENTID_IOS,
    },
    {
      path: env.OAUTH_PATH,
    }
  );

  const [requestFb, responseFb, promptAsyncFb] = Facebook.useAuthRequest(
    {
      clientId: env.FACEBOOK_OAUTH_CLIENTID,
    },
    {
      path: env.OAUTH_PATH,
    }
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [deleteAccountMutation, resultDeleteAccountMutation] =
    useDeleteUserMutation();
  useEffect(() => {
    if (response?.type === "success") {
      const { id_token } = response.params;
      FirebaseService.getAuth().currentUser?.getIdToken(true);
      const credential =
        FirebaseService.getGoogleAuthProvider().credential(id_token);
      console.log("credential", credential);
      FirebaseService.signInWithCredential(credential);
    } else {
      console.log("Authentication has not been successful  ", response);
    }
  }, [response]);

  useEffect(() => {
    if (
      responseFb &&
      responseFb.type === "success" &&
      responseFb.authentication
    ) {
      (async () => {
        const userInfoResponse = await fetch(
          `https://graph.facebook.com/me?access_token=${responseFb.authentication?.accessToken}&fields=id,name,email`
        );
        const userInfo = await userInfoResponse.json();
        const credential = FirebaseService.getFacebookAuthProvider().credential(
          responseFb.authentication?.accessToken as string
        );

        FirebaseService.signInWithCredential(credential).catch(
          async (error: any) => {
            try {
              if (
                error.code != "auth/account-exists-with-different-credential"
              ) {
                throw error;
              }
              showMessage("Account already linked to a google account");
            } catch (error) {
              throw error;
            }
          }
        );
      })();
    }
  }, [responseFb]);

  const resetPassword = () => {
    FirebaseService.sendPasswordResetEmail(email)
      .then(() => {
        showMessage("We've sent the link to your email");
      })
      .catch((error: any) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error when resetting password", error.code);
        var message;
        switch (errorCode) {
          case "auth/invalid-email":
            message = "Please enter the valid email";
            break;
          case "auth/user-not-found":
            message = "The user with this email doesn't exist";
            break;
          case "auth/network-request-failed":
            message =
              "Please make sure that your internet connection is stable and try again";
            break;
          default:
            message = "Please try again later";
            break;
        }
        showMessage(message);
      });
  };

  const handleCreateUserWithEmailAndPassword = () => {
    FirebaseService.createUserWithEmailAndPassword(email, password);
  };

  const handleSigninWithEmailAndPassword = async () => {
    try {
      const userCredentials = await FirebaseService.signInWithEmailAndPassword(
        email,
        password
      );

      const token = await userCredentials.user.getIdToken();
      dispatch(setToken({ token: token }));
    } catch (error) {
      showMessage("Incorrect Password");
    }
  };

  const handleFacebookLogin = () => {
    promptAsyncFb();
  };

  const changePassword = async (
    password1: string,
    password2: string,
    oldPassword: string
  ) => {
    if (password1 != password2) {
      showMessage("Password dont match");

      return;
    }
    if (password1.length < 6 || oldPassword.length < 6) {
      showMessage("Password should be at least 6 characters long");
      return;
    }
    const user = FirebaseService.getAuth().currentUser;
    if (!user) {
      return;
    }
    const credential = FirebaseService.getEmailAuthProvider().credential(
      user.email as string,
      oldPassword
    );
    var authResult;

    try {
      authResult = await FirebaseService.reauthenticateWithCredential(
        credential
      );
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("error when reauthenticatinf password", error.code);
      var message: string;
      switch (errorCode) {
        case "auth/wrong-password":
          message = "The password is incorrect";
          break;
        case "auth/internal-error":
          message = "The user with this email doesn't exist";
          break;
        case "auth/network-request-failed":
          message =
            "Please make sure that your internet connection is stable and try again";
          break;
        case "auth/too-many-requests":
          message =
            "We've received too many requests from yuor account. Try again later.";
          break;
        default:
          message = "Please try again later";
          break;
      }
      setStatus({
        message: "We've sent the link to your email",
        isShowing: true,
      });
      authResult = "unseccessful";
      return;
    }
    console.log("auth result ", authResult);

    await FirebaseService.updatePassword(password1)
      .then(() => {
        setStatus({
          message: "We've sent the link to your email",
          isShowing: true,
        });
      })
      .catch((error: any) => {
        setStatus({
          message: "We've sent the link to your email",
          isShowing: true,
        });
      });
  };
  const signOut = () => {
    FirebaseService.signOut();
  };

  const deleteAccount = () => {
    deleteAccountMutation();
  };

  const subscribeAuthChange = () => {
    const unsubscibe = FirebaseService.onAuthStateChanged(
      async (userAuth: any) => {
        await userAuth?.reload();
        userAuth = FirebaseService.getAuth().currentUser;
        if (userAuth) {
          const token = await userAuth.getIdToken();
          const createdAt = new Date(userAuth.metadata.creationTime as string);
          const lastLoginAt = new Date(
            userAuth.metadata.lastSignInTime as string
          );
          const isNewUser =
            Math.abs(createdAt.getTime() - lastLoginAt.getTime()) < 1000;
          if (isNewUser) {
            logSingUp(userAuth.providerData[0].providerId);
          } else {
            logLogin(userAuth.providerData[0].providerId);
          }
          dispatch(setToken({ token: token }));
        } else {
          logLogout();
          dispatch(setToken({ token: undefined }));
        }
      }
    );
    return unsubscibe;
  };
  const handleGoogleLogin = () => promptAsync();
  return {
    resetPassword,
    modalVisible,
    setModalVisible,
    email,
    setEmail,
    password,
    setPassword,
    promptAsync,
    handleSigninWithEmailAndPassword,
    handleFacebookLogin,
    handleGoogleLogin,
    handleCreateUserWithEmailAndPassword,
    changePassword,
    signOut,
    deleteAccount,
    isLogin,
    subscribeAuthChange,
    token: useSelector((state: RootState) => state.auth.token),
  };
};

export default useAuth;
