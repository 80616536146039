import { Image, StyleSheet, Text, View } from "react-native";
import React from "react";
import { useUserStore } from "../../hooks";
import { getImage } from "../../helpers/resourceProvider";
import TabLink from "./TabLink";
import { styles } from "./styles";
import { typography } from "../../theme";

const AccessoriesGuide = () => {
  const { user } = useUserStore();
  if (!user) return [];
  const recommendations = user.recommendations;

  const { kibbe } = recommendations;

  const slides = [
    {
      slide: (
        <View>
          <Image
            source={{
              uri: "https://i.gyazo.com/c5f5baca2154f5190e0bb5db66ed3adf.png",
            }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>Accessories Guide</Text>
          <Text style={styles.description}>
            Dive into the world of accessories, tailored just for you by our
            AI-powered stylist!
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink
          img={{
            uri: "https://i.gyazo.com/c5f5baca2154f5190e0bb5db66ed3adf.png",
          }}
          text="Welcome"
        />
      ),
    },
    {
      slide: (
        <View>
          {/* <Image
            source={{ uri: "" }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          /> */}
          <Text style={styles.title}>Best Accessories</Text>
          <Text style={styles.description}>
            {kibbe.explanationBestAccessories}
          </Text>
          <View style={{ height: 20 }} />
          <Text style={typography.h3}>Examples of Best Accessories</Text>
          <View style={{ height: 10 }} />
          <Text style={styles.description}>
            {kibbe.bestAccessories.map((accessory) => (
              <Text key={accessory}>
                - {accessory} {"\n"}
              </Text>
            ))}
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink img={getImage("bestAccessories")} text="Best Accessories" />
      ),
    },
    // {
    //   slide: (
    //     <View>
    //       {/* <Image
    //         source={{ uri: "" }}
    //         style={[styles.mainIllustration]}
    //         resizeMode="cover"
    //       /> */}
    //       <Text style={styles.title}>Examples of Best Accessories</Text>
    //       <Text style={styles.description}>
    //         {kibbe.bestAccessories.map((accessory) => (
    //           <Text key={accessory}>
    //             {accessory} {"\n"}
    //           </Text>
    //         ))}
    //       </Text>
    //     </View>
    //   ),
    //   tabLink: (
    //     <TabLink
    //       img={getImage("examplesBestAccessories")}
    //       text="Best Accessories Examples"
    //     />
    //   ),
    // },
    {
      slide: (
        <View>
          {/* <Image
            source={{ uri: "" }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          /> */}
          <Text style={styles.title}>Worst Accessories</Text>
          <Text style={styles.description}>
            {kibbe.explanationWorstAccessories}
          </Text>
          <View style={{ height: 20 }} />
          <Text style={typography.h3}>Examples of Worst Accessories</Text>
          <View style={{ height: 10 }} />
          <Text style={styles.description}>
            {kibbe.worstAccessories.map((accessory) => (
              <Text key={accessory}>
                - {accessory} {"\n"}
              </Text>
            ))}
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink img={getImage("worstAccessories")} text="Worst Accessories" />
      ),
    },
    // {
    //   slide: (
    //     <View>
    //       <Image
    //         source={{ uri: "" }}
    //         style={[styles.mainIllustration]}
    //         resizeMode="cover"
    //       />
    //       <Text style={styles.title}>Examples of Worst Accessories</Text>
    //       <Text style={styles.description}>
    //         {kibbe.worstAccessories.map((accessory) => (
    //           <Text key={accessory}>
    //             {accessory} {"\n"}
    //           </Text>
    //         ))}
    //       </Text>
    //     </View>
    //   ),
    //   tabLink: (
    //     <TabLink
    //       img={getImage("examplesWorstAccessories")}
    //       text="Worst Accessories Examples"
    //     />
    //   ),
    // },
    {
      slide: (
        <View>
          <Image
            source={getImage("thanks")}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>Thank You!</Text>
          <Text style={styles.description}>
            Thanks for exploring Best and Worst Accessories! Elevate your style
            with the perfect accessories.
          </Text>
        </View>
      ),
      tabLink: <TabLink img={getImage("thanks")} text="End" />,
    },
  ];

  return slides;
};

export default AccessoriesGuide;
