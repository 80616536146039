import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LookItemI } from "../../types/common/LookI";

export interface LookItemsStateI {
  state: { [key: LookItemI["id"]]: LookItemI };
}

const initialState = {
  state: {},
} as LookItemsStateI;

export const lookItemSlice = createSlice({
  name: "lookItems",
  initialState: initialState,
  reducers: {
    setLookItem: (
      state,
      action: PayloadAction<{
        lookItem: Partial<LookItemI>;
        id: LookItemI["id"];
      }>
    ) => {
      state.state[action.payload.id] = {
        ...state.state[action.payload.id],
        ...action.payload.lookItem,
      };
    },
  },
});

export const { setLookItem } = lookItemSlice.actions;
export default lookItemSlice.reducer;
