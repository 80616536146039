import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { LookI } from "../types/common/LookI";
import {
  useDeleteLookMutation,
  useGetLookByIdQuery,
  usePostLookMutation,
} from "../store/api";
import { LookStoreI } from "../types/LookStoreI";

const useLookStore = (): LookStoreI => {
  const [postLook, resultPostLook] = usePostLookMutation();
  const [deleteLookM, resultDeleteLook] = useDeleteLookMutation();

  const createLook = (look: LookI, isCreateByImg: boolean) => {
    postLook({ look: look, isCreateByImg: isCreateByImg });
  };
  const deleteLook = (look: LookI) => {
    deleteLookM(look);
  };

  const createLookResult = () => {
    let status: "UNINITIALIZED" | "PROCESSING" | "PROCESSED";
    if (resultPostLook.status == QueryStatus.uninitialized)
      status = "UNINITIALIZED";
    else if (resultPostLook.status == QueryStatus.pending)
      status = "PROCESSING";
    else status = "PROCESSED";

    const result: LookStoreI["createLookResult"] = {
      status: status,
    };
    if (resultPostLook.data != undefined) {
      // console.log("resultPostLook.data", resultPostLook.data);
      result.look = resultPostLook.data;
    }
    return result;
  };

  const getById = (id: LookI["id"]) => {
    // console.log("getById", allLooks, id);
    return allLooks ? allLooks.find((look: LookI) => look.id == id) : undefined;
  };

  const getByFilter = (lookFilter: LookFilterI) => {
    const looks = allLooks
      ? allLooks.filter(
          (look: LookI) =>
            (!lookFilter.gender || look.gender == lookFilter.gender) &&
            (!lookFilter.style || look.style == lookFilter.style) &&
            (!lookFilter.keywords ||
              look.keywords.some((keyword: string) =>
                lookFilter.keywords?.includes(keyword)
              )) &&
            (!lookFilter.year || look.year == lookFilter.year) &&
            (lookFilter.isIncludeAuthor == undefined ||
              (lookFilter.isIncludeAuthor == false &&
                look.authorId != lookFilter.authorId))
        )
      : undefined;
    return { looks, isFetching };
  };

  return {
    getById,
    createLook,
    createLookResult: createLookResult(),
    deleteLook,
    getByFilter,
  };
};

export default useLookStore;
