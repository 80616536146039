import { FontFamily } from "./typography";

const theme = {
  fontPrimaryBold: FontFamily.InterBold,
  fontSecondaryRegular: FontFamily.PoppinsRegular,
  fontSecondarySemibold: FontFamily.PoppinsMedium,
  fontSecondaryBold: FontFamily.PoppinsMedium,
};

export { theme };
