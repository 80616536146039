import { UserParamsI } from "../types/common/UserParamsI";
import {
  ColorPickerQuestionI,
  QuestionType,
  ValidationResponseI,
} from "../types/Quiz";

export class ColorPickerQuestion implements ColorPickerQuestionI {
  type = QuestionType.COLOR_PICKER;
  constructor(
    public result: keyof UserParamsI,
    public question: string,
    public chosenAnswer: [string, number][],
    public validation: (
      answer: UserParamsI[keyof UserParamsI]
    ) => ValidationResponseI,
    public colors: string[]
  ) {}
}
