import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
// @ts-ignore
import Slider from "react-native-sliders";

import { ColorPickerQuestionI } from "../../../types/Quiz";
import { getNameOfNearestColor } from "../../../helpers/colorHelpers";
import { typography } from "../../../theme";
interface Color {
  r: number;
  g: number;
  b: number;
}

export type Props = {
  question: ColorPickerQuestionI;
  onChange: (color: [string, number][]) => void;
};
const hexToRgb = (hex: string): Color => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
};

const rgbToHex = (color: Color): string => {
  const { r, g, b } = color;
  return "#" + [r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("");
};

const interpolateColor = (colors: string[], value: number): Color => {
  const rgbColors = colors.map(hexToRgb);
  const index = Math.floor(value * (rgbColors.length - 1));
  const remaining = value * (rgbColors.length - 1) - index;

  if (index === rgbColors.length - 1) {
    return rgbColors[index];
  }

  const color1 = rgbColors[index];
  const color2 = rgbColors[index + 1];

  const r = color1.r + remaining * (color2.r - color1.r);
  const g = color1.g + remaining * (color2.g - color1.g);
  const b = color1.b + remaining * (color2.b - color1.b);

  return { r: Math.round(r), g: Math.round(g), b: Math.round(b) };
};

const ColorPickerQuestion = ({ question, onChange }: Props) => {
  const [selectedColor, setSelectedColor] = useState<Color>(
    hexToRgb(question.colors[0])
  );
  const [sliderValue, setSliderValue] = useState<number>(Math.random());
  useEffect(() => {
    const color = interpolateColor(question.colors, sliderValue);
    setSelectedColor(color);
    onChange([[rgbToHex(color), 1]]);
  }, [sliderValue]);

  return (
    <View style={styles.container}>
      <Text style={styles.selectedColorText}>
        {getNameOfNearestColor(rgbToHex(selectedColor)).name}
      </Text>
      <View
        style={{
          width: 100,
          height: 100,
          backgroundColor: `rgb(${selectedColor.r},${selectedColor.g},${selectedColor.b})`,
          borderRadius: 10,
          marginBottom: 100,
        }}
      />
      <Slider
        style={styles.slider}
        minimumValue={0}
        maximumValue={1}
        step={0.01}
        value={sliderValue}
        onValueChange={setSliderValue}
        thumbTintColor={`rgb(${selectedColor.r},${selectedColor.g},${selectedColor.b})`}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  gradient: {
    width: "100%",
    height: 200,
    justifyContent: "center",
    alignItems: "center",
  },
  slider: {
    width: "80%",
  },
  selectedColorText: {
    ...typography.p3,
  },
});

export default ColorPickerQuestion;
