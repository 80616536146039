import React from "react";
import SearchableDropDown from "../react-native-searchable-dropdown-fixed";

export type Props = {
  selectedItems?: { id: number; name: string }[] | undefined;
  onItemSelect: (item: { name: string; id: number }) => void;
  onRemoveItem?: ((item: string, index: number) => void) | undefined;
  items: { id: number; name: string }[];
  placeholder: string;
};

const Dropdown = ({
  selectedItems = undefined,
  onItemSelect,
  onRemoveItem = undefined,
  items,
  placeholder,
}: Props) => {
  return (
    <SearchableDropDown
      multi={true}
      selectedItems={selectedItems}
      onItemSelect={onItemSelect}
      containerStyle={styles.dropdownContainer}
      onRemoveItem={onRemoveItem}
      itemStyle={styles.dropdownItem}
      itemTextStyle={styles.dropdownItemText}
      itemsContainerStyle={styles.dropdownItemContainer}
      items={items}
      defaultIndex={2}
      chip={true}
      resetValue={false}
      textInputProps={{
        placeholder: placeholder,
        underlineColorAndroid: "transparent",
        style: styles.textInputProps,
      }}
      listProps={{
        nestedScrollEnabled: true,
      }}
    />
  );
};

export default Dropdown;

const styles = {
  dropdownContainer: { padding: 5 },
  dropdownItem: {
    padding: 10,
    marginTop: 2,
    backgroundColor: "#ddd",
    borderColor: "#bbb",
    borderWidth: 1,
    borderRadius: 5,
  },
  dropdownItemText: { color: "#222" },
  dropdownItemContainer: { maxHeight: 140 },
  textInputProps: {
    padding: 12,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
  },
};
