import {
  LinkingOptions,
  NavigationContainer,
  NavigationContainerRef,
  getStateFromPath,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React, { useEffect } from "react";
import { Linking, View } from "react-native";
import StatusMessage from "../components/status-message/StatusMessage";
import SignInScreen from "../screens/sign-in-screen/SignInScreen";
import SignUpScreen from "../screens/sign-up-screen/SignUpScreen";
import SplashScreen from "../screens/splash-screen/SplashScreen";
import TosScreen from "../screens/tos-screen/TosScreen";
import AuthenticatedTabNavigator from "./AuthenticatedTabNavigator";
import HeaderAuthentication from "./HeaderAuthentication";
import { getPathFromState } from "@react-navigation/native";

import NetInfo from "@react-native-community/netinfo";
import { env } from "../../env";
import { logScreenView } from "../helpers/analytics";
import { quiz } from "../helpers/quiz";

import { useAppStatus, useAuth, useResources, useUserStore } from "../hooks";
import QuizScreen from "../screens/quiz-screen/QuizScreen";
import containers from "../theme/containers";
import NavigationParamList from "./NavigationParamList";
import { StyleGuideArticleScreen } from "../screens/style-guide-articles";
import HeaderAuthenticated from "./HeaderAuthenticated";
import SubscriptionsScreen from "../screens/subscriptions-screen";
import usePayments from "../hooks/usePayments";
import FavoritesStackNavigator from "./FavoritesStackNavigator";
import { LookCardV2 } from "../components/look-card-v2/LookCardV2";
import { FiltersI } from "../types/common/FiltersI";
import { Gender } from "../types/common/Gender";
import { LookI } from "../types/common/LookI";
import { Category } from "../types/common/ProductI";
import ModalRoot from "../components/modal-root/ModalRoot";

const Stack = createNativeStackNavigator<NavigationParamList>();

// type ScreenPathMap = Record<string, string>;

// const flattenConfig = (config: LinkingOptions<NavigationParamList>, prefix = ''): ScreenPathMap => {

//   return Object.keys(config).reduce((acc: ScreenPathMap, key: string) => {
//     const value = config[key];
//     const newPath = prefix ? `${prefix}/${key}` : key;

//     if (typeof value === 'object' && value !== null) {
//       // If the value is an object, recursively flatten it
//       return { ...acc, ...flattenConfig(value as LinkingOptions<NavigationParamList>['config']['screens'], newPath) };
//     } else {
//       // If the value is a string, it's a terminal node in the configuration
//       return { ...acc, [newPath]: value as string };
//     }
//   }, {});
// };

// const linkingOptions: LinkingOptions = {
//   // Your linking options go here
// };

// // Now use this function to flatten your configuration
// const pathToScreenMap = flattenConfig(linkingOptions.config.screens);

// const getScreenNameFromPath = (path: string): string | undefined => {
//   return pathToScreenMap[path];
// }
export const linkingOptions: LinkingOptions<NavigationParamList> = {
  config: {
    screens: {
      QuizScreenBeforeSignup: "/quiz",
      SignupScreen: "/signup",
      LoginScreen: "/login",
      TOS: "/tos",
      AuthenticatedTabNavigator: "/",
      StyleGuideArticle: "/style-guide-article",
      Subscription: "/subscription",
      Wardrobe: "/wardrobe",
      Main: {
        path: "main",
        screens: {
          HomeScreen: "/",
          StyleGuide: "/style-guide",
          ShopLook: "/shop-look",
          LookBook: "/look-book",
          CreateLook: "/create-look",
          InProgress: "/in-progress",
          StealLook: "/steal-look",
          DeckSwiper: "/deck-swiper",
          CapsuleWardrobe: "/capsule-wardrobe",
          LookBookSwiper: "/look-book-swiper",
          QuizScreen: "/quiz-screen",
          ColorSeasonGuide: "/color-season-guide",
          HairstyleGuide: "/hairstyle-guide",
          GenerateLook: "/generate-look",
          Chat: "/chat",
        },
      },
      Favorites: {
        path: "/favorites",
        screens: {
          FavoriteProducts: "/products",
          Favorites: "",
        },
      },

      Settings: {
        path: "/settings",
        screens: {
          Settings: "",
          PrivacyPolicy: "/privacy-policy",
          AboutScreen: "/about",
          ChangePassword: "/change-password",
          Feedback: "/feedback",
          Profile: {
            path: "/profile",
            screens: {
              Profile: "",
              ChangeProfileParameters: "/change-profile",
              QuizScreen: "/quiz-screen",
            },
          },
          QuizScreen: "/quiz-screen",
        },
      },
    },
  },
  getPathFromState(state, config) {
    let path = getPathFromState(state, config);
    const index = path.indexOf("?");
    if (index >= 0) {
      path = path.substr(0, index);
    }
    return path;
  },
  getStateFromPath(path, config) {
    return getStateFromPath(path, config);
  },
  prefixes: [
    "https://stylee.app",
    "styleeapp://",
    "https://stylee.app/webapp",
    "https://stylee.app/webapp/",
  ],
};

const AuthenticationStackNavigator = () => {
  const navigationRef =
    React.useRef<NavigationContainerRef<NavigationParamList>>(null);
  const { isLogin, subscribeAuthChange } = useAuth();
  const { isLoading } = useResources();
  const { user } = useUserStore();
  const { isPremium } = usePayments(user?.id);
  const { showMessage } = useAppStatus();

  useEffect(() => {
    const unsubscribe = subscribeAuthChange();
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      if (state.isConnected == false && state.isConnected != null) {
        showMessage("No internet connection", 5000000);
      }
      if (state.isConnected == true) {
        showMessage("", 1);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <View style={containers.rootContainer}>
      <NavigationContainer
        linking={linkingOptions}
        documentTitle={{
          formatter: (options, route) => `Stylee App`,
        }}
        ref={navigationRef}
        onStateChange={async (d) => {
          if (navigationRef.current?.getCurrentRoute()) {
            const currentRouteName =
              navigationRef.current.getCurrentRoute()?.name;
            const params = navigationRef.current.getCurrentRoute()?.params;
            logScreenView(currentRouteName as string, JSON.stringify(params));
          }
        }}
      >
        {!isLogin ? (
          <Stack.Navigator
            screenOptions={{
              header: HeaderAuthentication,
              headerShown: false,
            }}
          >
            <Stack.Group>
              {env.is_quiz_before && (
                <Stack.Screen
                  name="QuizScreenBeforeSignup"
                  component={QuizScreen}
                  initialParams={{
                    quiz: quiz,
                    isWelcomeScreen: true,
                    onExit: () => {
                      navigationRef.current?.navigate("LoginScreen");
                    },
                    onFinish: () => {
                      navigationRef.current?.navigate("SignupScreen");
                    },
                    exitText: "I already have an account",
                  }}
                />
              )}
              <Stack.Screen name="SignupScreen" component={SignUpScreen} />
              <Stack.Screen name="LoginScreen" component={SignInScreen} />
              <Stack.Screen name="TOS" component={TosScreen} />
            </Stack.Group>
          </Stack.Navigator>
        ) : (
          <Stack.Navigator
            screenOptions={{
              header: (props) => HeaderAuthenticated(props, user, isPremium),
              headerShown: false,
            }}
          >
            <Stack.Screen
              name="AuthenticatedTabNavigator"
              component={AuthenticatedTabNavigator}
            />
            <Stack.Screen
              name="StyleGuideArticle"
              component={StyleGuideArticleScreen}
              options={{
                headerShown: true,
              }}
            />
            <Stack.Screen
              name="Subscription"
              component={SubscriptionsScreen}
              options={{
                headerShown: true,
              }}
            />
            <Stack.Screen
              name="Favorites"
              component={FavoritesStackNavigator}
            />
          </Stack.Navigator>
        )}
      </NavigationContainer>
      <StatusMessage />
      <ModalRoot />
    </View>
  );
};

export default AuthenticationStackNavigator;
