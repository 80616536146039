import { Image, StyleSheet, Text, View } from "react-native";
import React from "react";
import { useUserStore } from "../../hooks";
import { getImage } from "../../helpers/resourceProvider";
import TabLink from "./TabLink";
import { styles } from "./styles";

const BodytypeGuide = () => {
  const { user } = useUserStore();
  if (!user) return [];
  const recommendations = user.recommendations;
  const { bodytype } = recommendations;

  const slides = [
    {
      slide: (
        <View>
          <Image
            source={{
              uri: "https://i.gyazo.com/4808c1ece4302380b54a13f3cd5df991.png",
            }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>Bodytype Guide</Text>
          <Text style={styles.description}>
            Dive into the styles and outfits tailored for your unique body type.
            Get recommendations from our AI-powered stylist!
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink
          img={{
            uri: "https://i.gyazo.com/4808c1ece4302380b54a13f3cd5df991.png",
          }}
          text="Welcome"
        />
      ),
    },
    {
      slide: (
        <View>
          {/* <Image
            source={{ uri: "" }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          /> */}
          <Text style={styles.title}>What to do</Text>
          <Text style={styles.description}>{bodytype.generalTips}</Text>
          <Text style={styles.description}>
            {bodytype.explanationBestOuterwear}
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink img={getImage("bestBodytype")} text="Best Recommendations" />
      ),
    },
    {
      slide: (
        <View>
          {/* <Image
            source={{ uri: "" }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          /> */}
          <Text style={styles.title}>What to Avoid</Text>
          <Text style={styles.description}>{bodytype.generalBadTips}</Text>
          <Text style={styles.description}>
            {bodytype.explanationWorstOuterwear}
          </Text>
        </View>
      ),
      tabLink: <TabLink img={getImage("worstBodytype")} text="What to Avoid" />,
    },
    // {
    //   slide: (
    //     <View>
    //       <Image
    //         source={{ uri: "" }}
    //         style={[styles.mainIllustration]}
    //         resizeMode="cover"
    //       />
    //       <Text style={styles.title}>Best Outerwear</Text>
    //       <Text style={styles.description}>
    //         {bodytype.explanationBestOuterwear}
    //       </Text>
    //     </View>
    //   ),
    //   tabLink: (
    //     <TabLink
    //       img={getImage("bestOuterwearBodytype")}
    //       text="Best Outerwear"
    //     />
    //   ),
    // },
    // {
    //   slide: (
    //     <View>
    //       <Image
    //         source={{ uri: "" }}
    //         style={[styles.mainIllustration]}
    //         resizeMode="cover"
    //       />
    //       <Text style={styles.title}>Worst Outerwear</Text>
    //       <Text style={styles.description}>
    //         {bodytype.explanationWorstOuterwear}
    //       </Text>
    //     </View>
    //   ),
    //   tabLink: (
    //     <TabLink
    //       img={getImage("worstOuterwearBodytype")}
    //       text="Outerwear to Avoid"
    //     />
    //   ),
    // },
    {
      slide: (
        <View>
          <Image
            source={getImage("thanks")}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>Thank You!</Text>
          <Text style={styles.description}>
            Thank you for exploring insights for your body type. Dress with
            confidence and showcase your best features!
          </Text>
        </View>
      ),
      tabLink: <TabLink img={getImage("thanks")} text="End" />,
    },
  ];

  return slides;
};

export default BodytypeGuide;
