import {
  FiltersI,
  PriceFilterI,
  BrandFilterI,
  StoreFilterI,
  ColorFilterI,
} from "../../types/common/FiltersI";
import { PriceFilter } from "./PriceFilter";
import { StoreFilter } from "./StoreFilter";
import { ColorFilter } from "./ColorFilter";
import { BrandFilter } from "./BrandFilter";

export class Filters implements FiltersI {
  constructor(
    public priceFilter: PriceFilterI,
    public brandFilter: BrandFilterI,
    public storeFilter: StoreFilterI,
    public colorFilter: ColorFilterI,
    public source: "store" | "wardrobe" | "all"
  ) {}

  static createEmpty() {
    return new Filters(
      new PriceFilter(),
      new BrandFilter(),
      new StoreFilter(),
      new ColorFilter(),
      "all"
    );
  }
}
