import { useFonts } from "expo-font";
import { Asset } from "expo-asset";
import { Image } from "react-native";
import { useEffect, useState } from "react";
import { images } from "../helpers/resourceProvider";
import { fonts } from "../theme";

function cacheImages(images: any[]) {
  return images.map((image) => {
    if (typeof image === "string") {
      return Image.prefetch(image);
    } else {
      return Asset.fromModule(image).downloadAsync();
    }
  });
}

const useResources = () => {
  let [isFontsLoaded] = useFonts(fonts);
  const [appIsReady, setAppIsReady] = useState(false);

  // Load any resources or data that you need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        const imageAssets = cacheImages([
          "https://i.gyazo.com/c257b8d13075fc975926f4c04d34a15d.png",
          "https://i.gyazo.com/efbb8ef38431e2918b22507bfb3d8041.png",
          "https://i.gyazo.com/e00dcc1dbe9b7f27853acd073563f17a.png",
          "https://i.gyazo.com/0e22c12d3d5421bbbbcc50613e57c5c4.png",
          "https://i.gyazo.com/4ef5c096ca9e52eb241e946cfeca33ae.png",
          "https://i.gyazo.com/88d8c77058b64ad120facbf28a1980b1.png",
          "https://i.gyazo.com/fb2889096f57200e3316f0dc881ef77b.png",
          "https://i.gyazo.com/064d534376d821f6d60ea02b99e387f7.png",
          ...Object.values(images),
        ]);

        await Promise.all([...imageAssets]);
      } catch (e) {
        // You might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setAppIsReady(true);
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  const isLoading = !isFontsLoaded && !appIsReady;
  return { isLoading };
};

export default useResources;
