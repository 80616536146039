import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthStateI {
  isLoggedIn: boolean;
  token: undefined | string;
}

const initialState: AuthStateI = {
  isLoggedIn: false,
  token: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setLoginStatus: (state, action: PayloadAction<{ value: boolean }>) => {
      state.isLoggedIn = action.payload.value;
    },
    setToken: (
      state,
      action: PayloadAction<{
        token: AuthStateI["token"];
      }>
    ) => {
      state.token = action.payload.token;
    },
  },
});

export const { setLoginStatus, setToken } = authSlice.actions;
export default authSlice.reducer;
