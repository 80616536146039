import React from "react";
import { Image, Text, View } from "react-native";
import { getImage } from "../../helpers/resourceProvider";
import { useUserStore } from "../../hooks";
import { StyleGuideArticleSlide } from "../../navigators/NavigationParamList";
import TabLink from "./TabLink";
import { styles } from "./styles";
import { typography } from "../../theme";

const HairstylesGuide = (): StyleGuideArticleSlide[] => {
  const { user } = useUserStore();

  if (!user) return [];
  const recommendations = user.recommendations;

  const { colors, kibbe, faceshape, bodytype } = recommendations;

  const slides = [
    {
      slide: (
        <View>
          <Image
            source={{
              uri: "https://i.gyazo.com/115913ce609523051d83e3075da5bab3.png",
            }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>Hairstyles Guide</Text>
          <Text style={styles.description}>
            Discover the best hairstyles, patterns, colors and more tailored for
            you!
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink
          img={{
            uri: "https://i.gyazo.com/115913ce609523051d83e3075da5bab3.png",
          }}
          text="Welcome"
        />
      ),
    },
    {
      slide: (
        <View>
          {/* <Image
            source={{ uri: "" }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          /> */}
          <Text style={styles.title}>Best Hairstyles</Text>
          {/* <View style={{ height: 10 }} /> */}
          <Text style={styles.description}>
            {faceshape.explanationBestHairstyle}
          </Text>
          <View style={{ height: 20 }} />

          <Text style={typography.h3}>Examples of best hairstyles</Text>
          <View style={{ height: 10 }} />

          <Text style={styles.description}>
            {faceshape.bestHairstyles.map((hairstyle, index) => (
              <Text>
                - {hairstyle} {"\n"}
              </Text>
            ))}
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink img={getImage("bestHairstyles")} text="Best Hairstyles" />
      ),
    },
    // {
    //   slide: (
    //     <View>
    //       <Image
    //         source={{ uri: "" }}
    //         style={[styles.mainIllustration]}
    //         resizeMode="cover"
    //       />
    //       <Text style={styles.title}>Examples of best haistyles</Text>
    //       <Text style={styles.description}>
    //         {faceshape.bestHairstyles.map((hairstyle) => (
    //           <Text>
    //             {hairstyle} {"\n"}
    //           </Text>
    //         ))}
    //       </Text>
    //     </View>
    //   ),
    //   tabLink: (
    //     <TabLink img={getImage("bestHairstyles")} text="Best Hairstyles" />
    //   ),
    // },

    {
      slide: (
        <View>
          {/* <Image
            source={{ uri: "" }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          /> */}

          <Text style={styles.title}>Worst Hairstyles</Text>
          <Text style={styles.description}>
            {faceshape.explanationWorstHairstyle}
          </Text>
          <View style={{ height: 20 }} />
          <Text style={typography.h3}>Examples of worst hairstyles</Text>
          <View style={{ height: 10 }} />
          <Text style={styles.description}>
            {faceshape.worstHairstyles.map((hairstyle, index) => (
              <Text>
                - {hairstyle} {"\n"}
              </Text>
            ))}
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink img={getImage("worstHairstyles")} text="Worst Hairstyles" />
      ),
    },
    // {
    //   slide: (
    //     <View>
    //       <Image
    //         source={{ uri: "" }}
    //         style={[styles.mainIllustration]}
    //         resizeMode="cover"
    //       />
    //       <Text style={styles.title}>Examples of worst haistyles</Text>
    //       <Text style={styles.description}>
    //         {faceshape.worstHairstyles.map((hairstyle) => (
    //           <Text>
    //             {hairstyle} {"\n"}
    //           </Text>
    //         ))}
    //       </Text>
    //     </View>
    //   ),
    //   tabLink: (
    //     <TabLink img={getImage("bestHairstyles")} text="Best Hairstyles" />
    //   ),
    // },
    {
      slide: (
        <View>
          <Image
            source={getImage("thanks")}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>Thank You!</Text>
          <Text style={styles.description}>
            Thanks for exploring HairstylesGuide! Hope you discovered amazing
            styles.
          </Text>
        </View>
      ),
      tabLink: <TabLink img={getImage("thanks")} text="End" />,
    },
  ];

  return slides;
};

export default HairstylesGuide;
