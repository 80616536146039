import { StyleSheet, Image } from "react-native";
import { FiltersI } from "../../types/common/FiltersI";
import { LookI } from "../../types/common/LookI";
import ProductI from "../../types/common/ProductI";
import { ProductCardV2 } from "../product-card/ProductCardV2";
import { PrimaryButton, SecondaryButton } from "../buttons";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import NavigationParamList from "../../navigators/NavigationParamList";
import { useEffect, useState } from "react";
import { Pressable, ScrollView, View } from "native-base";
import PremiumLabel from "../premium-label";
import { useUserStore } from "../../hooks";
import { env } from "../../../env";
import { getImage } from "../../helpers/resourceProvider";
import Loader from "../loader";
import { typography } from "../../theme";
import { useModal } from "../../hooks/useModal";
import { usePostLookMutation } from "../../store/api";

export type LookCardV2Props = {
  look: LookI;
  filters?: FiltersI;
  style?: any;
};

export const LookCardV2 = ({ look, style }: LookCardV2Props) => {
  // console.log("look", look);
  const [lookLocal, setLook] = useState<LookI>(look);
  const { showModal } = useModal();
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();

  const [isLoading, setIsLoading] = useState<{
    isLoading: boolean;
    isInitialized: boolean;
  }>({
    isLoading: false,
    isInitialized: false,
  });
  const [image, setImage] = useState<string>("");
  const { user, addFavoriteLook } = useUserStore();
  const [createLook, responseCreateLook] = usePostLookMutation();

  const generateTryOn = async () => {
    if (user?.params) {
      setIsLoading({
        isLoading: true,
        isInitialized: true,
      });
      let description = lookLocal.lookItems.reduce(
        (acc, item) => `${acc} ${item.product?.description}, `,
        ""
      );

      description = `a ${user.params.gender} model with skin color: ${
        user.params.skincolor && user.params.skincolor[0][0]
      }, 
        haircolor: ${user.params.haircolor && user.params.haircolor[0][0]}, 
        boddytype: ${user.params.bodytype && user.params.bodytype},
        
        wearing ${description}
        standing on floor
        only one person, full body shot, full height, from head to feet, front view, fashnion photo, photorealistic, HD
        `;

      const response = await fetch(`${env.BASE_URL}imgs/try-on`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          description: description,
        }),
      });
      const data = await response.json();
      setImage(data.data);
      setIsLoading({
        isLoading: false,
        isInitialized: true,
      });
    }
  };

  // useEffect(() => {
  //   generateLook();
  // }, [lookLocal]);

  return (
    <View style={[styles.container, style]}>
      <SecondaryButton
        text={"💾 Save"}
        onPress={async () => {
          await createLook({ look: lookLocal, isCreateByImg: false });
          addFavoriteLook(look);
        }}
      />

      {isLoading.isInitialized && isLoading.isLoading ? (
        <Loader
          messages={["Generating try on...", "Hang on a sec...", "Almost done"]}
          time={25000}
          sections={10}
          textStyle={{
            ...typography.p1,
          }}
          type={"flow"}
          containerStyle={{
            marginTop: 20,
            alignSelf: "center",
            width: "70%",
          }}
          progressBarStyle={{
            height: 20,
          }}
        />
      ) : (
        <Pressable my={2} onPress={() => generateTryOn()}>
          <PremiumLabel
            text={"Try on"}
            style={{ width: "100%", height: 48, borderRadius: 8 }}
            textStyle={{ fontSize: 16 }}
          />
        </Pressable>
      )}

      {image != "" && (
        <View
          style={{ width: "100%", aspectRatio: 1 }}
          my={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image
            source={{ uri: image }}
            style={{ width: "100%", height: "100%", resizeMode: "cover" }}
          />
        </View>
      )}
      {/* <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={true}
        style={{ width: "100%" }}
      > */}
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {lookLocal.lookItems.map((item, index) => (
          <View
            style={{
              width: "45%",
              marginBottom: 8,
              marginRight: 8,
              // backgroundColor: "red",
            }}
          >
            <ProductCardV2
              product={item.product as ProductI}
              key={index}
              style={{
                width: "100%",
                //  backgroundColor: "green"
              }}
            />
            <Pressable
              position="absolute"
              top={2}
              left={2}
              onPress={() =>
                navigation.navigate("ChangeProduct", {
                  onChanged: (product: ProductI) => {
                    console.log("product", product);
                    setLook((prev) => ({
                      ...prev,
                      lookItems: prev.lookItems.map((item, i) =>
                        i === index ? { ...item, product: product } : item
                      ),
                    }));
                    navigation.goBack();
                  },
                  lookItem: item,
                })
              }
            >
              <Image
                source={getImage("filtersIcon")}
                style={{ width: 28, height: 28 }}
              />
            </Pressable>
          </View>
        ))}
      </View>
      {/* </ScrollView> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    borderRadius: 20,
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "space-between",
    // borderColor: colors.gray,
    // borderWidth: 2,
    // padding: 10,
    // backgroundColor: colors.lightgray,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
  },
  image: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
  },
  textContainer: {
    padding: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
  },
  description: {
    fontSize: 14,
    color: "gray",
  },
});
