import React, { useState } from "react";
import { Image, Platform, StyleSheet, TextInput, View } from "react-native";
import { getImage } from "../../helpers/resourceProvider";
import { spacing, typography } from "../../theme";
import colors from "../../theme/colors";

export type Props = {
  email: string;
  setEmail: (email: string) => void;
};
const InputEmail = ({ email, setEmail }: Props) => {
  const [isFocused, setFocused] = useState(false);
  return (
    <View
      style={[
        styles.textInputContainer,
        isFocused ? styles.focused : styles.blurred,
      ]}
    >
      <Image source={getImage("mailLine")} style={styles.emailLeftIcon} />
      <TextInput
        style={[styles.inputEmail, Platform.OS == "web" && { outline: "none" }]}
        placeholder={"Email"}
        keyboardType="email-address"
        value={email}
        onChangeText={(text) => setEmail(text)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </View>
  );
};

export default InputEmail;

const styles = StyleSheet.create({
  textInputContainer: {
    ...typography.p1,
    borderWidth: 1,
    borderColor: colors.gray,

    borderRadius: spacing.xs,
    height: 50,
    alignSelf: "stretch",
    marginBottom: 15,
  },
  focused: {
    borderColor: colors.dark,
    borderWidth: 1,
    backgroundColor: colors.bg,
  },
  blurred: {
    borderColor: colors.gray,
    borderWidth: 1,
  },
  inputEmail: {
    padding: 15,
    width: "100%",
    paddingLeft: 50,
  },
  emailLeftIcon: {
    position: "absolute",
    top: 15,
    left: 15,
    height: 20,
    width: 20,
  },
});
