import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useUserStore } from "../hooks";
import usePayments from "../hooks/usePayments";
import { AddNewItemScreen } from "../screens/add-new-item-screen";
import WardrobeScreen from "../screens/wardrobe-screen/WardrobeScreen";
import HeaderAuthenticated from "./HeaderAuthenticated";
import NavigationParamList from "./NavigationParamList";

const Stack = createNativeStackNavigator<NavigationParamList>();

const WardrobeStackNavigator = () => {
  const { user } = useUserStore();
  const { isPremium } = usePayments(user?.id);
  return (
    <Stack.Navigator
      screenOptions={{
        header: (args) => HeaderAuthenticated(args, user, isPremium),
        headerShown: true,
      }}
    >
      <Stack.Screen name="Wardrobe" component={WardrobeScreen} />
      <Stack.Screen name="AddNewItem" component={AddNewItemScreen} />
    </Stack.Navigator>
  );
};

export default WardrobeStackNavigator;
