import * as analytics from "firebase/analytics";
import * as auth from "firebase/auth";
import * as firebase from "firebase/app";
import { env } from "../../env";
import { Gender } from "../types/common/Gender";
import { UserI } from "../types/common/UserI";

class FirebaseService {
  static firebaseApp: firebase.FirebaseApp;

  static async init() {
    FirebaseService.firebaseApp = firebase.initializeApp(env.firebase_config);
  }

  static getGoogleAuthProvider() {
    return auth.GoogleAuthProvider;
  }

  static getEmailAuthProvider() {
    return auth.EmailAuthProvider;
  }

  static getFacebookAuthProvider() {
    return auth.FacebookAuthProvider;
  }
  static getAuth() {
    return auth.getAuth(FirebaseService.firebaseApp);
  }

  static onAuthStateChanged(
    nextOrObserver: auth.NextOrObserver<auth.User>,
    error?: auth.ErrorFn,
    completed?: auth.CompleteFn
  ) {
    return auth.onAuthStateChanged(
      FirebaseService.getAuth(),
      nextOrObserver,
      error,
      completed
    );
  }

  static reauthenticateWithCredential(
    credential: auth.AuthCredential
  ): Promise<auth.UserCredential> {
    return auth.reauthenticateWithCredential(
      FirebaseService.getAuth().currentUser as auth.User,
      credential
    );
  }

  static updatePassword(password: string) {
    return auth.updatePassword(
      FirebaseService.getAuth().currentUser as auth.User,
      password
    );
  }

  static signOut() {
    return auth.signOut(FirebaseService.getAuth());
  }

  static sendPasswordResetEmail(email: string) {
    return auth.sendPasswordResetEmail(FirebaseService.getAuth(), email);
  }

  static signInWithCredential(credential: auth.AuthCredential) {
    return auth.signInWithCredential(FirebaseService.getAuth(), credential);
  }

  static signInWithEmailAndPassword(email: string, password: string) {
    return auth.signInWithEmailAndPassword(
      FirebaseService.getAuth(),
      email,
      password
    );
  }

  static createUserWithEmailAndPassword(email: string, password: string) {
    auth
      .createUserWithEmailAndPassword(
        FirebaseService.getAuth(),
        email,
        password
      )
      .then((userCredential) => {
        console.log("Created user", userCredential);
      })
      .catch((error) => {
        console.log(error);
        if (error.code === "auth/email-already-in-use") {
          console.log("USER ALREADYY EXISTS");
        }
      });
  }

  static setUserProperties(gender: Gender, age: number, id: UserI["id"]) {
    analytics.setUserProperties(analytics.getAnalytics(), {
      user_gender: gender,
      user_age: age,
      user_uid: id,
    });
  }

  static logEvent(eventName: string, params?: any) {
    analytics.logEvent(analytics.getAnalytics(), eventName as string, params);
  }
}

FirebaseService.init();

export default FirebaseService;
