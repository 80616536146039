import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import {
  useGetUserQuery,
  usePostUserParamsMutation,
  usePostFavoriteProductMutation,
  useDeleteFavoriteProductMutation,
  usePostFavoriteLookMutation,
  useDeleteFavoriteLookMutation,
  useSetUserPhotoMutation,
  useGetUserPhotoQuery,
} from "../store/api";
import { UserStoreI } from "../types/UserStoreI";
import { LookI } from "../types/common/LookI";
import ProductI from "../types/common/ProductI";
import { UserI } from "../types/common/UserI";
import { UserParamsI } from "../types/common/UserParamsI";
import useAppStatus from "./useAppStatus";

const useUserStore = (): UserStoreI => {
  const token = useSelector((state: RootState) => state.auth.token);
  const {
    data,
    refetch: refetchUser,
    error: errorGetUser,
  } = useGetUserQuery(undefined, {
    skip: token == undefined,
    pollingInterval: 10000,
  });
  const [postUserParams, resultPostUserParams] = usePostUserParamsMutation();
  const [postFavoriteProduct, resultPostFavoriteProduct] =
    usePostFavoriteProductMutation();
  const [deleteFavoriteProduct, resultDeleteFavoriteProduct] =
    useDeleteFavoriteProductMutation();

  const [postFavoriteLook, resultPostFavoriteLook] =
    usePostFavoriteLookMutation();
  const [deleteFavoriteLook, resultDeleteFavoriteLook] =
    useDeleteFavoriteLookMutation();
  const [setPhotoMutation, resultSetPhoto] = useSetUserPhotoMutation();
  const {
    data: userPhoto,
    error: errorGetUserPhoto,
    refetch: refetchUserPhoto,
  } = useGetUserPhotoQuery(undefined, {
    skip: true,
  });
  const { showMessage } = useAppStatus();

  const setPhotoResult = () => {
    let status: setPhotoResult["status"];
    if (resultSetPhoto.status == QueryStatus.uninitialized)
      status = "UNINITIALIZED";
    else if (resultSetPhoto.status == QueryStatus.pending)
      status = "PROCESSING";
    else status = "PROCESSED";

    const result: setPhotoResult = { status: status };
    if (resultSetPhoto.data != undefined)
      result["result"] = resultSetPhoto.data;
    return result;
  };

  useEffect(() => {
    if (errorGetUser) {
      // showMessage("Internal error, please try again later or contact us.");
      setTimeout(refetchUser, 5000);
    }
    if (errorGetUserPhoto) {
      // showMessage("Internal error, please try again later or contact us.");
      setTimeout(refetchUserPhoto, 5000);
    }
  }, [errorGetUser]);

  const setUserParams = async (userParams: UserParamsI): Promise<void> => {
    postUserParams(userParams);
  };
  const create = (user: UserI): void => {};

  const resetUserParams = async () => {
    if (data) {
      postUserParams({ ...data.user.params, isSetup: false });
    }
  };
  const changeUserParam = async (userParam: Partial<UserParamsI>) => {
    if (data?.user) {
      postUserParams(userParam);
    }
  };

  const addFavoriteProduct = async (product: ProductI): Promise<void> => {
    if (data?.user) {
      postFavoriteProduct(product.id);
    }
  };
  const removeFavoriteProduct = async (product: ProductI): Promise<void> => {
    if (data?.user) {
      deleteFavoriteProduct(product.id);
    }
  };

  const addFavoriteLook = async (look: LookI): Promise<void> => {
    if (data?.user) {
      postFavoriteLook(look.id);
    }
  };
  const removeFavoriteLook = async (look: LookI): Promise<void> => {
    if (data?.user) {
      deleteFavoriteLook(look.id);
    }
  };

  const setPhoto = (photo: string) => {
    setPhotoMutation(photo);
  };
  const getPhoto = () => {
    return userPhoto;
  };

  return {
    user: data?.user,
    rules: data?.rules,
    create,
    resetUserParams,
    changeUserParam,
    addFavoriteProduct,

    removeFavoriteProduct,
    addFavoriteLook,
    removeFavoriteLook,
    setPhoto,
    getPhoto,
    setPhotoResult: setPhotoResult(),
  };
};

export default useUserStore;
