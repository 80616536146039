import { Image, Text, View } from "react-native";
import { getImage } from "../../helpers/resourceProvider";
import useUserStore from "../../hooks/useUserStore";
import { StyleGuideArticleSlide } from "../../navigators/NavigationParamList";
import { useGetAllStyleGuideQuery } from "../../store/api";
import TabLink from "./TabLink";
import { styles } from "./styles";
import { typography } from "../../theme";

const PrintsPatternsGuide = (): StyleGuideArticleSlide[] => {
  const { user, getPhoto } = useUserStore();
  if (!user) return [];
  const recommendations = user.recommendations;

  const { kibbe } = recommendations;

  const slides = [
    {
      slide: (
        <View>
          <Image
            source={{
              uri: "https://i.gyazo.com/ad8bd712cbae957b7cdca8e374eea50a.png",
            }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>Prints and Patterns Guide</Text>
          <Text style={styles.description}>
            Explore the best and worst patterns tailored for you by our
            AI-powered stylist!
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink
          img={{
            uri: "https://i.gyazo.com/ad8bd712cbae957b7cdca8e374eea50a.png",
          }}
          text="Welcome"
        />
      ),
    },
    {
      slide: (
        <View>
          {/* <Image
            source={{ uri: "" }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          /> */}
          <Text style={styles.title}>Best Patterns</Text>
          <Text style={styles.description}>
            {kibbe.explanationBestPatterns}
          </Text>
          <View style={{ height: 20 }} />
          <Text style={typography.h3}>Examples of Best Patterns</Text>
          <View style={{ height: 10 }} />
          <Text style={styles.description}>
            {kibbe.bestPatterns.map((pattern) => (
              <Text key={pattern}>
                - {pattern} {"\n"}
              </Text>
            ))}
          </Text>
        </View>
      ),
      tabLink: <TabLink img={getImage("bestPatterns")} text="Best Patterns" />,
    },
    // {
    //   slide: (
    //     <View>
    //       <Image
    //         source={{ uri: "" }}
    //         style={[styles.mainIllustration]}
    //         resizeMode="cover"
    //       />
    //       <Text style={styles.title}>Examples of Best Patterns</Text>
    //       <Text style={styles.description}>
    //         {kibbe.bestPatterns.map((pattern) => (
    //           <Text key={pattern}>
    //             {pattern} {"\n"}
    //           </Text>
    //         ))}
    //       </Text>
    //     </View>
    //   ),
    //   tabLink: (
    //     <TabLink
    //       img={getImage("examplesBestPatterns")}
    //       text="Best Patterns Examples"
    //     />
    //   ),
    // },
    {
      slide: (
        <View>
          {/* <Image
            source={{ uri: "" }}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          /> */}
          <Text style={styles.title}>Worst Patterns</Text>
          <Text style={styles.description}>
            {kibbe.explanationWorstPatterns}
          </Text>
          <View style={{ height: 20 }} />
          <Text style={typography.h3}>Examples of Worst Patterns</Text>
          <View style={{ height: 10 }} />
          <Text style={styles.description}>
            {kibbe.worstPatterns.map((pattern) => (
              <Text key={pattern}>
                - {pattern} {"\n"}
              </Text>
            ))}
          </Text>
        </View>
      ),
      tabLink: (
        <TabLink img={getImage("worstPatterns")} text="Worst Patterns" />
      ),
    },
    // {
    //   slide: (
    //     <View>
    //       <Image
    //         source={{ uri: "" }}
    //         style={[styles.mainIllustration]}
    //         resizeMode="cover"
    //       />
    //       <Text style={styles.title}>Examples of Worst Patterns</Text>
    //       <Text style={styles.description}>
    //         {kibbe.worstPatterns.map((pattern) => (
    //           <Text key={pattern}>
    //             {pattern} {"\n"}
    //           </Text>
    //         ))}
    //       </Text>
    //     </View>
    //   ),
    //   tabLink: (
    //     <TabLink
    //       img={getImage("examplesWorstPatterns")}
    //       text="Worst Patterns Examples"
    //     />
    //   ),
    // },
    {
      slide: (
        <View>
          <Image
            source={getImage("thanks")}
            style={[styles.mainIllustration]}
            resizeMode="cover"
          />
          <Text style={styles.title}>Thank You!</Text>
          <Text style={styles.description}>
            Thanks for exploring Best and Worst Prints and Patterns! Discover
            patterns that truly fit your style.
          </Text>
        </View>
      ),
      tabLink: <TabLink img={getImage("thanks")} text="End" />,
    },
  ];

  return slides;
};

export default PrintsPatternsGuide;
