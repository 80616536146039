import { useEffect, useState } from "react";
import { Platform } from "react-native";
import Purchases, {
  PurchasesOffering,
  PurchasesPackage,
} from "react-native-purchases";
import { useDispatch, useSelector } from "react-redux";
import { setIsPremium } from "../store/reducers/userInfoReducer";

import { RootState } from "../store";
import useUserStore from "./useUserStore";
import useAuth from "./useAuth";
import { env } from "../../env";
import { openBrowserAsync } from "expo-web-browser";
import { UserI } from "../types/common/UserI";

const APIKeys = {
  apple: "appl_GvOQGijsWUwaHwsJvutyXtZupUW",
  google: "your_revenuecat_google_api_key",
  web: "strp_aeshJscmMzPzsdUEnamTxFJhoho",
};

const usePayments = (uid: UserI["id"] | undefined) => {
  const { user } = useUserStore();
  const { token } = useAuth();
  let isPremium = useSelector((state: RootState) => state.userInfo.isPremium);
  if (Platform.OS == "web") {
    isPremium = user?.hasPaid ?? false;
  }
  const dispatch = useDispatch();
  const [currentOffering, setCurrentOffering] =
    useState<PurchasesOffering | null>(null);

  const [customerInfo, setCustomerInfo] = useState<any>(null);
  useEffect(() => {
    dispatch(
      setIsPremium({
        value:
          customerInfo?.entitlements?.all?.pro?.expirationDateMillis >
          Date.now(),
      })
    );
  }, [customerInfo]);

  const getCustomerInfo = async () => {
    const info = await Purchases.getCustomerInfo();
    setCustomerInfo(info);
  };

  const configurePurchases = async () => {
    try {
      // Purchases.setDebugLogsEnabled(true);
      if (Platform.OS == "android") {
        Purchases.configure({ apiKey: APIKeys.google, appUserID: uid });
      } else if (Platform.OS == "ios") {
        Purchases.configure({ apiKey: APIKeys.apple, appUserID: uid });
      } else if (Platform.OS == "web") {
        setCurrentOffering({
          availablePackages: [
            {
              identifier: "pro",
              packageType: 0,
              priceId: "P1M5.99",
              product: {
                subscriptionPeriod: "P1M",
                price: 5.99,
                currencyCode: "USD",
              },
            },
            {
              identifier: "pro",
              priceId: "P1Y49.99",
              packageType: 0,
              product: {
                subscriptionPeriod: "P1Y",
                price: 49.99,
                currencyCode: "USD",
              },
            },
          ],
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const fetchOfferings = async () => {
      try {
        const offerings = await Purchases.getOfferings();
        setCurrentOffering(offerings.current);
      } catch (e) {
        console.log(e);
      }
    };
    configurePurchases();
    fetchOfferings();
    getCustomerInfo();
  }, []);

  const makePurchase = async (purhasePackage: PurchasesPackage) => {
    if (Platform.OS == "web") {
      if (!user) return;
      console.log("purchasePackage", purhasePackage);
      const res = await fetch(
        env.BASE_URL + "payments/create-checkout-session",
        {
          method: "POST",
          body: JSON.stringify({
            priceId: purhasePackage.priceId,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res);

      openBrowserAsync((await res.json()).sessionUrl);

      return;
    }

    let res;
    try {
      if (!currentOffering) {
        return;
      }
      res = await Purchases.purchasePackage(purhasePackage);
    } catch (e) {}

    return res;
  };

  return {
    makePurchase,
    currentOffering,
    customerInfo,
    getCustomerInfo,
    isPremium,
  };
};

export default usePayments;
