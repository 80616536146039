import { NavigationProp, useNavigation } from "@react-navigation/native";
import * as WebBrowser from "expo-web-browser";
import React from "react";
import { Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import ModalDialogue from "../../components/modal-dialogue/ModalDialogue";
import SettingsItem from "../../components/settings-item/SettingsItem";

import { getImage } from "../../helpers/resourceProvider";
import { useAuth } from "../../hooks";
import useUserStore from "../../hooks/useUserStore";
import colors from "../../theme/colors";
import containers from "../../theme/containers";
import { typography } from "../../theme";
import FirebaseService from "../../helpers/FirebaseService";
import { SettingsNavigatorParamList } from "../../navigators/SettingsStackNavigator";
import { openBrowserAsync } from "expo-web-browser";

const SettingsScreen = () => {
  const navigation =
    useNavigation<NavigationProp<SettingsNavigatorParamList>>();
  const { resetUserParams } = useUserStore();
  const { signOut, deleteAccount } = useAuth();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState(false);
  const isMobile = Platform.OS != "web";

  return (
    <ScrollView style={styles.scrollView}>
      <View style={styles.container}>
        {/* <Text
          style={[typography.h1, { marginBottom: 30, alignSelf: "center" }]}
        >
          My Settings
        </Text> */}

        <View style={{ marginBottom: 20 }}>
          <Text style={[typography.h3, { marginBottom: 10 }]}>Account</Text>

          <SettingsItem
            image={getImage(`settings_profile`)}
            name="Profile"
            details="Profile info"
            onPress={() => navigation.navigate("Profile")}
          />

          <SettingsItem
            image={getImage(`settings_subscriptions`)}
            name="Subscription"
            details="Get Premium Features"
            onPress={() =>
              navigation.navigate("Subscription", { context: "settings" })
            }
          />
          <SettingsItem
            image={getImage(`settings_password`)}
            name="Password"
            details="Change your current password"
            onPress={() => navigation.navigate("ChangePassword")}
          />
        </View>
        {/* {isMobile && (
          <View style={{ marginBottom: 20 }}>
            <Text style={[typography.h3, { marginBottom: 10 }]}>
              Notification Settings
            </Text>
            <SettingsItem
              image={getImage(`settings_notifications`)}
              name="Push Notifications"
              details="Turn On or Off Notifications"
              onPress={() => navigation.navigate("InProgress")}
            />
          </View>
        )} */}

        <View style={{ marginBottom: 20 }}>
          <Text style={[typography.h3, { marginBottom: 10 }]}>General</Text>
          <SettingsItem
            image={getImage(`settings_about`)}
            name="About"
            details="Learn more about us"
            onPress={() => navigation.navigate("AboutScreen")}
          />
          <SettingsItem
            image={getImage(`settings_thanks`)}
            name="Rate us"
            details="Rate & Review"
            onPress={() =>
              WebBrowser.openBrowserAsync("https://www.trustpilot.com/")
            }
          />

          <SettingsItem
            image={getImage(`settings_tos`)}
            name="Terms Of Service"
            details="Legal agreements"
            onPress={() => openBrowserAsync("https://stylee.app/tos.html")}
          />
          <SettingsItem
            image={getImage(`settings_privacy`)}
            name="Privacy Policy"
            details="Check our policy"
            onPress={() =>
              openBrowserAsync("https://stylee.app/privacy-policy.html")
            }
          />

          <SettingsItem
            image={getImage(`settings_delete`)}
            name="Delete Account"
            details="delete account"
            onPress={() => setIsDeleteModalVisible(true)}
          />
          <SettingsItem
            image={getImage(`settings_logout`)}
            name="Log Out"
            details="See you later"
            onPress={FirebaseService.signOut}
          />
        </View>
      </View>
      <ModalDialogue
        title="Delete Account"
        text="Are you sure you want to delete your account? All your data will be lost."
        onPress={() => {
          deleteAccount();
          signOut();
        }}
        setModalVisible={setIsDeleteModalVisible}
        modalVisible={isDeleteModalVisible}
      />
    </ScrollView>
  );
};

export default SettingsScreen;

const styles = StyleSheet.create({
  container: { ...containers.screenContainer },
  scrollView: { flex: 1, paddingTop: 20, backgroundColor: colors.bg },
});
