import React from "react";
import {
  Modal,
  Pressable,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { useModal } from "../../hooks/useModal";
import colors from "../../theme/colors";
import { theme } from "../../theme/defaultTheme";
import PrimaryButton from "../buttons/PrimaryButton";

export type Props = {
  setModalVisible: (modalVisible: boolean) => void;
  modalVisible: boolean;
  onPress: () => void;
  title: string;
  text: string;
};

const ModalRoot = () => {
  const { modal, showModal, hideModal } = useModal();
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modal.isShowing}
      onRequestClose={() => {
        hideModal();
      }}
    >
      <Pressable
        style={styles.centeredView}
        onPress={() => {
          hideModal();
        }}
      >
        <TouchableWithoutFeedback>
          <View style={[styles.modalView]}>
            {modal.content && modal.content}
          </View>
        </TouchableWithoutFeedback>
      </Pressable>
    </Modal>
  );
};

export default ModalRoot;

const styles = StyleSheet.create({
  welcomeTextContainer: {
    alignSelf: "flex-start",
    marginBottom: 50,
  },
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: colors.bg,
    alignItems: "center",
    minHeight: 550,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
    backgroundColor: "rgba(0,0,0, 0.40)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    maxWidth: 400,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  heroImage: {
    resizeMode: "contain",
    width: "100%",
    height: 400,
  },
  hero: {
    overflow: "hidden",
    width: "100%",
    height: "50%",
    top: 20,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonsContainer: {
    width: "100%",
    height: "40%",
    flexDirection: "column",
    justifyContent: "flex-start",
  },

  image: {
    marginTop: 0,
    resizeMode: "contain",
    width: 300,
    height: 200,
  },
  text: {
    fontFamily: theme.fontPrimaryBold,
    fontWeight: "bold",
    color: colors.bg,
    marginTop: 10,
    fontSize: 20,
    textTransform: "capitalize",
  },
  label: {
    textTransform: "uppercase",
    padding: 15,
    textAlign: "center",
    color: "gray",
  },
});
