import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { Image, ScrollView, StyleSheet, Text, View } from "react-native";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import CameraComponent from "../../components/camera-component";
import { Gender } from "../../types/common/Gender";
import { Style } from "../../types/common/LookI";
import { Look } from "../../entities/common/Look";
import { randomInteger } from "../../helpers/RandomInteger";
import { getImage } from "../../helpers/resourceProvider";
import { useLookStore } from "../../hooks";
import useUserStore from "../../hooks/useUserStore";
import { typography } from "../../theme";
import colors from "../../theme/colors";
import containers from "../../theme/containers";
import NavigationParamList from "../../navigators/NavigationParamList";

const StealLookScreen = () => {
  const [image, setImage] = useState("");
  const { createLook, createLookResult } = useLookStore();
  const [style, setStyle] = useState<Style>("CASUAL");
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const { user } = useUserStore();

  const createLookByImg = () => {
    createLook(
      new Look(
        style,
        user?.params.gender as Gender,
        randomInteger(0, Number.MAX_SAFE_INTEGER),
        "",
        "",
        image,
        [],
        [],
        2022,
        isPublic,
        0,
        1
      ),
      true
    );
  };

  useEffect(() => {
    if (createLookResult.status == "PROCESSED") {
      if (createLookResult.look)
        navigation.navigate("ShopLook", { look: createLookResult.look });
    }
  }, [createLookResult]);

  const isImage = image != "";
  const imageSource = isImage
    ? { uri: image }
    : { uri: getImage("choosePhoto") };
  return (
    <ScrollView style={styles.scrollView}>
      <View style={styles.container}>
        {createLookResult.status != "PROCESSING" ? (
          <View>
            <Text style={styles.text}>
              Choose a look you like and I will give you similar items!
            </Text>
            <View style={{ height: 500 }}>
              {isImage ? (
                <Image
                  source={imageSource}
                  style={
                    isImage
                      ? styles.image
                      : {
                          width: 100,
                          height: 100,
                          left: "40%",
                          top: "40%",
                          position: "absolute",
                        }
                  }
                />
              ) : (
                <CameraComponent
                  onLoad={(photo: string) => {
                    setImage(photo);
                  }}
                  style={{
                    marginBottom: 60,
                    height: "100%",
                  }}
                />
              )}
            </View>

            <View style={styles.isPublicArea}>
              <BouncyCheckbox
                isChecked={isPublic}
                onPress={(isChecked: boolean) => {
                  setIsPublic(!isPublic);
                }}
              />
              <Text style={styles.p2}>Make it public?</Text>
            </View>
            <PrimaryButton text="Steal" onPress={createLookByImg} />
          </View>
        ) : (
          createLookResult.status == "PROCESSING" && (
            <Image
              source={getImage("cameraProcessing")}
              style={{
                width: 100,
                height: 100,
                left: "40%",
                top: "40%",
                position: "absolute",
              }}
            />
          )
        )}
      </View>
    </ScrollView>
  );
};

export default StealLookScreen;

const styles = StyleSheet.create({
  scrollView: { flex: 1, paddingTop: 20, backgroundColor: colors.bg },

  container: {
    ...containers.screenContainer,
  },
  isPublicArea: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 20,
  },
  image: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  imageContainer: {
    width: "100%",
    height: 400,
    marginBottom: 20,
  },
  text: {
    ...typography.h2,
  },
  p2: {
    ...typography.p2,
  },
});
