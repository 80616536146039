import { Gender } from "./Gender";
import { UserI } from "./UserI";

export default interface ProductI {
  id: number;
  name: string;
  price: number;
  description: string;
  keywords: string[];
  color: string[];
  url: string;
  image: string[];
  category: Category;
  brand: string;
  store: string;
  country: string;
  gender: Gender;
  articleId: string;
  createdAt: Date;
  currency: string;
  isPublic: boolean;
  ownerId?: UserI["id"];
}

export enum Category {
  SHIRT_BLOUSE = "shirt, blouse",
  TOP_T_SHIRT_SWEATSHIRT = "top, t-shirt, sweatshirt",
  SWEATER = "sweater",
  CARDIGAN = "cardigan",
  JACKET = "jacket",
  VEST = "vest",
  PANTS = "pants",
  SHORTS = "shorts",
  SKIRT = "skirt",
  COAT = "coat",
  DRESS = "dress",
  JUMPSUIT = "jumpsuit",
  CAPE = "cape",
  GLASSES = "glasses",
  HAT = "hat",
  HEADBAND_HEAD_COVERING_HAIR_ACCESSORY = "headband, head covering, hair accessory",
  TIE = "tie",
  GLOVE = "glove",
  WATCH = "watch",
  BELT = "belt",
  TIGHTS_STOCKINGS = "tights, stockings",
  SOCK = "sock",
  SHOE = "shoe",
  BAG_WALLET = "bag, wallet",
  SCARF = "scarf",
  UMBRELLA = "umbrella",
  BRA = "bra",
  BRACELET = "bracelet",
  JEWELLERY = "jewellery",
  BAG = "bag",
  OTHER = "other",
}

const categoryClusters: { [key: string]: Category[] } = {
  tops: [
    Category.SHIRT_BLOUSE,
    Category.TOP_T_SHIRT_SWEATSHIRT,
    Category.SWEATER,
    Category.CARDIGAN,
    Category.JACKET,
    Category.VEST,
    Category.CAPE,
    Category.BRA,
  ],
  bottoms: [
    Category.PANTS,
    Category.SHORTS,
    Category.SKIRT,
    Category.TIGHTS_STOCKINGS,
    Category.SOCK,
  ],
  shoes: [Category.SHOE],
  accessories: [
    Category.GLASSES,
    Category.HAT,
    Category.HEADBAND_HEAD_COVERING_HAIR_ACCESSORY,
    Category.TIE,
    Category.GLOVE,
    Category.WATCH,
    Category.BELT,
    Category.SCARF,
    Category.UMBRELLA,
    Category.BRACELET,
    Category.BAG_WALLET,
    Category.JEWELLERY,
    Category.BAG,
  ],
  fullBody: [Category.COAT, Category.DRESS, Category.JUMPSUIT],
  other: [Category.OTHER],
};

export enum ClusteredCategory {
  TOPS = "tops",
  BOTTOMS = "bottoms",
  SHOES = "shoes",
  ACCESSORIES = "accessories",
  OTHER = "other",
  FULL_BODY = "full body",
}

export const getClusteredCategory = (category: Category): ClusteredCategory => {
  for (const cluster in categoryClusters) {
    if (categoryClusters[cluster].includes(category)) {
      return cluster as ClusteredCategory;
    }
  }
  return ClusteredCategory.OTHER;
};

export const getSubcategories = (cluster: ClusteredCategory): Category[] => {
  return categoryClusters[cluster];
};
