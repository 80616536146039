import { UserParamsI } from "../types/common/UserParamsI";
import {
  PhotoQuestionI,
  QuestionType,
  ValidationResponseI,
} from "../types/Quiz";

export class PhotoQuestion implements PhotoQuestionI {
  result: "photo" = "photo";
  type = QuestionType.PHOTO;
  chosenAnswer: string = "";
  constructor(
    public question: string,
    public validation: (
      answer: UserParamsI[keyof UserParamsI][] | UserParamsI[keyof UserParamsI]
    ) => ValidationResponseI
  ) {}
}
