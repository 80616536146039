import React, { useState } from "react";
import {
  Image,
  Platform,
  Pressable,
  StyleSheet,
  TextInput,
  View,
} from "react-native";
import { getImage } from "../../helpers/resourceProvider";
import { spacing, typography } from "../../theme";
import colors from "../../theme/colors";

export type Props = {
  password: string;
  setPassword: (password: string) => void;
};

const InputPassword = ({ password, setPassword }: Props) => {
  const [isFocused, setFocused] = useState(false);
  const [isPasswordHidden, setPasswordHidden] = useState(true);
  return (
    <View
      style={[
        styles.textInputContainer,
        isFocused ? styles.focused : styles.blurred,
      ]}
    >
      <Image source={getImage("passwordLeftIcon")} style={styles.leftIcon} />
      <TextInput
        //@ts-ignore

        style={[styles.input, Platform.OS == "web" && { outline: "none" }]}
        placeholder={"Password"}
        keyboardType="default"
        value={password}
        onChangeText={(text) => setPassword(text)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        secureTextEntry={isPasswordHidden}
      />
      <Pressable
        onPress={() => setPasswordHidden(!isPasswordHidden)}
        style={styles.rightIconContainer}
      >
        <Image
          source={getImage("passwordRightIcon")}
          style={styles.rightIcon}
        />
      </Pressable>
    </View>
  );
};

export default InputPassword;

const styles = StyleSheet.create({
  textInputContainer: {
    ...typography.p1,
    borderWidth: 1,
    borderColor: colors.gray,

    borderRadius: spacing.xs,
    height: 50,
    alignSelf: "stretch",
    marginBottom: 15,
  },
  focused: {
    borderColor: colors.dark,
    borderWidth: 1,
    backgroundColor: colors.bg,
  },
  blurred: {
    borderColor: colors.gray,
    borderWidth: 1,
  },
  input: {
    padding: 15,
    width: "100%",
    paddingLeft: 50,
  },
  leftIcon: {
    position: "absolute",
    top: 15,
    left: 15,
    height: 20,
    width: 20,
  },
  rightIconContainer: {
    position: "absolute",
    top: 15,
    right: 15,
  },
  rightIcon: {
    height: 20,
    width: 20,
  },
});
