import { createMongoAbility } from "@casl/ability";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useEffect } from "react";
import { Linking, ScrollView, StyleSheet, View } from "react-native";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";
import PremiumLabel from "../../components/premium-label/PremiumLabel";
import RectangleCard from "../../components/rectangle-card/RectangleCard";

import { getImage } from "../../helpers/resourceProvider";
import usePayments from "../../hooks/usePayments";
import useUserStore from "../../hooks/useUserStore";
import NavigationParamList from "../../navigators/NavigationParamList";
import { useGetAllStyleGuideQuery } from "../../store/api";
import { typography } from "../../theme";
import colors from "../../theme/colors";
import containers from "../../theme/containers";
import { SearchBar } from "../search-bar";
import TrendsGuide from "../style-guide-articles/TrendsGuide";

const HomeScreen = () => {
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  const { user, rules } = useUserStore();
  const {
    makePurchase,
    currentOffering,
    customerInfo,
    getCustomerInfo,
    isPremium,
  } = usePayments(user?.id);
  const [chatPrompt, setChatPrompt] = React.useState<string>("");
  const styleGuide = useGetAllStyleGuideQuery(undefined, {
    skip: !user,
  });
  // useEffect(() => {
  //   async function getAndHandleInitialURL() {
  //     const url = await Linking.getInitialURL();
  //     console.log("url", url);
  //     if (url) {
  //       const { pathname: path, search: queryParams } = new URL(url);
  //       navigateTo(path, queryParams);
  //     }
  //   }

  //   getAndHandleInitialURL();
  // }, []);

  function navigateTo(path: string, queryParams: string) {
    // console.log(linkingOptions.getStateFromPath?.(path));
    // navigation.navigate("Favorites", queryParams);
  }

  if (!user) return <LoadingState />;
  const ability = createMongoAbility(rules);
  // return (
  //   <LookCardV2
  //     look={lookMock}
  //     style={{ width: "100%", backgroundColor: colors.bg }}
  //   />
  // );
  return (
    <View style={{ flex: 1 }}>
      <View style={{ backgroundColor: colors.bg }}>
        <SearchBar
          value={chatPrompt}
          onInputChange={setChatPrompt}
          onPress={() => {
            const chatPromptCopy = chatPrompt;
            setChatPrompt("");
            navigation.navigate("Chat", { firstMessage: chatPromptCopy });
          }}
        />
        <View style={{ height: 20 }} />
      </View>
      <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={true}>
        <View style={styles.container}>
          <View style={{ height: 20 }} />
          <RectangleCard
            style={{ marginBottom: 25 }}
            image={getImage("analyzeLookCard")}
            onPress={() => {
              navigation.navigate("GenerateLook", {
                initialPrompt: undefined,
              });
            }}
            header={"Look Generator"}
            description={"Generate personalized looks and shop immidiately"}
          />
          <RectangleCard
            style={{ marginBottom: 25 }}
            image={getImage("styleGuideCard")}
            onPress={() => navigation.navigate("StyleGuide")}
            header={"Style Guide"}
            description={
              "All you need to know about wearing colors, prints, accessories, and more"
            }
          ></RectangleCard>

          <RectangleCard
            style={{ marginBottom: 25 }}
            image={getImage("stealLookCard")}
            onPress={() => {
              if (!isPremium) {
                navigation.navigate("Subscription", { context: "trends" });
              } else {
                navigation.navigate("StyleGuideArticle", {
                  useSlides: TrendsGuide,
                  isShowDrawer: true,
                });
              }
            }}
            header={"Current trends"}
            description={
              "Find out what's trending and how to wear it for your body type"
            }
          >
            {!isPremium && <PremiumLabel text="Pro" />}
          </RectangleCard>

          {/* / <View style={{ height: Platform.OS == "ios" ? 100 : 30 }} /> */}
        </View>
      </ScrollView>
    </View>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  lookbookCard: {
    marginBottom: 25,
    width: 190,
    marginRight: 20,
  },
  scrollView: { backgroundColor: colors.bg },
  container: {
    ...containers.screenContainer,
    alignContent: "stretch",
  },
  h1: {
    ...typography.h1,
  },
  h2: {
    ...typography.h2,
    fontWeight: "bold",
  },
  serviceContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    marginBottom: 30,
  },
});

const LoadingState = () => {
  return (
    <View style={stylesLoading.container}>
      <Placeholder Animation={Fade}>
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderMedia style={stylesLoading.placeholderBasicAndGuide} />
        <PlaceholderLine width={50} height={20} />
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginBottom: 30,
          }}
        >
          <PlaceholderLine
            noMargin={true}
            style={stylesLoading.placeholderOccasion}
          />
          <PlaceholderLine
            noMargin={true}
            style={stylesLoading.placeholderOccasion}
          />
          <PlaceholderLine
            noMargin={true}
            style={stylesLoading.placeholderOccasion}
          />
          <PlaceholderLine
            noMargin={true}
            style={stylesLoading.placeholderOccasion}
          />
          <PlaceholderLine
            noMargin={true}
            style={stylesLoading.placeholderOccasion}
          />
          <PlaceholderLine
            noMargin={true}
            style={stylesLoading.placeholderOccasion}
          />
          <PlaceholderLine
            noMargin={true}
            style={stylesLoading.placeholderOccasion}
          />
          <PlaceholderLine
            noMargin={true}
            style={stylesLoading.placeholderOccasion}
          />
          <PlaceholderLine
            noMargin={true}
            style={stylesLoading.placeholderOccasion}
          />
        </View>
      </Placeholder>
    </View>
  );
};

const stylesLoading = StyleSheet.create({
  container: { ...containers.screenContainer },
  placeholderBasicAndGuide: {
    width: "100%",
    height: 140,
    borderRadius: 5,
    marginBottom: 10,
  },
  placeholderOccasion: {
    width: "30%",
    height: 140,
    borderRadius: 5,
    marginBottom: 10,
  },
});
