import { useNavigation, NavigationProp } from "@react-navigation/native";
import { getNameOfNearestColor } from "../../helpers/colorHelpers";
import { convertMetricToImperial } from "../../helpers/convertMetricToImperial";
import { getQuestionByName } from "../../helpers/quiz";
import { useUserStore } from "../../hooks";
import NavigationParamList from "../../navigators/NavigationParamList";
import { QuestionI } from "../../types/Quiz";
import { Bodytype } from "../../types/common/Bodytype";
import { ColorSeason } from "../../types/common/ColorSeason";
import { Faceshape } from "../../types/common/Faceshape";
import { Gender } from "../../types/common/Gender";
import { Hairlength } from "../../types/common/Hairlength";
import { Specials } from "../../types/common/Specials";
import { UserI } from "../../types/common/UserI";
import { UserParamsI } from "../../types/common/UserParamsI";

const getGenderStr = (gender: Gender) => {
  switch (gender) {
    case Gender.FEMALE:
      return "Woman";
      break;
    case Gender.MALE:
      return "Man";
      break;
    case Gender.NON_BINARY:
      return "Non binary";
      break;
    default:
      console.log("could not convert gender enum value ", gender);
      break;
  }
};

const getBodytypeStr = (bodytype: Bodytype) => {
  switch (bodytype) {
    case Bodytype.APPLE:
      return "Apple";
      break;
    case Bodytype.HOURGLASS:
      return "Hourglass";
      break;
    case Bodytype.INVERTED_TRIANGLE:
      return "Inverted triangle";
      break;
    case Bodytype.PEAR:
      return "Pear";
      break;
    case Bodytype.RECTANGLE:
      return "Rectangle";
      break;
    default:
      console.log("could not convert bodytype enum value ", bodytype);
      break;
  }
};

const getFaceshapeStr = (faceshape: Faceshape) => {
  switch (faceshape) {
    case Faceshape.DIAMOND:
      return "Diamond";
      break;
    case Faceshape.HEART:
      return "Heart";
      break;
    case Faceshape.OBLONG:
      return "Oblong";
      break;
    case Faceshape.OVAL:
      return "Oval";
      break;
    case Faceshape.ROUND:
      return "Round";
      break;
    case Faceshape.SQUARE:
      return "Square";
      break;
    case Faceshape.TRIANGLE:
      return "Triangle";
      break;
    case Faceshape.DIAMOND:
      return "Diamond";
      break;
    default:
      console.log("could not convert faceshape enum value ", faceshape);
      break;
  }
};

const getHairlengthStr = (hairlength: Hairlength) => {
  switch (hairlength) {
    case Hairlength.LONG:
      return "Long";
      break;
    case Hairlength.MIDDLE:
      return "Middle";
      break;
    case Hairlength.SHORT:
      return "Short";
      break;
    default:
      console.log("could not convert hairlength enum value ", hairlength);
      break;
  }
};

const getSpecialsStr = (specials: Specials) => {
  switch (specials) {
    case Specials.MATERNITY:
      return "Maternity";
      break;
    case Specials.PETIT:
      return "Petit";
      break;
    case Specials.PLUS:
      return "Plus";
      break;
    case Specials.TALL:
      return "Tall";
      break;
    default:
      console.log("could not convert specials enum value ", specials);
      break;
  }
};

const getColoSeasonStr = (colorseason: ColorSeason) => {
  switch (colorseason) {
    case ColorSeason.AUTUMN_SOFT:
      return "Autumn soft";
    case ColorSeason.AUTUMN_TRUE:
      return "Autumn true";
    case ColorSeason.AUTUMN_DARK:
      return "Autumn dark";
    case ColorSeason.WINTER_DARK:
      return "Winter dark";
    case ColorSeason.WINTER_TRUE:
      return "Winter true";
    case ColorSeason.WINTER_BRIGHT:
      return "Winter bright";
    case ColorSeason.SPRING_BRIGHT:
      return "Spring bright";
    case ColorSeason.SPRING_TRUE:
      return "Spring true";
    case ColorSeason.SPRING_LIGHT:
      return "Spring light";
    case ColorSeason.SUMMER_LIGHT:
      return "Summer light";
    case ColorSeason.SUMMER_TRUE:
      return "Summer true";
    case ColorSeason.SUMMER_SOFT:
      return "Summer soft";
    default:
      console.log("could not convert colorseason enum value ", colorseason);
      break;
  }
};

const useProfileScreenViewModel = () => {
  const navigation =
    useNavigation<NavigationProp<NavigationParamList, "Profile">>();
  let { user, changeUserParam, getPhoto } = useUserStore();
  user = user as UserI;
  const age = user.params.age;
  const name = user.params.name;
  const gender = user.params.gender && getGenderStr(user.params.gender);
  const weight =
    user.params.weight &&
    `${user.params.weight} kg / ${convertMetricToImperial(
      "kg",
      user.params.weight
    )} lbs`;
  const height =
    user.params.height &&
    `${user.params.height} cm / ${convertMetricToImperial(
      "cm",
      user.params.height
    )} ft`;
  const bodytype = user.params.bodytype && getBodytypeStr(user.params.bodytype);
  const faceshape =
    user.params.faceshape && getFaceshapeStr(user.params.faceshape);
  const hairlength =
    user.params.hairlength && getHairlengthStr(user.params.hairlength);
  const colorseason =
    user.params.colorseason && getColoSeasonStr(user.params.colorseason);
  const eyecolor =
    user.params.eyecolor &&
    getNameOfNearestColor(user.params.eyecolor[0][0]).name;
  const haircolor =
    user.params.haircolor &&
    getNameOfNearestColor(user.params.haircolor[0][0]).name;
  const skincolor =
    user.params.skincolor &&
    getNameOfNearestColor(user.params.skincolor[0][0]).name;
  const skinundertone = user.params.skinundertone;
  const specials =
    user.params.specials &&
    user.params.specials?.length > 0 &&
    user.params.specials.map((sp) => getSpecialsStr(sp)).join(", ");

  const handleChangeProfile = (questionName: QuestionI["result"][]) => {
    return () => {
      navigation.navigate("QuizScreen", {
        quiz: [...questionName.map((q) => getQuestionByName(q))],
        isWelcomeScreen: false,
        onFinish: (userParams: Partial<UserParamsI>) => {
          changeUserParam(userParams);
          navigation.navigate("Profile");
        },
        exitText: "",
        onExit: () => {},
      });
    };
  };
  const photo = getPhoto();
  return {
    handleChangeProfile,
    photo,
    age,
    name,
    gender,
    height,
    bodytype,
    faceshape,
    eyecolor,
    haircolor,
    skincolor,
    hairlength,
    specials,
    weight,
    colorseason,
    skinundertone,
  };
};

export default useProfileScreenViewModel;
