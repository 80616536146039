import React from "react";
import { Image, Text, View } from "react-native";
import { useUserStore } from "../../hooks";
import { useGetAllStyleGuideQuery } from "../../store/api";
import { Gender } from "../../types/common/Gender";
import TabLink from "./TabLink";
import { styles } from "./styles";
import { SecondaryButton } from "../../components/buttons";
import { useNavigation, NavigationProp } from "@react-navigation/native";
import NavigationParamList from "../../navigators/NavigationParamList";

const TrendsGuide = () => {
  const { data, isFetching } = useGetAllStyleGuideQuery();
  const { user } = useUserStore();
  const navigation = useNavigation<NavigationProp<NavigationParamList>>();
  if (!data) return [];

  let trends = data
    .filter((item) => item.gender == user?.params.gender)
    .map((item) => item.data);
  console.log(trends);
  return trends.map((trend) => ({
    slide: (
      <View>
        <Image
          source={{ uri: trend.img }}
          style={[styles.mainIllustration]}
          resizeMode="contain"
        />
        <Text style={styles.title}>{trend.title}</Text>
        <Text style={styles.description}>{trend.description}</Text>
        {trend.formula && trend.formula != "" && (
          <SecondaryButton
            text="get outfit like this "
            onPress={() => {
              navigation.navigate("GenerateLook", {
                initialPrompt: " outfit inspired by" + trend.formula,
              });
            }}
          />
        )}
      </View>
    ),

    tabLink: <TabLink img={{}} text={trend.title} />,
  }));
};

export default TrendsGuide;
