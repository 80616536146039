import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LookI } from "../../types/common/LookI";

export interface LookStateI {
  state: { [key: LookI["id"]]: LookI };
}

const initialState = {
  state: {},
} as LookStateI;

export const lookSlice = createSlice({
  name: "looks",
  initialState: initialState,
  reducers: {
    setLook: (
      state,
      action: PayloadAction<{
        look: LookI;
        id: LookI["id"];
      }>
    ) => {
      state.state[action.payload.id] = action.payload.look;
    },
  },
});

export const { setLook } = lookSlice.actions;
export default lookSlice.reducer;
